import styles from './blog-details.module.css';
import React, { useState,useEffect } from "react";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import axios from "axios";
import Spinner from "../spinner/spinner";
import { Link ,useParams, useNavigate} from 'react-router-dom';
import { actions } from "../../redux/companyReducer";
import { useDispatch } from 'react-redux';
const BlogDetails = () => {
  const { t } = useTranslation();

  let { id } = useParams();
  const navigate = useNavigate();
  const [blogDetails,setBlogDetails] = useState([]);
  const [allBlogs,setAllBlogs] = useState([]);
  const [isLoadedAllBlogs,setIsLoadedAllBlogs] = useState(false);
  const [isLoadedBlog,setIsLoadedBlog] = useState(false);
  const [currentBlogId,setBlogId] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    dispatch(actions.setuserDetails({}));
    getBlogDetails();
    getAllBlogs();
    setBlogId(parseInt(id));
    window.scrollTo(0, 0);
    window.addEventListener('popstate', onBackButtonEvent);
    // eslint-disable-next-line 
 },[]);
 const onBackButtonEvent = (e) => {
  e.preventDefault();
  window.location.reload();
}
 function getBlogDetails(){
  axios.get(process.env.REACT_APP_BASE_URL + "blog/" + id).then((response) => {
    setBlogDetails(response.data);
    setIsLoadedBlog(true);
    console.log(blogDetails);
  }).catch(error => {
    setIsLoadedBlog(true);
    console.log('There was an error!', error);
});
}
function getAllBlogs(){
  axios.get(process.env.REACT_APP_BASE_URL + "blog").then((response) => {
    setAllBlogs(response.data);
    setIsLoadedAllBlogs(true);
    console.log(allBlogs);
  }).catch(error => {
    setIsLoadedAllBlogs(true);
    console.log('There was an error!', error);
});
}
function readMore(){
  window.location.reload();
}
const clickBlogTitle = (id) => {
  navigate("/blog-details/"+id);
  readMore();
}
  return(
  <div className={styles.BlogDetails} data-testid="BlogDetails">
   <body>

    <section id="Hotjobs" class="top-90">
        <div class="padding-body mt-3">
            <div class="sub-text"><Link className="topI" to={'/'}><a style={{"color":"#a5a6a8"}}>{t('top')}</a></Link> / <span>{t('blog')}</span> </div>
    
            <div class="top-90">
                <div class="row mb-5 justify-content-center">
                <div class="col-md-9 custom-col9">
                {isLoadedBlog ?      <div class="row">

                           { blogDetails.blogName ? <div class="card job-list-card p-0">
                                <div class="card-body blog-body ">
                                    <div class="row">
                                        <div class="col-md-12 p-4">
                                            <h5 class="my-4">{blogDetails.blogName}</h5>
                                        </div>
                                          <div class="p-0">
                                            <img src={blogDetails.imageUrl} alt="" class="blog-img" style={{"objectFit":"cover"}}/>
                                          </div>
                                          <div class="col-md-12 p-4 mt-5">
                                            <div class="popup-head mt-3 mb-3 d-flex justify-content-between">
                                                <h6>{t('description')}</h6>
                                                <p class="text-end">{blogDetails.dateOfBlog}</p>
                                            </div>


                                            <p class="mt-4">{blogDetails.description}</p>
                                        </div>
                                <div class="text-center">
                                <a   href={blogDetails.URL} target="_blank">
                                            <button class="main-buttons ">{t('article')}</button>
                                            </a>
                                        </div>

                                    </div>
                                </div> 
                            </div> : null}

                         </div>:<div style={{"padding-bottom":"40%"}}> <Spinner ></Spinner> </div>}

                    </div>
                    
                   {allBlogs.length > 1 ? <div class="col-md-3 custom-col3">
                   { isLoadedAllBlogs ?  <div class="row">
                      {allBlogs.map((blog)=>{return( <div>    
                    { blog.blogId === currentBlogId ? null : <div class="col-md-12 col-blog6"> 
                            <div class="card Card-blog-details  mb-3">
                              <div class="blog-whitebg">
                                <img src={blog.imageUrl} class="blog-details-card-img-top" alt="..." style={{"objectFit":"cover"}}/>
                                <div class="blog-heading px-4 py-3">
                                <div class="blog-head" onClick={() => clickBlogTitle(blog.blogId)} style={{"cursor":"pointer"}}>{blog.blogName}</div>
                                </div>
                              </div>                              
                              <div class="blog-card-body pt-5 pb-4 p-left">
                                <p class="card-text pb-4">
                                  <span style={{"overflow":"hidden","text-overflow":"ellipsis","display":"-webkit-box","-webkit-line-clamp":"5","-webkit-box-orient":"vertical"}}>{blog.description}</span></p>
                                <a class="read-more pb-3 pt-3" href={"/blog-details/"+ blog.blogId} onClick={readMore}>{t('read_more')} <i class="fa-solid fa-arrow-right-long ps-3"></i></a>
                              </div>
                            </div>
                          </div> }
                          </div>)})}
                      </div> : <Spinner></Spinner>}
                    </div> : null}

                </div>

            </div>
        </div>
    </section>

  

    <script src="js/bootstrap.bundle.js"></script>

</body>
  </div>
)};

export default BlogDetails;
