import React, { useEffect } from "react";
import styles from './job-application.module.css';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { actions } from "../../redux/companyReducer";
import { useDispatch } from 'react-redux';
const Jobapplication = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    dispatch(actions.setuserDetails({}));
    // eslint-disable-next-line 
  }, []);
  function handleOnClickRegistration(){
     localStorage.setItem("career_Id",true);
    navigate("/registration");
  }
  function handleOnClickLogin(){
    localStorage.setItem("career_Id",true);
    navigate("/login");
  }
  return (
    <div className={styles.Jobapplication} data-testid="Jobapplication">
      <body class="d-flex flex-column h-100">

        <section id="Hotjobs" class="top-90">
          <div class="padding-body mt-3">
            <div class="sub-text">  <Link className="breadcurumb" to={"/"}>{t('top')} </Link> / <span>{t('career_counseling')}</span> </div>
            <div class="top-90">
              <div class="row mb-3 justify-content-center">
                <div class="col-md-10 custom-col9">
                  <div class="row">
                    <div class="card job-list-card ">
                      <div class="card-body p-4">
                        <div>
                          <div class="row d-flex align-items-center top-90">
                            <div class="text-center">
                              <h5>{t('new_user_or_existing')}</h5>
                              <h5>{t('new_user_or_existing_line_2')} </h5>
                            </div>
                            <div class="col-md-12 text-center">
                                <button class="reg-btn" data-bs-dismiss="modal" onClick={handleOnClickRegistration}>{t('new_registration')}</button>
                                <button class="main-buttons sub-btn" onClick={handleOnClickLogin}>{t('login')}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <script src="js/bootstrap.bundle.js"></script>
      </body>
    </div>
  )
};


export default Jobapplication;
