import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import axios from "axios";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Link, useNavigate } from 'react-router-dom';
import TextField from "@mui/material/TextField";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import 'react-toastify/dist/ReactToastify.css';
import { actions } from "../../redux/companyReducer";
import { useDispatch } from 'react-redux';
const Counseling = () => {
    const history = useNavigate()
    const { t } = useTranslation();
    const [questionsList, setQuestions] = useState([]);
    const [index, setIndex] = useState(0);
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [next, setNext] = useState(true);
    const [datePickerValue, setDatePickerValue] = useState();
    const [userEmail, setEmail] = useState();
    const [areaList, setAreaList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [tempLocation, setTempLocation] = useState();
    const [selectedLocation, setSelectedLocation] = useState();
    const [isLoading, setLoading] = useState(false);
    const [response, setResponse] = useState(false);
    const [dateOpen, setDateOpen] = useState(false);
    const [open, setOpen] = useState(false);
    
    const [invalidName,setInvalidName] = useState(true);
    const [invalidMail,setInvalidMail] = useState(true);
    const [invalidPhone,setInvalidPhone] = useState(true);
    const dispatch = useDispatch();
    const [questionAnswerArray,setQuestionAnswerArray] = useState(['お名前','生年月日','メールアドレス','電話番号','希望の勤務地','就業状況','就業経験','転職希望時期']);
    let locale = "ja";
    let maxDate = dayjs(new Date());
    /*
    1. text box - email
    2. text box - phone number
    3. text box - normal text box
    4. date picker - (For DOB)
    5. radio button - single select
    6. checkbox - multiple select
    7. pop up - single select (For area)
    8. pop up - multiple select (For category)
    */
    let data = {
        "questions": [
            {
                "id": 1,
                "question": " お名前を入力してください ",
                "input": 3,
            },
            {
                "id": 2,
                "question": " 生年月日を入力してください ",
                "input": 4,
            },
            {
                "id": 3,
                "question": " メールアドレスを入力してください ",
                "input": 1,
            },
            {
                "id": 4,
                "question": " 電話番号を入力してください ",
                "input": 2,
            },
            {
                "id": 5,
                "question": " 希望の勤務地を入力してください ",
                "input": 7,
            },
            {
                "id": 6,
                "question": " 現在の就業状況を教えてください ",
                "input": 5,
                "options": [
                    {
                        "id": 1,
                        "opt": "在職中"
                    },
                    {
                        "id": 3,
                        "opt": "離職中"
                    }
                ]
            },
            {
                "id": 7,
                "question": " 就業経験の有無を教えてください",
                "input": 5,
                "options": [
                    {
                        "id": 6,
                        "opt": "経験なし"
                    },
                    {
                        "id": 7,
                        "opt": "1年未満"
                    },
                    {
                        "id": 6,
                        "opt": "1年以上"
                    },
                    {
                        "id": 7,
                        "opt": "3年以上"
                    }
                ]
            },
            {
                "id": 8,
                "question": " 転職希望時期を教えてください",
                "input": 5,
                "options": [
                    {
                        "id": 4,
                        "opt": "1ヶ月以内"
                    },
                    {
                        "id": 9,
                        "opt": "3ヶ月以内"
                    },
                    {
                        "id": 4,
                        "opt": "6ヶ月以内"
                    },
                    {
                        "id": 9,
                        "opt": "1年以内"
                    },
                    {
                        "id": 9,
                        "opt": "1年以上先"
                    }
                ]
            },


        ]
    }
    useEffect(() => {
        dispatch(actions.setuserDetails({}));
        setLoading(false);
        getAllCategory();
        getAllArea();
        setQuestions(data.questions)
        getQuestions();
        i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
        window.scrollTo(0, 0);
        // eslint-disable-next-line 
    }, []);

    const getAllCategory = () => {
        axios.get(process.env.REACT_APP_BASE_URL + "category").then(
            (response) => {
                setCategoryList(response.data.categoryList);
                console.log(categoryList);
            },
            (error) => { }
        );
    };
    const getAllArea = () => {
        axios.get(process.env.REACT_APP_BASE_URL + "category/area").then(
            (response) => {
                console.log(response);
                setAreaList(response.data.areaList);
            },
            (error) => { }
        );
    };
    function getQuestions() {
        for (let i = 0; i < data.questions.length; i++) {
            setSelectedQuestions(selectedQuestions => [...selectedQuestions, data.questions[i].question]);
        }
        console.log(selectedQuestions);
        //console.log(data.questions)
    }
    function isValidEmail(email) {
        return   /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"+-@$!%*?&#_+=,._0-9]+\.)+[^<>()[\]\.,;:\s@\"]{2,3})$/i.test(email);
    }

    function changeLocation(event, p) {
        if (event.target.checked) {
            setTempLocation(p)
            console.log(tempLocation)
        }
    }
    function clearLocation() {
        setTempLocation(selectedLocation);
    }
    function handleNext() {
        console.log(selectedAnswers);
        console.log(selectedQuestions);
        setIndex(index + 1);

    }
    function handlePrevious() {
        console.log(selectedAnswers);
        console.log(selectedQuestions);
        setIndex(index - 1);

    }
    function submit() {
        setLoading(true)
        console.log(selectedAnswers);
        sendMAil();
    }
    const handleOnchange = (event, index) => {
        console.log(event.target.value)
        for (let i = 0; i <= selectedAnswers.length; i++) {
            if (index === i) {
                if (selectedAnswers[i]) {
                    let arry = [...selectedAnswers];
                    arry[i] = event.target.value;
                    setSelectedAnswers(arry);
                } else {
                    setSelectedAnswers(selectedAnswers => [...selectedAnswers, event.target.value]);
                }
                console.log(selectedAnswers)
            }
        }
    }
    const handleOnchangeText = (event, index) => {
        if (/[*|\":<>[\]{}`\\()';,.+=-@&$0-9]/i.test(event.target.value) || event.target.value.trim() === "") {
            setNext(false);
            setInvalidName(false);
        } else {
            setNext(true);
            setInvalidName(true);
        }
        console.log(event.target.value)
        for (let i = 0; i <= selectedAnswers.length; i++) {
            if (index === i) {
                if (selectedAnswers[i]) {
                    let arry = [...selectedAnswers];
                    arry[i] = event.target.value;
                    setSelectedAnswers(arry);
                } else {
                    if (selectedAnswers[i] === "") {
                        let arry = [...selectedAnswers];
                        arry[i] = event.target.value;
                        setSelectedAnswers(arry);
                    } else {
                        setSelectedAnswers(selectedAnswers => [...selectedAnswers, event.target.value]);
                    }

                }
                console.log(selectedAnswers)
            }
        }
    }
    const handleOnchangeEmail = (event, index) => {
        for (let i = 0; i <= selectedAnswers.length; i++) {
            if (!isValidEmail(event.target.value)) {
                setNext(false);
                setInvalidMail(false);
            } else {
                setNext(true);
                setInvalidMail(true);
            }
            if (index === i) {
                if (selectedAnswers[i]) {
                    let arry = [...selectedAnswers];
                    arry[i] = event.target.value;
                    setSelectedAnswers(arry);
                    setEmail(arry[i]);
                }
                else {
                    if (selectedAnswers[i] === "") {
                        let arry = [...selectedAnswers];
                        arry[i] = event.target.value;
                        setSelectedAnswers(arry);
                        setEmail(arry[i]);
                    }
                    else {
                        setSelectedAnswers(selectedAnswers => [...selectedAnswers, event.target.value]);
                        setEmail(event.target.value);
                    }

                }
                console.log(selectedAnswers)
            }
        }
    }
    const handleOnchangePhone = (event, index) => {

        for (let i = 0; i <= selectedAnswers.length; i++) {
           if(event.target.value > 999999999999){
            event.target.value = event.target.value.slice(0, 12);
           }
            if ((event.target.value.length >= 10 && event.target.value.length <= 12 && /^\d{1,12}$/.test(+event.target.value))) {
                setNext(true);
                setInvalidPhone(true);
            } else {
                setNext(false);
                setInvalidPhone(false);
            }
            if (index === i) {
                if (selectedAnswers[i] || selectedAnswers[i] === "") {
                    let arry = [...selectedAnswers];
                    arry[i] = event.target.value;
                    setSelectedAnswers(arry);
                }
                else {
                     setSelectedAnswers(selectedAnswers => [...selectedAnswers, event.target.value]);
                    }
                console.log(selectedAnswers)
            }
        }
    }
    const handleOnchangeDate = (newValue, index) => {
        for (let i = 0; i <= selectedAnswers.length; i++) {
            if (newValue !== "") {
                setNext(true);
                setDatePickerValue(new Date(newValue));
                if (index === i) {
                    if (selectedAnswers[i]) {
                        let arry = [...selectedAnswers];
                        arry[i] = moment(new Date(newValue)).format("YYYY-MM-DD");
                        setSelectedAnswers(arry);
                    }else if (selectedAnswers[i] === "") {
                            let arry = [...selectedAnswers];
                            arry[i] = moment(new Date(newValue)).format("YYYY-MM-DD");;
                            setSelectedAnswers(arry);
                        }
                     else {
                            setSelectedAnswers(selectedAnswers => [...selectedAnswers, moment(new Date(newValue)).format("YYYY-MM-DD")]);
                        }                    
                    console.log(selectedAnswers)
                }
            } else {
                setNext(false);
            }
        }
    }
    const handleOnChangeArea = () => {
        setSelectedLocation(tempLocation);
        for (let i = 0; i <= selectedAnswers.length; i++) {
            if (index === i) {
                if (selectedAnswers[i]) {
                    let arry = [...selectedAnswers];
                    arry[i] = tempLocation;
                    setSelectedAnswers(arry);
                } else {
                    if (selectedAnswers[i] === "") {
                        let arry = [...selectedAnswers];
                        arry[i] = tempLocation;
                        setSelectedAnswers(arry);
                    } else {
                        setSelectedAnswers(selectedAnswers => [...selectedAnswers, tempLocation]);
                    }

                }
                console.log(selectedAnswers)
            }
        }
    }
    function sendMAil() {
        console.log(selectedAnswers);
        console.log(selectedQuestions)
       
        const body = {
            "questionsLabel": selectedQuestions,
            "answers": selectedAnswers,
            "email": userEmail,
            "questions":questionAnswerArray
        };
        axios.post(process.env.REACT_APP_BASE_URL + 'careerCounselling/responses', body).then(
            (response) => {
                console.log(response);
                setLoading(false);
                setResponse(true);
                setOpen(false);
                window.dataLayer.push({
                    event:"Career Counselling"
                    });
                history('/counseling-success');
            },
            (error) => {
                setLoading(false);
                setResponse(false);
                document.getElementById("successPopUpButton").click();
            }
        );
    }
    function handleOnClickPopUp() {
        document.getElementById("successPopUpCloseButton").click();
        history('/');
    }
    return (
        <body className="d-flex flex-column h-100">
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js"></script>
      <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
            <section id="Hotjobs" className="top-90">
                <div className="padding-body mt-3">
                    <div className="sub-text"><Link className="topI" to={'/'}><a style={{ "color": "#DBDBDB" }}>{t('top')}</a></Link> /
                        <span>{t('career_counseling')}</span> </div>

                    <div className="top-90">
                        <div className="text-center mb-5">
                            <h4>{t('free_job_change')}</h4>
                        </div>
                        <div className="row mb-3 justify-content-center">
                            <div className="col-md-10 custom-col9">
                                <div className="row">
                                    <div className="card job-list-card " style={{ "marginBottom": "120px" }}>
                                        <div className="card-body p-4">
                                            <div className="tb-82">

                                                {questionsList.map((q, i) => {
                                                    return (<>
                                                        {index === i && <div className="row d-flex align-items-center top-90">
                                                            <div className="mb-5 mt-3">
                                                                <div className="text-center">
                                                                    <h5>
                                                                        {index > 0 ? <i class="fa-solid fa-arrow-left" style={{ "paddingRight": "10px", "cursor": "pointer" }} onClick={handlePrevious}></i> : null}
                                                                        &nbsp;{i + 1}.&nbsp; {q.question}
                                                                    </h5>
                                                                </div>
                                                                {q.input === 5 && <form className="form-counseling">
                                                                    {q.options.map((o) => {
                                                                        return (<div class="radiobtn">
                                                                            <input type="radio" id={o.opt} checked={selectedAnswers[i] === o.opt}
                                                                                name="drone" value={o.opt} onChange={(e) => handleOnchange(e, i)} />
                                                                            <label for={o.opt}>{o.opt}</label>
                                                                        </div>)
                                                                    })}
                                                                </form> }

                                                                {q.input === 3 && <form className="form-counseling">
                                                                    <input type="text" id="nameInput" aria-describedby="emailHelp" name="fullName"
                                                                        value={selectedAnswers[i]} onChange={e => handleOnchangeText(e, i)} onPaste = {e => handleOnchangeText(e, i)}
                                                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                                        className="form-control custom-input custom-input-changeJa"  />
                                                                        { !invalidName ?<div className="error">{t('invalid_name')}</div> : null}
                                                                </form> }
                                                                {q.input === 1 && <form className="form-counseling">
                                                                    <div></div>
                                                                    <input type="email" id="emailInput" aria-describedby="emailHelp" name="email"
                                                                        value={selectedAnswers[i]} onChange={e => handleOnchangeEmail(e, i)} onPaste = {e => handleOnchangeEmail(e, i)}
                                                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                                        className="form-control custom-input custom-input-changeJa" />
                                                                        { !invalidMail ?<div className="error">{t('invalid_email')}</div> : null}
                                                                </form> }
                                                                {q.input === 2 && <form className="form-counseling">
                                                                    <div></div>
                                                                    <input type="number" id="emailInput" aria-describedby="emailHelp" name="phone" style={{"-moz-appearance":"textfield"}}
                                                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} maxLength="12"
                                                                        value={selectedAnswers[i]} onChange={e => handleOnchangePhone(e, i)} onPaste = {e => handleOnchangePhone(e, i)}
                                                                        className="form-control custom-input custom-input-changeJa" />
                                                                        { !invalidPhone ?<div className="error">{t('invalid_phone')}</div> : null}
                                                                </form> }
                                                                {q.input === 4 && <form className="form-counseling">
                                                                    <LocalizationProvider
                                                                        dateAdapter={AdapterDayjs}
                                                                        adapterLocale={locale}>
                                                                        <DesktopDatePicker
                                                                            clearable={true}
                                                                            open={dateOpen}
                                                                            onClose={() => setDateOpen(false)}
                                                                            value={datePickerValue}
                                                                            maxDate={maxDate}
                                                                            className="form-control custom-input"
                                                                            inputFormat="YYYY-MM-DD"
                                                                            renderInput={(params) => (<TextField {...params} onClick={() => setDateOpen(true)} inputProps={{ ...params.inputProps, readOnly: true, }} />)}
                                                                            onChange={(newValue) => setDatePickerValue(new Date(newValue))}
                                                                            onAccept={(newValue) => handleOnchangeDate(newValue, i)} />
                                                                    </LocalizationProvider>
                                                                </form> }
                                                                {q.input === 7 && <form className="form-counseling">
                                                                    <div className="col-md-12">
                                                                        <div className="mb-3">
                                                                            <input
                                                                                readOnly
                                                                                className="form-control custom-input bg-jobc custom-input-changeJa align_input_text"
                                                                                type="text"
                                                                                id="name"
                                                                                placeholder= {t('selected_location')}
                                                                                aria-describedby="emailHelp"
                                                                                name="location"
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#joblocation"
                                                                                value={selectedLocation} />
                                                                            <span class="fas fa-caret-down errspan"></span>
                                                                        </div>
                                                                    </div>
                                                                </form> }
                                                            </div>
                                                            <div className="col-md-12 text-center">
                                                                {questionsList.length === i + 1 ?
                                                                    <>
                                                                        {(selectedAnswers[i] && next && isLoading === false) && <button className="main-buttons sub-btn" onClick={submit}>{t('submit')}</button> }
                                                                        {isLoading === true && <Spinner style={{ "color": "#02c090" }}></Spinner>}

                                                                    </> :
                                                                    <>
                                                                        {selectedAnswers[i] && next && <button className="main-buttons sub-btn" onClick={handleNext} id="nextButton">{t('next')}</button> }
                                                                    </>
                                                                }
                                                            </div>
                                                        </div> }
                                                    </>)
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    {/*AREA MODAL */}
                    <div
                        className="modal fade"
                        id="joblocation"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabindex="-1"
                        aria-labelledby="staticBackdropLabel"
                        aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                                <div className="modal-header border-none">
                                    <div className="col-md-12 text-center mt-60">
                                        <div className="main-heading hot-jobhead">
                                            <h4>{t('selected_location')}</h4>
                                        </div>
                                    </div>
                                    <button                                        
                                        type="button"
                                        className="Close-btn"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={clearLocation} >
                                        <i className="fa-solid fa-xmark"></i>
                                    </button>
                                </div>
                                <div className="modal-body p-0">
                                    <div className="search-area">
                                        {areaList?.map((row, index) => (
                                            <div>
                                                <div className="row">
                                                {row.prefectures.length > 0 ?      <div className="popup-head mt-3 mb-3 d-flex">
                                                        <h6>{row?.areaTitle}</h6>
                                                    </div>:null}
                                                </div>
                                                <div>
                                                    {row?.prefectures?.map((sub, index) => (
                                                        <>
                                                            <div className="form-check form-check-inline mb-2">
                                                                <input
                                                                    className="form-check-input form-chck"
                                                                    type="radio"
                                                                    name="radioLOc"

                                                                    id={sub?.prefectureId}
                                                                    value={sub?.prefectureId}
                                                                    onChange={(e) =>
                                                                        changeLocation(e, sub?.prefectureTitle)
                                                                    }
                                                                    checked={tempLocation == sub.prefectureTitle}
                                                                />
                                                                {/* <input className="form-check-input form-chck" type="checkbox" id="inlineCheckbox1" value={sub.checked} onChange={e=>onSubCategoryChange(sub,row)} /> */}
                                                                <label
                                                                    className="form-check-label ps-2"  data-toggle="tooltip" data-placement="bottom" title={sub?.prefectureTitle}
                                                                    for="inlineCheckbox1"
                                                                >
                                                                    {sub?.prefectureTitle}
                                                                </label>
                                                            </div>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="modal-footer border-none ">
                                    <div className="col-md-12 text-center">
                                        <button
                                            className="cancel-btn"
                                            data-bs-dismiss="modal"
                                            onClick={clearLocation}>
                                            {t("cancel")}
                                        </button>
                                        <button
                                            className="main-buttons sub-btn"
                                            data-bs-dismiss="modal"
                                            onClick={handleOnChangeArea}>
                                            {t("add")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </section>
            <a href="" class="edit-icon" data-bs-toggle="modal" id="successPopUpButton" data-bs-target="#successPopup" hidden></a>
            <div class="modal fade" id="successPopup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-confirm ">
                    <div class="modal-content">
                        <div class="modal-header justify-content-center" style={{ "background": "#ff3e3e !important" }}>
                            <div class="icon-box">
                                {response === true ? <i class="fa fa-check" aria-hidden="true"></i> : <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>}
                            </div>
                            <button type="button" id="successPopUpCloseButton" class="Close-btn" onClick={handleOnClickPopUp}
                                data-bs-dismiss="modal" aria-label="Close" style={{ "color": "white" }}><i class="fa-solid fa-xmark"></i></button>
                        </div>
                        <div class="modal-body text-center">
                            {response === true ? <h4>{t('success')}</h4> : <h4>{t('error')}</h4>}
                            {response === true ? <p>{t('details_submitted')}</p> : null}
                            <button class="btn btn-success" data-dismiss="modal" onClick={handleOnClickPopUp}><span>{t('ok')}</span></button>
                        </div>
                    </div>
                </div>
            </div>
            <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
            <ToastContainer
        position="bottom-right"
        autoClose={800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
            <script src="js/bootstrap.bundle.js"></script>

        </body>
    )
};


export default Counseling;
