import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "company",
  initialState: {
    contents: 1,
    freeText:"",
    industry:[],
    user:{}
  },
  reducers: {
    setPageCompanyCount: (state, action) => {
      state.contents = action.payload;
    },
    setSearchCompanyfield: (state, action) => {
      state.freeText = action.payload;
    },
    setSearchCompanyIndustryfield: (state, action) => {
        state.industry = action.payload;
        console.log( state.industry ,"state")
    },
    setuserDetails: (state, action) => {
      state.user = action.payload;
  }
  }
});

export const { reducer, actions } = slice;

export const selectCount = (state) => state.company.value;
