import React, { useEffect } from "react";
import styles from './service-details.module.css';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { Link } from 'react-router-dom';
import { actions } from "../../redux/companyReducer";
import { useDispatch } from 'react-redux';
const Servicedetails = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    let isLogin = localStorage.getItem("userId");
    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
        dispatch(actions.setuserDetails({}));
        // eslint-disable-next-line 
    }, []);

    return (
        <div className={styles.Servicedetails} data-testid="Servicedetails">
            <body className="d-flex flex-column h-100">

                <div className="banner-login middle-toparea">
                    <div className="container-fluid padding-body">
                        <div className="sub-menus"><Link className="topI" to={'/'}><a style={{"color":"#DBDBDB"}}>{t('top')}</a></Link> / <span>{t('service_introduction')}</span> </div>

                        <div className="login-header">
                            <h3>{t('service_introduction')}</h3>
                        </div>
                    </div>

                </div>

                <section id="service-intro" className="top-90">
                    <div className="padding-body">
                        <div className="row d-flex align-items-center">
                            <div className="main-heading hot-jobhead">
                                <h4>{t('who')}</h4>
                            </div>
                        </div>

                        <div className="top-90">
                            <div className="row mb-3 justify-content-center">
                                <div className="col-md-10">
                                    <p>DキャリはDX人材に特化した求人を発見できる人材紹介サービスです。DX関連の企業様とのお付き合いが長いDXHUB社ならではの求人で、業界に精通したコンサルタントが求職者のキャリアアップのサポートをいたします。これまでのご経験・ご経歴・スキルなどから最適な企業をご紹介します。また日系・外資系ともに求人を取り扱っております。活躍の舞台を幅広く対応できることで、ご自身のキャリアプランにあった求人をご紹介することが可能です。</p>

                                 
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
{/* 
                <section id="service-intro" className="top-90">
                    <div className="col-md-12 align-items-center ">
                        <div className="main-heading hot-jobhead">
                            <h4>{t('our_services')}</h4>
                        </div>
                    </div>

                    <div className="top-90">
                        <div className="container-fluid ">
                            <div className="row ">
                                <div className="col-md-6 col-md6 p-0">
                                    <div className="service-bg">
                                        <div className=" padding-body">
                                            <p className="color-serve mt-5 mb-5">Lorem ipsum dolor sit amet, consetetur sadipscing
                                                elitr, sed diam nonumy eirmod tempor invidunt ut labore et daliquyam erat, sed diam
                                                voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
                                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam no</p>
                                            <p className="color-serve mt-5 mb-5">Lorem ipsum dolor sit amet, consetetur sadipscing
                                                elitr, sed diam nonumy eirmod tempor invidunt ut labore et daliquyam erat, sed diam
                                                voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
                                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam no</p>
                                            <p className="color-serve mt-5 mb-5">Lorem ipsum dolor sit amet, consetetur sadipscing
                                                elitr, sed diam nonumy eirmod tempor invidunt ut labore et daliquyam erat, sed diam
                                                voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
                                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam no</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-md6 pe-col6 mb-5">
                                    <div className="services-page-right mb-5">
                                        <div className="serve-page-border">
                                            <img src={require("../../Images/img-service.png")} className="serve-page-img" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="top-90 padding-body">
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-10">

                                <div className="row">
                                    <div className="col-md-4 mb-4 mb-6">
                                        <div className="card card-serv">
                                            <div className="cd-icon d-flex justify-content-center">
                                                <div className="serve-sub1 text-center">
                                                    <i className="fa-solid fa-handshake"></i>
                                                </div>

                                            </div>

                                            <div className="card-body card-cd px-3">
                                                <p className="card-text">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                                                    diam nonumy eirmod tempor invidunt ut
                                                    labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam
                                                    et justo duo dolores et ea rebum.
                                                    Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                                                    amet. Lorem ipsum dolor sit amet,
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4 mb-4 mb-6">
                                        <div className="card card-serv">
                                            <div className="cd-icon d-flex justify-content-center">
                                                <div className="serve-sub1 text-center">
                                                    <i className="fa-solid fa-pen-to-square"></i>
                                                </div>

                                            </div>

                                            <div className="card-body card-cd px-3">
                                                <p className="card-text">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                                                    diam nonumy eirmod tempor invidunt ut
                                                    labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam
                                                    et justo duo dolores et ea rebum.
                                                    Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                                                    amet. Lorem ipsum dolor sit amet,
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-4 mb-6">
                                        <div className="card card-serv">
                                            <div className="cd-icon d-flex justify-content-center">
                                                <div className="serve-sub1 text-center">
                                                    <i className="fa-solid fa-chart-column"></i>
                                                </div>

                                            </div>

                                            <div className="card-body card-cd px-3">
                                                <p className="card-text">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                                                    diam nonumy eirmod tempor invidunt ut
                                                    labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam
                                                    et justo duo dolores et ea rebum.
                                                    Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
                                                    amet. Lorem ipsum dolor sit amet,
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                {/* <section id="service-intro" className="top-90">
                    <div className="padding-body">
                        <div className="row d-flex align-items-center">
                            <div className="main-heading hot-jobhead">
                                <h4>{t('how_to_apply')}</h4>
                            </div>
                        </div>

                        <div className="top-90">
                            <div className="row mb-3 justify-content-center">
                                <div className="col-md-10">
                                    <div className="row">

                                        <div className="col-md-6 col-apply6 mb-5">
                                            <div className="row">
                                                <div className="col-md-4 d-flex justify-content-center">
                                                    <div className="number-bg"><span>1</span></div>
                                                </div>
                                                <div className="col-md-8 m-auto">
                                                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                                        eirmod tempor invidunt ut labore et dolore magna
                                                        aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
                                                        dolores et ea rebum. Stet clita kasd gubergren,
                                                        no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor
                                                        sit amet,
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-apply6 mb-5">
                                            <div className="row">
                                                <div className="col-md-4 d-flex justify-content-center">
                                                    <div className="number-bg"><span>2</span></div>
                                                </div>
                                                <div className="col-md-8 m-auto">
                                                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                                        eirmod tempor invidunt ut labore et dolore magna
                                                        aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
                                                        dolores et ea rebum. Stet clita kasd gubergren,
                                                        no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor
                                                        sit amet,
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-apply6 mb-5">
                                            <div className="row">
                                                <div className="col-md-4 d-flex justify-content-center">
                                                    <div className="number-bg"><span>3</span></div>
                                                </div>
                                                <div className="col-md-8 m-auto">
                                                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                                        eirmod tempor invidunt ut labore et dolore magna
                                                        aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
                                                        dolores et ea rebum. Stet clita kasd gubergren,
                                                        no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor
                                                        sit amet,
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-apply6 mb-5">
                                            <div className="row">
                                                <div className="col-md-4 d-flex justify-content-center">
                                                    <div className="number-bg"><span>4</span></div>
                                                </div>
                                                <div className="col-md-8 m-auto">
                                                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                                                        eirmod tempor invidunt ut labore et dolore magna
                                                        aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo
                                                        dolores et ea rebum. Stet clita kasd gubergren,
                                                        no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor
                                                        sit amet,
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                <section id="ad-counseling" className="top-90">
                    <div className="counseling-section">
                        <div className="row">
                            <div className="col-md-6 margin-img counsel-hide ">
                                <div className="counsel-relative">
                                    <div className="counsel-border">
                                        <img src={require("../../Images/counseling.png")} alt="" className="counsel-img" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-custom-6">
                                <h3 className="text-white mt-5 mb-3">{t('start_your_career_with')}
                                {/* <br />
                                    {t('find_job_agency')}  */}
                                </h3>
                                {/* <Link to={"/counseling"}>
                                    <button className="counseling-btn2"> {t('click_here_for_career')} </button>
                                </Link> */}
                                { !isLogin&& <Link to={"/job-apply"}>
                                <button className="counseling-btn2"> {t('click_here_for_career')} </button>
                  </Link> }
                  {isLogin&& <Link to={"/career-confirmation"}>
                  <button className="counseling-btn2"> {t('click_here_for_career')} </button>
                  </Link> }
                            </div>
                        </div>
                    </div>
                </section>
                <script src="js/bootstrap.bundle.js"></script>
            </body>
        </div>
    )
};

export default Servicedetails;
