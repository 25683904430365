import React, { useEffect, useState } from 'react';
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { actions } from "../../redux/companyReducer";
import { useDispatch } from 'react-redux';
function RegistrationSuccess(){
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [mailMsg,setMailMsg] =  useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    dispatch(actions.setuserDetails({}));
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    setMailMsg(localStorage.getItem("mail_resend"))
    setTimeout(() => {
      localStorage.removeItem("mail_resend")
   }, 1000);
  }, []);
  function login() {
    navigate("/login");
  }

  return(
    <section className="top-90 mb-5">
    <div class="top-90">
    <div class="container my-5">
      <div class="card mt-3 Card-box">
        <div class="card-body">
          <div class="row mb-2 mt-5 px-3">
              <div class="col-md-12 text-center">
                  <i class="fa-regular fa-circle-check success-i"></i>
              </div>
  
              <div class="text-center mt-4 mb-4">
                  <h5>{t("registrion_success")}</h5> 
              </div>
  
              <div class="text-center">
                 {mailMsg !== null ?  (<p>{mailMsg}</p>):(<p>{t("user_sucess_register")}</p>)} 
              </div>
  
              <div class="text-center">
                  <button class="main-buttons mb-0" onClick={login}>{t("login")}</button>
                </div>
  
  
          </div>
        </div>
      </div>
    </div>
  </div>
  </section>
  )
}

export default RegistrationSuccess;
