import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import axios from "axios";
import {Link, useNavigate} from 'react-router-dom';
import Spinner from "../spinner/spinner";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import companylogo from '../../Images/logo-company.png';
import { actions } from "../../redux/companyReducer";
import { useDispatch } from 'react-redux';
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 2
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 300 },
    items: 1
  }
};
const responsive2 = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 599, min: 300 },
    items: 1
  }
};
const Startpage = () => {
  const [size, setSize] = useState({
    x: window.innerWidth,
    y: window.innerHeight
  });
  const updateSize = () =>
    setSize({
      x: window.innerWidth,
      y: window.innerHeight
    });

  let isLogin = localStorage.getItem("userId");
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [popularCompanies, setPopularCompanies] = useState([]);
  const [hotJobs, setHotJobs] = useState([]);
  const [pickupCategories, setPickupCategories] = useState([]);
  const [allBlogs, setAllBlogs] = useState([]);
  const [area, setArea] = useState([]);
  const [category, setCategory] = useState([]);
  const [isLoadedPopularCompanies, setIsLoadedPopularCompanies] = useState(false);
  const [isLoadedHotJobs, setIsLoadedHotJobs] = useState(false);
  const [isLoadedAllBlogs, setIsLoadedAllBlogs] = useState(false);
  const [isLoadedPickupCategories, setIsLoadedPickupCategories] = useState(false);

  const [selectedPrefecture, setSelectedPrefecture] = useState([])
  const [selectedArea, setSelectedArea] = useState([]);
  const [selectedJobCategory, setSelectedJobCategory] = useState([]);
  const [selectedJobSubCategory, setSelectedJobSubCategory] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    dispatch(actions.setuserDetails({}));
    getPopularCompanies();
    getHotJobs();
    getPickupCategories();
    getAllBlogs();
    getArea();
    getCategory();
    window.onresize = updateSize;
    console.log(size.x);
    // eslint-disable-next-line 
  }, []);

  function getPopularCompanies() {
    axios.get(process.env.REACT_APP_BASE_URL + "company/popular").then((response) => {  
      setPopularCompanies(response.data);
      setIsLoadedPopularCompanies(true);
      console.log(popularCompanies);
    }).catch(error => {
      setIsLoadedPopularCompanies(true);
      console.log('There was an error!', error);
    });
  }
  function getHotJobs() {
    axios.get(process.env.REACT_APP_BASE_URL + "job/hotjob").then((response) => {
      setHotJobs(response.data);
      setIsLoadedHotJobs(true);
      console.log(hotJobs);
    }).catch(error => {
      setIsLoadedHotJobs(true);
      console.log('There was an error!', error);
    });
  }
  function getPickupCategories() {
    axios.get(process.env.REACT_APP_BASE_URL + "category/pickup").then((response) => {
      setPickupCategories(response.data);
      setIsLoadedPickupCategories(true);
      console.log(pickupCategories);
    }).catch(error => {
      setIsLoadedPickupCategories(true);
      console.log('There was an error!', error);
    });
  }
  function getAllBlogs() {
    axios.get(process.env.REACT_APP_BASE_URL + "blog").then((response) => {
      setAllBlogs(response.data);
      setIsLoadedAllBlogs(true);
      console.log(allBlogs);
    }).catch(error => {
      setIsLoadedAllBlogs(true);
      console.log('There was an error!', error);
    });
  }

  function getArea() {
    axios.get(process.env.REACT_APP_BASE_URL + "category/area").then((response) => {
      setArea(response.data.areaList);
      console.log(area);
    }).catch(error => {
      console.log('There was an error!', error);
    });
  }
  function getCategory() {
    axios.get(process.env.REACT_APP_BASE_URL + "category").then((response) => {
      setCategory(response.data.categoryList);
      console.log(category);
    }).catch(error => {
      console.log('There was an error!', error);
    });
  }
  const handleOnChangeArea = (event, prefectures) => {
    console.log(prefectures)
    if (event.target.checked) {
      console.log("checked")
      prefectures.map((prefectureDetails) => {
        setSelectedPrefecture(selectedPrefecture => [parseInt(prefectureDetails.prefectureId), ...selectedPrefecture]);
        return 0;
      })
      setSelectedArea(selectedArea => [parseInt(event.target.value), ...selectedArea]);
      console.log(selectedArea);
    } else if (!event.target.checked) {
      console.log("unchecked")
      prefectures.map((prefectureDetails) => {
        setSelectedPrefecture(current =>
          current.filter(item => {
            return item !== parseInt(prefectureDetails.prefectureId);
          }),);
        return 0;
      })
      setSelectedArea(current =>
        current.filter(item => {
          return item !== parseInt(event.target.value);
        }),);
      console.log(selectedArea);
    }
  };
  const handleOnChangePrefecture = (event, prefectures, areaId) => {
    if (event.target.checked) {
      console.log("checked")
      setSelectedPrefecture(selectedPrefecture => [parseInt(event.target.value), ...selectedPrefecture]);
      console.log(selectedPrefecture);
      let flag;
      for (let i = 0; i < prefectures.length; i++) {
        if (document.getElementById("prefecture" + prefectures[i].prefectureId).checked === true) {
          flag = 1;
          console.log(flag)
        } else {
          flag = 0;
          break;
        }
      }
      if (flag === 1) {
        setSelectedArea(selectedArea => [parseInt(areaId), ...selectedArea]);
      }
    } else if (!event.target.checked) {
      console.log("unchecked")
      setSelectedPrefecture(current =>
        current.filter(item => {
          return item !== parseInt(event.target.value);
        }),);
      setSelectedArea(current =>
        current.filter(item => {
          return item !== parseInt(areaId);
        }),);
    }
  };
  const handleOnChangeJobCategory = (event, jobSubCategory) => {
    console.log(jobSubCategory)
    if (event.target.checked) {
      console.log("checked")
      jobSubCategory.map((jobSubCategoryDetails) => {
        setSelectedJobSubCategory(selectedJobSubCategory => [parseInt(jobSubCategoryDetails.subId), ...selectedJobSubCategory]);
        return 0;
      })
      setSelectedJobCategory(selectedJobCategory => [parseInt(event.target.value), ...selectedJobCategory]);
      console.log(selectedJobCategory);
    } else if (!event.target.checked) {
      console.log("unchecked")
      jobSubCategory.map((jobSubCategoryDetails) => {
        setSelectedJobSubCategory(current =>
          current.filter(item => {
            return item !== parseInt(jobSubCategoryDetails.subId);
          }),);
        return 0;
      })
      setSelectedJobCategory(current =>
        current.filter(item => {
          return item !== parseInt(event.target.value);
        }),);
      console.log(selectedJobCategory);
    }
  };
  const handleOnChangeJobSubCategory = (event, subCategory, categoryId) => {
    if (event.target.checked) {
      console.log("checked")
      setSelectedJobSubCategory(selectedJobSubCategory => [parseInt(event.target.value), ...selectedJobSubCategory]);
      console.log(selectedJobSubCategory);
      let flag;
      for (let i = 0; i < subCategory.length; i++) {
        if (document.getElementById("subCategory" + subCategory[i].subId).checked === true) {
          flag = 1;
          console.log(flag)
        } else {
          flag = 0;
          break;
        }
      }
      if (flag === 1) {
        setSelectedJobCategory(selectedJobCategory => [parseInt(categoryId), ...selectedJobCategory]);
      }
    } else if (!event.target.checked) {
      console.log("unchecked")
      setSelectedJobSubCategory(current =>
        current.filter(item => {
          return item !== parseInt(event.target.value);
        }),);
      setSelectedJobCategory(current =>
        current.filter(item => {
          return item !== parseInt(categoryId);
        }),);
    }
  };

  function clearArea() {
    setSelectedArea([]);
    setSelectedPrefecture([]);
  }
  function clearCategory() {
    setSelectedJobCategory([]);
    setSelectedJobSubCategory([]);
  }
  function numFormatter(num) {
    if(num != undefined && num !== 0 && num !== 1){
      if(num > 999999){
        return (num / 10000).toLocaleString("en-US") + '万円';
      }else if(num < 1000000 && num > 999){
        return (num/1000).toLocaleString("en-US") + '千円';
      }else if(num < 1000 && num > 99){
        return (num/100).toLocaleString("en-US") + '百円';
      }else if(num < 100){
        return (num).toLocaleString("en-US");
      }
    }
    if(num === 0){
       num = "上限なし"
       return num
    }
    if(num === 1){
      num = "応相談"
      return num
    }
  }
  const clickBlogTitle = (id) => {
    navigate("/blog-details/"+id);
  }
  return (
    <div data-testid="Startpage">
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js"></script>
      <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
      <body className="d-flex flex-column h-100">

        <div className="top-banner middle-toparea">
          <div className="container-fluid padding-body">

            <div className="banner-top">
            <h3 className="mb-4" dangerouslySetInnerHTML={{ __html: t('the_easiest_way') }}></h3>
              {/* <p className="top-paragrm">{t('cathphrase_visualkey')}</p> */}
              { !isLogin&& <Link to={"/registration"}>
                <button className="top-button mt-5"> {t('user_register')} </button>
              </Link>}
            </div>
          </div>

        </div>
        <div className="clearfix"></div>

        <div className="container-fluid p-0">
        
        {/* {size.x > 760 && popularCompanies.length < 4 &&<div name="normalPopular">
          {popularCompanies.length > 0 ? <section id="Company" className="top-90">

            <div className="padding-body">
              <div className="row d-flex align-items-center">
                <div className="main-heading ">
                  <h4> {t('popular_companies')} </h4>
                </div>
              </div>
              <div className="company-list top-90">
                {isLoadedPopularCompanies ? <div className="row mb-3 justify-content-center">
                  {popularCompanies.map((companyDetails) => {
                    return (
                      <div className="col-md-2 mb-3">
                        <Link to={"/company-details/" + companyDetails.Company.companyId}>
                          <div className="card  Card-box1 text-center" style={{"overflow":"hidden"}}>
                            <div className="card-body">
                              <img src={(companyDetails?.Company?.logoUrl === "" || companyDetails?.Company?.logoUrl === null ) ? companylogo : companyDetails?.Company?.logoUrl} alt="" className="img-fluid-custom" style={{"objectFit":"contain"}} />
                            </div>
                          </div>
                        </Link>
                      </div>
                    )
                  })}
                </div> : <Spinner></Spinner>}
              </div>
            </div>
          </section> : null}
          </div>}

          {size.x <= 760  &&<div name="carousalPopular">
          {popularCompanies.length > 0 && <section id="Company" className="top-90">
      <div className="padding-body" id="popularCompany">
      <div className="row d-flex align-items-center">
                <div className="main-heading ">
                  <h4> {t('popular_companies')} </h4>
                </div>
              </div>
      <div className="company-list top-90">
      {isLoadedPopularCompanies ?   <div className="row mb-3 justify-content-center">
        <Carousel responsive={responsive2} styles={{ "paddingRight": "40px" }}>
                    {popularCompanies.map((companyDetails) => {
                      return (
                        <Link to={"/company-details/" + companyDetails.Company.companyId}>
                        <div className="card  Card-box1 text-center" style={{"overflow":"hidden","marginRight":"20px"}}>
                          <div className="card-body">
                            <div className="blog-whitebg">
                              <img src={(companyDetails?.Company?.logoUrl === "" || companyDetails?.Company?.logoUrl === null ) ? companylogo : companyDetails?.Company?.logoUrl} className="popular-card-img-top" alt="..." style={{"objectFit":"contain"}}/>
                            </div>
                          </div>
                        </div>
                        </Link>
                      )
                    })}
                  </Carousel>
            </div> : <Spinner></Spinner>}

            </div>
            </div>
            </section> }
          </div>}
          {size.x >= 760 && popularCompanies.length >= 4 &&<div name="carousalPopular">
          {popularCompanies.length > 0 ? <section id="Company" className="top-90">
      <div className="padding-body" id="popularCompany">
      <div className="row d-flex align-items-center">
                <div className="main-heading ">
                  <h4> {t('popular_companies')} </h4>
                </div>
              </div>
      <div className="company-list top-90">
      {isLoadedPopularCompanies ?   <div className="row mb-3 justify-content-center">
        <Carousel responsive={responsive2} styles={{ "paddingRight": "40px" }}>
                    {popularCompanies.map((companyDetails) => {
                      return (
                        <Link to={"/company-details/" + companyDetails.Company.companyId}>
                        <div className="card  Card-box1 text-center" style={{"overflow":"hidden","marginRight":"20px"}}>
                          <div className="card-body">
                            <div className="blog-whitebg">
                              <img src={(companyDetails?.Company?.logoUrl === "" || companyDetails?.Company?.logoUrl === null ) ? companylogo : companyDetails?.Company?.logoUrl} className="popular-card-img-top" alt="..." style={{"objectFit":"contain"}}/>
                            </div>
                          </div>
                        </div>
                        </Link>
                      )
                    })}
                  </Carousel>
            </div> : <Spinner></Spinner>}

            </div>
            </div>
            </section> : null}
          </div>} */}
          <div className="clearfix"></div>

          <section id="Service" className="service-bg">
            <div className="padding-body">

              <div className="row serviceTop-90">
                <div className="col-md-6 service-col6">
                  <div className="service-heading">
                    <h2>{t('servcie_highlights')}</h2>
                    <div className="service-border"></div>
                  </div>

                  <p className="color-serve mt-5 mb-5">DキャリはDX人材に特化した求人を発見できる人材紹介サービスです。</p>


                  <div className="clearfix"></div>

                  <div className="row">
                    <div className="col-md-12 display-hide">

                      <div className="services-right">
                        <div className="serve-img-border">
                          <img src={require("../../Images/serve-img.png")} alt="" className="serve-img" style={{"objectFit":"cover"}}  />
                        </div>
                      </div>

                    </div>
                  </div>


                  <div className="row mt-35 align-items-center">
                    <div className="col-md-2 colmd2-2 dy-flex justify-content-center">
                      <div className="serve-sub text-center">
                        <i className="fa-solid fa-handshake"></i>
                      </div>
                    </div>
                    <div className="col-md-10 m-auto custom-col10">
                      <div className="color-serve pt2">
                      <p> {t('top-servuce-heading1')} <br />
                    {t('top-servuce-section1')}</p> 
                    
                      </div>
                      <div className="border-serve"></div>
                    </div>
                  </div>
 
                  <div className="row mt-35  align-items-center">
                    <div className="col-md-2 colmd2-2 dy-flex justify-content-center">
                      <div className="serve-sub text-center fnt-sub-edit">
                        <i className="fa-solid fa-pen-to-square"></i>
                      </div>
                    </div>
                    <div className="col-md-10  m-auto custom-col10">
                      <div className="color-serve pt2">
                      <p> {t('top-servuce-heading2')} <br />
                     {t('top-servuce-section2')}</p>
                      </div>
                      <div className="border-serve"></div>                    
                    </div>

                  </div>
                  <div className="row mt-35  align-items-center">
                    <div className="col-md-2 colmd2-2 dy-flex justify-content-center">
                      <div className="serve-sub text-center">
                        <i className="fa-solid fa-chart-column"></i>
                      </div>
                    </div>
                    <div className="col-md-10 m-auto custom-col10">
                      <div className="color-serve pt2">
                      <p> {t('top-servuce-heading3')} <br />
                     {t('top-servuce-section3')}</p>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="col-md-6 display-window service-col6">
                  <div className="services-right">
                    <div className="serve-img-border">
                      <img src={require("../../Images/serve-img.png")} alt="" className="serve-img" style={{"objectFit":"cover"}}  />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </section>
          <div className="clearfix"></div>

          {hotJobs.length > 0 &&<section id="Hotjobs" className="top-90">
            <div className="padding-body">
              <div className="row d-flex align-items-center">
                <div className="main-heading hot-jobhead">
                  <h4>{t('hot_jobs')}</h4>
                </div>
              </div>
              <div className="top-90">
                <div className="row mb-3 justify-content-center">
                  {isLoadedHotJobs ? <div className="col-md-10">

                    {hotJobs.map((jobDetails) => {
                      return (
                        <div className="card job-card">
                          <div className="card-body p-4">
                            <div className="row">
                              <div className="col-md-10 col-custom10">
                                <h5>{jobDetails.job.jobName}</h5>
                                <p className="description-text ">{jobDetails.job.description}</p>
                                <div className="row ps-2">
                                  {jobDetails.job.tags.map((tagDetails) => {
                                    return (
                                      <button className="tag-bg mb-2">{tagDetails.tagName}</button>
                                    )
                                  })}
                                </div>
                                <div  class="row pt-3 colr-catch-font "><span className='saperate-text '>{jobDetails?.job?.Company?.catchPhrase}</span></div>
                                <div className="row pt-4">
                                  {/* <div className="col-md-3 job-col2">
                                    <div className="icons-btm d-flex" 
                                    > <div><i className="fas fa-building yen-font pe-2"></i>   </div>
                                    <div className="table-text"><span
                                    data-toggle="tooltip" data-placement="bottom" title={jobDetails?.job.Company.companyName} className="font-16">{jobDetails.job.Company.companyName}</span></div></div>
                                  </div> */}
                                  {/* {jobDetails?.job?.prefectures?.map((prefecture, index) => (
                                    <div key={index} className="col-md-3 job-col2">
                                      <div className="icons-btm d-flex">
                                        <div>
                                          <i className="fa fa-map-marker-alt yen-font pe-2"></i>
                                        </div>
                                        <div className="table-text">
                                          <span
                                            className="font-16" data-toggle="tooltip" data-placement="bottom" title={prefecture?.prefectureName}>{prefecture.prefectureName}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ))} */}
                                {jobDetails?.job?.prefectures?.length > 0 && (
                                  <div className="col-md-4 job-col2">
                                    <div className="icons-btm d-flex">
                                      <div>
                                        <i className="fa fa-map-marker-alt yen-font pe-2"></i>
                                      </div>
                                      <div
                                        style={{
                                          width: '75%',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap',
                                        }}
                                        title={jobDetails?.job?.prefectures
                                          ?.map((prefecture) => prefecture?.prefectureName)
                                          .join(', ')}
                                      >
                                        {jobDetails?.job?.prefectures?.map((prefecture, index, array) => (
                                          <span
                                            key={index}
                                            className="font-16"
                                            data-toggle="tooltip"
                                            data-placement="bottom"
                                          >
                                            {prefecture?.prefectureName}
                                            {index < array.length - 1 && ', '}
                                          </span>
                                        ))}
                                      </div>
                                    </div>
                                  </div>  
                                  )}
                              {(jobDetails?.job?.Company?.industry?.industryName) ? <div className="col-md-3 job-col2">
                                    <div className="icons-btm d-flex"
                                   > <div><i className="fa fa-suitcase yen-font pe-2"></i>  </div>
                                   <div className="table-text"> <span
                                    data-toggle="tooltip" data-placement="bottom" title={jobDetails?.job.Company.industry.industryName} className="font-16">{jobDetails.job.Company.industry.industryName}</span></div>
                                  </div>
                                  </div>:<></>}
                                 {(jobDetails?.job?.salaryRange?.minimumSalary) ? <div className="col-md-3 job-col2">
                                    <div className="icons-btm d-flex"
                                   ><div> <i className="fa-solid fa-yen-sign yen-font pe-2"></i>  </div>
                                   <div className="table-text"> <span
                                    data-toggle="tooltip" data-placement="bottom" title={numFormatter(jobDetails.job.salaryRange.minimumSalary) + " - " + numFormatter(jobDetails.job.salaryRange.maximumSalary)}
                                     className="font-16 yen-color">{numFormatter(jobDetails.job.salaryRange.minimumSalary)} - {numFormatter(jobDetails.job.salaryRange.maximumSalary)}</span></div>
                                  </div>
                                  </div>: <></>}
                                </div>
                              </div>

                              <div className="col-md-2 m-auto text-center col-custom-2">
                                <Link to={"/job-details/" + jobDetails.job.jobId}>
                                  <button className="job-details"> {t('job_details')} </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}

                    <div className="text-center">
                      <Link to={"/job-list/1?area=&prefecture=&category=&subCategory=&industry=&skill=&maxSalary=&minSalary="}>
                        <button className="main-buttons"> {t('view_all_jobs')}</button>
                      </Link>
                    </div>
                  </div> : <Spinner></Spinner>}
                </div>
              </div>
            </div>
          </section> }

          <div className="clearfix"></div>

          <section id="job-search">
            <div className="jobsearch">
              <div className="container-fluid padding-body">

                <div className="col-md-12 text-center">
                  <div className="job-header">
                    <h3> {t('job_search')} </h3>
                  </div>
                  <p className="text-white py-3">ご希望の勤務地、職種から求人の検索が可能です。</p>

                  <div className="col-md-12 mt-custom-5">
                    <button className="place-btn" data-bs-toggle="modal" data-bs-target="#placesearch"> {t('place')} </button>
                    <button className="Occupation-btn" data-bs-toggle="modal"
                      data-bs-target="#occupationsearch"> {t('occupation')} </button>
                  </div>
                </div>
              </div>

            </div>
          </section>
          <section id="ad-counseling">
            <div className="counseling-section">
              <div className="row">
                <div className="col-md-6 margin-img counsel-hide ">
                  <div className="counsel-relative">
                    <div className="counsel-border">
                      <img src={require("../../Images/counseling.png")} alt="" className="counsel-img" style={{"objectFit":"cover"}} />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-custom-6">
                  <h3 className="text-white mt-5 mb-3">{t('start_your_career_with')}
                  {/* <br />
                    {t('find_job_agency')}  */}
                    
                  </h3>
                  {/* <Link to={"/counseling"}>
                 
                  </Link> */}
                   { !isLogin&& <Link to={"/job-apply"}>
                   <button className="counseling-btn2"> {t('click_here_for_career')} </button>
                  </Link> }
                  {isLogin&& <Link to={"/career-confirmation"}>
                  <button className="counseling-btn2"> {t('click_here_for_career')} </button>
                  </Link> }
                </div>
              </div>
            </div>
          </section>


          {allBlogs.length > 0 && <section id="blog" className="top-90">
            <div className="padding-body">
              <div className="row d-flex align-items-center">
                <div className="main-heading ">
                  <h4> {t('blog_and_articles')} </h4>
                </div>
              </div>

              <div className="top-90 ">
                {isLoadedAllBlogs ? <div className="row mb-3 justify-content-center">
                  <div className="col-md-10">
                    <div className="row" style={{ "justifyContent": "center" }}>
                      {allBlogs.length > 1 ? <Carousel responsive={responsive} styles={{ "paddingRight": "40px" }}>
                        {allBlogs.map((blog) => {
                          return (
                            <div className="col-md-10 col-blog6">
                              <div className="card Card-blog  mb-3">
                                <div className="blog-whitebg">
                                  <img src={blog.imageUrl} className="blog-card-img-top" alt="..." style={{"objectFit":"cover","cursor":"default"}}/>
                                  <div className="blog-heading px-4 py-3">
                                    <div className="blog-head" onClick={() => clickBlogTitle(blog.blogId)}>{blog.blogName}</div>
                                  </div>
                                </div>
                                <div className="blog-card-body pt-5 pb-4 p-left" style={{"cursor":"default"}}>
                                  <p className="card-text pb-4">
                                  <span style={{"overflow":"hidden","text-overflow":"ellipsis","display":"-webkit-box","-webkit-line-clamp":"3","-webkit-box-orient":"vertical"}}> {blog.description}</span></p>         
                                     <a href={"/blog-details/" + blog.blogId} className="read-more pb-3 pt-3">{t('read_more')} <i
                                    className="fa-solid fa-arrow-right-long ps-3"></i></a>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </Carousel> : <>
                        {allBlogs.map((blog) => {
                          return (
                            <div className="col-md-6 col-blog6">
                              <div className="card Card-blog  mb-3">
                                <div className="blog-whitebg">
                                  <img src={blog.imageUrl} className="blog-card-img-top" alt="..." style={{"objectFit":"cover","cursor":"default"}} />
                                  <div className="blog-heading px-4 py-3">
                                    <div className="blog-head" onClick={() => clickBlogTitle(blog.blogId)}>{blog.blogName}</div>
                                  </div>
                                </div>
                                <div className="blog-card-body pt-5 pb-4 p-left" style={{ "minHeight": "300px", "maxHeight": "300px","cursor":"default" }}>
                                  <p className="card-text pb-4">
                                   <span style={{"overflow":"hidden","text-overflow":"ellipsis","display":"-webkit-box","-webkit-line-clamp":"3","-webkit-box-orient":"vertical"}}> {blog.description}</span></p>
                                  <a href={"/blog-details/" + blog.blogId} className="read-more pb-3 pt-3">{t('read_more')} <i
                                    className="fa-solid fa-arrow-right-long ps-3"></i></a>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </>}
                    </div>
                  </div>
                </div> : <Spinner></Spinner>}
              </div>
            </div>
          </section> }
          {pickupCategories.length > 0 && <section id="Pickupcategories" className="top-90">
            <div className="padding-body">
              <div className="row d-flex align-items-center">
                <div className="main-heading ">
                  <h4>{t('pickup_categories')}</h4>
                </div>
              </div>

              <div className="top-90 ">
                {isLoadedPickupCategories ? <div className="row justify-content-center mb-3 ">
                  <div className="col-md-10 ">
                    <div className="row mb-3">
                      {pickupCategories.map((categories) => {
                        return (
                          <div className="col-md-4 mb-3"  style={{"margin":"0 auto"}}>
                            <Link to={"/job-list/1?area=&prefecture=&category=&subCategory=" +categories.subCategory.categoryId +
                             "&industry=&skill=&maxSalary=&minSalary="}
                              state={{
                                selectedArea: "",
                                selectedPrefecture: "",
                                selectedJobCategory: "",
                                selectedJobSubCategory: [categories.subCategory.categoryId],
                                selectedIndustry: "",
                                selectedMaxSalary: "",
                                selectedMinSalary: "",
                                selectedSkill: ""
                              }}>
                              <button className="category-btn mb-3"
                               data-toggle="tooltip" data-placement="bottom" title={categories.subCategory?.name} >
                                <span style={{"overflow":"hidden","text-overflow":"ellipsis","display":"-webkit-box","-webkit-line-clamp":"2","-webkit-box-orient":"vertical"}}>{categories.subCategory?.name}</span></button>
                            </Link>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div> : <Spinner></Spinner>}
              </div>
            </div>
          </section> }
{/* 
          <section id="link-section" className="top-90 mb-5">
            <div className="padding-body">
              <div className="row justify-content-center">
                <div className="col-md-10">
                  <div className="row">
                    <div className="col-md-6 col-blog6">
                      <div className="card Card-link  mb-3">
                        <div className="link-banner">
                          <a href="https://www.okaygo.in/blogs/top-insurance-company-for-delivery-bike-riders" target="_blank">
                            <img src={require("../../Images/blog-img1.png")} className="card-img-top" alt="..." style={{"objectFit":"cover"}} />
                            <div className="link-bg">
                              <p>Lorem ipsum dolor sit amet, consetetur
                                sadipscing elitr, sed diam </p>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-blog6">
                      <div className="card Card-link  mb-3">
                        <div className="link-banner">
                          <a href="https://www.okaygo.in/blogs/top-insurance-company-for-delivery-bike-riders" target="_blank">
                            <img src={require("../../Images/blog-img1.png")} className="card-img-top" alt="..." style={{"objectFit":"cover"}}  />
                            <div className="link-bg">
                              <p>Lorem ipsum dolor sit amet, consetetur
                                sadipscing elitr, sed diam </p>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </section> */}

        </div>


        {/* MODAL AREA */}

        <div className="modal fade" id="placesearch" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
          aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered  modal-lg">

            <div className="modal-content">
              <div className="modal-header border-none">
                <div className="col-md-12 text-center mt-60">
                  <div className="main-heading hot-jobhead">
                    <h4>{t('search_by_place')}</h4>
                  </div>
                </div>
                <button type="button" className="Close-btn" data-bs-dismiss="modal" aria-label="Close" onClick={clearArea}><i
                  className="fa-solid fa-xmark"></i></button>
              </div>

              <div className="modal-body p-0">

                <div className="search-area">
                  {area.map((areaDetails) => {
                    return (
                      <div>
                        <div className="row">
                        {areaDetails.prefectures.length > 0 ?  <div className="popup-head mt-3 mb-3 d-flex">
                            <h6>{areaDetails.areaTitle}</h6>
                            <input className="form-check-input form-chck ms-2" type="checkbox" onChange={e => handleOnChangeArea(e, areaDetails.prefectures)}
                              id={"area" + areaDetails.id} value={areaDetails.id} checked={selectedArea.includes(areaDetails.id)} />
                          </div> : null}
                        </div>
                        <div>
                          {areaDetails.prefectures.map((prefectureDetails) => {
                            return (
                              <div className="form-check form-check-inline mb-2">
                                <input className="form-check-input form-chck" type="checkbox" onChange={e => handleOnChangePrefecture(e, areaDetails.prefectures, areaDetails.id)}
                                  id={"prefecture" + prefectureDetails.prefectureId} value={prefectureDetails.prefectureId} checked={selectedPrefecture.includes(prefectureDetails.prefectureId)} />
                                <label className="form-check-label ps-2" for="inlineCheckbox1"
                                 data-toggle="tooltip" data-placement="bottom" title={prefectureDetails?.prefectureTitle}>{prefectureDetails.prefectureTitle}</label>
                              </div>)
                          })}
                        </div>

                      </div>)
                  })}

                </div>

              </div>
              <div className="modal-footer border-none ">
                <div className="col-md-12 text-center text-col12">
                  <button className="cancel-btn" onClick={clearArea}>{t('clear')}</button>
                  <Link to={"/job-list/1?area="+selectedArea+"&prefecture="+selectedPrefecture+"&category=&subCategory=&industry=&skill=&maxSalary=&minSalary="}
                    state={{
                      selectedArea: selectedArea,
                      selectedPrefecture: selectedPrefecture,
                      selectedJobCategory: "",
                      selectedJobSubCategory: "",
                      selectedIndustry: "",
                      selectedMaxSalary: "",
                      selectedMinSalary: "",
                      selectedSkill: ""
                    }}>
                    <button className="main-buttons sub-btn" data-bs-dismiss="modal">{t('search')}</button>
                  </Link>
                </div>
              </div>
            </div>

          </div>
        </div>

        {/* MODAL CATEGORY */}

        <div class="modal fade" id="occupationsearch" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered  modal-lg">
            <div class="modal-content">
              <div class="modal-header border-none">
                <div class="col-md-12 text-center mt-60">
                  <div class="main-heading hot-jobhead"><h4>{t('search_by_job_category')}</h4>
                  </div>
                </div>
                <button type="button" class="Close-btn" data-bs-dismiss="modal" aria-label="Close" onClick={clearCategory}><i class="fa-solid fa-xmark"></i></button>
              </div>

              <div className="modal-body p-0">
                <div className="search-area">
                  {category.map((categoryDetails) => {
                    return (<div>
                      <div className="row">
                      {categoryDetails.subCategoryList.length > 0 ?  <div className="popup-head mt-3 mb-3 d-flex">
                          <h6>{categoryDetails.categoryTitle}</h6>
                          <input className="form-check-input form-chck ms-2" type="checkbox" onChange={e => handleOnChangeJobCategory(e, categoryDetails.subCategoryList)}
                            id={"category" + categoryDetails.id} value={categoryDetails.id} checked={selectedJobCategory.includes(categoryDetails.id)} />
                        </div> : null}
                      </div>

                      <div>
                        {categoryDetails.subCategoryList.map((subCategoryDetails) => {
                          return (
                            <div className="form-check form-check-inline mb-2">
                              <input className="form-check-input form-chck" type="checkbox" onChange={e => handleOnChangeJobSubCategory(e, categoryDetails.subCategoryList, categoryDetails.id)}
                                id={"subCategory" + subCategoryDetails.subId} value={subCategoryDetails.subId} checked={selectedJobSubCategory.includes(subCategoryDetails.subId)} />
                              <label className="form-check-label ps-2" for="inlineCheckbox1"
                               data-toggle="tooltip" data-placement="bottom" title={subCategoryDetails?.subCategoryTitle}>{subCategoryDetails.subCategoryTitle}</label>
                            </div>)
                        })}
                      </div>
                    </div>)
                  })}

                </div>
              </div>
              <div class="modal-footer border-none ">
                <div class="col-md-12 text-center text-col12">
                  <button class="cancel-btn" onClick={clearCategory}>{t('clear')}</button>
                  <Link to={"/job-list/1?area=&prefecture=&category="+selectedJobCategory+"&subCategory="+selectedJobSubCategory+
                             "&industry=&skill=&maxSalary=&minSalary="}
                    state={{
                      selectedArea: "",
                      selectedPrefecture: "",
                      selectedJobCategory: selectedJobCategory,
                      selectedJobSubCategory: selectedJobSubCategory,
                      selectedIndustry: "",
                      selectedMaxSalary: "",
                      selectedMinSalary: "",
                      selectedSkill: ""
                    }}>
                    <button className="main-buttons sub-btn" data-bs-dismiss="modal">{t('search')}</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <script src="js/bootstrap.bundle.js"></script>
      </body>
    </div>
  )
};
export default Startpage;
