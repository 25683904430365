import styles from './footer.module.css';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import React, { useEffect, useState } from "react";
import logo from '../../Images/logofooter.png'

const Footer = () =>{
  const { t } = useTranslation();
  const [language, setLanguage] = useState();
  useEffect(() => {
    if (localStorage.getItem("LANGUAGE")) {
      setLanguage(localStorage.getItem("LANGUAGE"))
    }
    else {
      setLanguage("ja");
      i18n.changeLanguage("ja");
    }
    console.log(language);
    // eslint-disable-next-line 
 },[]);
  return (
<footer className="footer mt-auto">
    <div className="footer-bg py-3">
      <div className="container-fluid padding-body">
        <div className="row py-3">
          <div className="col-md-2">
            <nav className="nav flex-column">
            <a className="nav-link nav-footer" href="/job-search">{t('job_search')}</a>
          <a className="nav-link nav-footer" href="/service-details">{t('about_service')} </a>
          <a className="nav-link nav-footer" href="https://dxhub.co.jp/" target="_blank">{t('company_profile_footer')}</a>
            </nav>
          </div>
          <div className="border-left col-md-1 "></div>
          <div className="border-bottom1 col-md-1 "></div>
          <div className="col-md-6">
            <nav className="nav flex-column">
          <a className="nav-link nav-footer" href="/inquiry">{t('inquiries')} </a>
          <a className="nav-link nav-footer" href="/privacy-policy">{t('privacy_policy')}</a>
          <a className="nav-link nav-footer" href="/terms-of-use">{t('terms_of_use')}</a>
          <a className="nav-link nav-footer" target="_blank" href="https://sites.google.com/dxhub.co.jp/scope-of-services/%E3%83%9B%E3%83%BC%E3%83%A0">{t('occupation_handling')}</a>
          {/* <a className="nav-link nav-footer" href="/faq">{t('faq')}</a> */}
            </nav>
          </div>
         <div className="col-md-3 justify-content-center ms-auto flex-column d-flex footer-pa-logo">
          <div className='footer-logo m-auto'>
            <img src={logo}></img>
          </div>
            <p className='footer-colr-plogo m-auto pt-3 footer-plogo'> 有料職業紹介事業許可番号：26-ユ-300607</p>
          </div>

        </div>
      </div>
    </div>
    <div className="footer-bottom mt-auto">
      <div className="col-md-12 text-center">
        <span className="copy-right"><i className="fa-regular fa-copyright"></i> DXHUB. All Rights Reserved</span>
      </div>
    </div>
  </footer>
)
  };



export default Footer;
