import { applyMiddleware, configureStore } from "@reduxjs/toolkit";
import { reducer as companyReducer } from "./companyReducer";
import {persistStore,persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import storageSession from 'redux-persist/lib/storage/session'
const persistConfig={
  key:'root',
  storage:storageSession,
  }

const persistedReducer=persistReducer(persistConfig,companyReducer)
 const store = configureStore({
  reducer: {
    company: persistedReducer,
  }
},applyMiddleware())

const persistor = persistStore(store)

export {persistor}
export default store