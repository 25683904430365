import React, { useEffect, useState } from "react";
import { useParams,Link,useNavigate } from "react-router-dom";
import useAppContext from "../../AppContext";
import building from '../../Images/building.svg';
import industrySVG from '../../Images/suitcase.svg';
import locationSVG from '../../Images/Icon material-location-on.svg'
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import companylogo from '../../Images/logo-company.png';
import { actions } from "../../redux/companyReducer";
import { useDispatch } from 'react-redux';
function Companydetails() {
  let { id } = useParams();
  const { t } = useTranslation();
  const appContext = useAppContext();
  const axios = appContext.getAxios();
  const [company, setCompany] = useState();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.setuserDetails({}));
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    getCompanyById();
  }, []);

  const getCompanyById = async () => {
    setOpen(true);
    axios.get("company/" + id).then(
      (response) => {
        
        console.log(response)
        // if(response?.data?.companyLogo === ""|| response?.data?.companyLogo === undefined){
        //     response.data.companylogo = companylogo;
        // }
        
        setCompany(response.data);
        setOpen(false);
      },
      (error) => {
        console.log(error?.response?.data?.errorCode)
        if(error?.response?.data?.errorCode === 25201 || error?.response?.data?.errorCode === "25201"){
            navigate("/company-list/1");
            
        } 
          
        setOpen(false);
      }
    );
  };
  function numFormatter(num) {
    if(num != undefined && num !== 0 && num !== 1){
        if(num > 999999){
          return (num / 10000).toLocaleString("en-US") + '万円';
        }else if(num < 1000000 && num > 999){
          return (num/1000).toLocaleString("en-US") + '千円';
        }else if(num < 1000 && num > 99){
          return (num/100).toLocaleString("en-US") + '百円';
        }else if(num < 100){
          return (num).toLocaleString("en-US");
        }
      }
      if(num === 0){
         num = "上限なし"
         return num
      }
      if(num === 1){
        num = "応相談"
        return num
      }
  }
  return (
    <html class="notranslate" translate="no">
    <div>
 
<section id="Hotjobs" className="top-90">
        <div className="padding-body mt-3">
            <div className="sub-text">  <Link className="breadcurumb" to={"/"}>
              {t("top")}
            </Link>{" "} / <Link className="breadcurumb" to={"/company-list/1"}> {t("company_list")}</Link> / <span>{t("company_details")}</span> </div>

            <div className="top-90">
                <div className="row mb-3 justify-content-center">

                  

                    <div className="col-md-10 custom-col9">
                        <div className="row">
                            <div className="card job-list-card ">
                                <div className="card-body p-4">
                                    <div className="row">

                                        {/* <div className="col-md-2 colmd-2 d-flex justify-content-center">
                                            <div className="company-logo">
                                                <img src={(company?.companyLogo === ""||company?.companyLogo === null)? companylogo :  company?.companyLogo} alt="" />
                                            </div>
                                        </div> */}

                                        <div className="col-md-12 colmd-10 mt-2">
                                           <span className="site_text_order "> <h5>{company?.catchPhrase}</h5></span>
                                            <p className="site_text_order ">{company?.description}
                                            </p>
                                        </div>

                                    </div>

                                    <div className="details-section ">
                                        <table className="table pt-2">
                                            <tbody>
                                            {(company?.industry?.industryName) ?  <tr>
                                                    <td scope="row" className="details-head">{t("industry")}</td>
                                                    <td className="details-p site_text_order ">{company?.industry?.industryName}</td>
                                                </tr> :<></>}
                                                {(company?.capital)?   <tr>
                                                    <td scope="row" className="details-head">{t("company_capital")}</td>
                                                    <td className="details-p site_text_order ">{new Intl.NumberFormat('ja').format(company?.capital)}{t("yen")}</td>
                                                </tr> : <></>}
                                                {/* {(company?.companyURL)? <tr>
                                                    <td scope="row" className="details-head">{t("url")}</td>
                                                    <td className="details-p site_text_order "> <a href={company?.companyURL} target="_blank">{company?.companyURL}</a> </td>
                                                </tr> :<></>} */}
                                                {/* {(company?.prefecture?.prefectureName)?<tr>
                                                    <td scope="row" className="details-head">{t("location")}</td>
                                                    <td className="details-p site_text_order "> {company?.prefecture?.prefectureName}</td>
                                                </tr> :<></>} */}
                                                            {company?.prefectures?.length > 0 && (
                                                                <tr>
                                                                    <td scope="row" className="details-head">{t("location")}</td>
                                                                    <td className="details-p site_text_order">
                                                                        {company?.prefectures?.map((prefecture, index, array) => (
                                                                            <span key={index}>
                                                                                {prefecture?.prefectureName}
                                                                                {index < array.length - 1 && ', '}
                                                                            </span>
                                                                        ))}
                                                                    </td>
                                                                </tr>
                                                            )}
                                          {/* {(company?.headquartersLocation)?<tr>
                                                    <td scope="row" className="details-head">{t("head_quarters_location")}</td>
                                                    <td className="details-p site_text_order "> {company?.headquartersLocation}</td>
                                                </tr>:<></>} */}
                                               { (company?.businessContent)? <tr>
                                                    <td scope="row" className="details-head">{t("business_content")}</td>
                                                    <td className="details-p site_text_order ">{company?.businessContent}</td>
                                                </tr>:<></>}
                                                {(company?.working_environment)? <tr>
                                                    <td scope="row" className="details-head">{t("working_enviroment")}</td>
                                                    <td className="details-p site_text_order "> {company?.working_environment}</td>
                                                </tr>:<></>}
                                               
                                            </tbody>
                                        </table>
                                    </div>
                                </div>





                            </div>
                          
                            {company?.jobs.length > 0 ? 
                            <div className="card job-list-card  top-90">
                                <div className="card-body p-4">
                                    <div className="row mb-3 justify-content-center p-4 mobile-padding">
                                        <div className="col-md-12">

                                        {company?.jobs?.map((row, index) => (
                                            <div className="card job-card">
                                                <div className="card-body p-4">
                                                    <div className="row">
                                                        <div className="col-md-10 col-custom10">
                                                        <span className="site_text_order ">  <h5>{row?.jobTitle}</h5></span>
                                                            <p  className="site_text_order description-text ">{row?.jobDescription}</p>

                                                            <div className="row ps-2">
                                                            {row?.tags?.map((tagRow, index) => (
                                                                <button className="tag-bg mb-3">{tagRow?.tagName}</button>
                                                            ))}
                                                               
                                                            </div>
                                                            <div  class="row pt-2 colr-catch-font "><span className='saperate-text '>{company?.catchPhrase}</span></div>
                                                            <div className="row pt-4">
                                                                {/* <div className="col-md-3 job-col2">
                                                                    <div className="icons-btm d-flex">
                                                                        <div>
                                                                         <img
                                                                            src={building} alt=""
                                                                            className="icons-b pe-2" /></div><div className="table-text"> <span
                                                                            className="font-16 " data-toggle="tooltip" data-placement="bottom" title= {company?.companyName}>{company?.companyName}</span></div>
                                                                            </div>
                                                                </div> */}
                                                                {(row?.prefecture?.prefectureName)?<div className="col-md-4 job-col2">
                                                                    <div className="icons-btm d-flex" > 
                                                                    <div><img
                                                                            src={locationSVG}
                                                                            alt="" className="icons-l pe-2" /> </div><div className="table-text"><span
                                                                            className="font-16" data-toggle="tooltip" data-placement="bottom" title= {row?.prefecture?.prefectureName}>{row?.prefecture?.prefectureName}</span></div>
                                                                </div>
                                                                </div>:<></>}
                                                                {(company?.industry?.industryName)?<div className="col-md-4 job-col2">
                                                                    <div className="icons-btm d-flex"> 
                                                                    <div><img
                                                                            src={industrySVG} alt=""
                                                                            className="icons-i pe-2" />  </div><div className="table-text"><span
                                                                            className="font-16" data-toggle="tooltip" data-placement="bottom" title= {company?.industry?.industryName}>{company?.industry?.industryName}</span>
                                                                    </div>
                                                                    </div>
                                                                </div>:<></>} 
                                                                {(row?.salaryRange?.minimumSalary)?<div className="col-md-4 job-col2">
                                                                    <div className="icons-btm d-flex"><div> <i
                                                                            className="fa-solid fa-yen-sign yen-font pe-2"></i> </div><div className="table-text">
                                                                        <span className="font-16 yen-color salary-font" data-toggle="tooltip" data-placement="bottom" title= {numFormatter(row?.salaryRange?.minimumSalary) + "-" +numFormatter(row?.salaryRange?.maximumSalary) }>{numFormatter(row?.salaryRange?.minimumSalary)} - {numFormatter(row?.salaryRange?.maximumSalary)}</span></div>
                                                                </div>
                                                                </div>:<></>}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 m-auto text-center col-custom-2">
                                                        <Link to={`/job-details/${row?.jobId}`}> <button className="job-details">{t("job_details")}</button></Link>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                           




                                        </div>
                                    </div>

                                </div>



                            </div>
                            : <div></div>}
                        </div>
                    
                    </div>
            </div>
            </div>
        </div>
    </section>

    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
 
    </div>
    </html>
  );
}

export default Companydetails;
