import React, { useEffect } from 'react';
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function JobApplyError(){
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
  }, []);
  function back() {
    navigate("/");
  }
  return(
    <>
        <section className="top-90 mb-5">
    <div class="top-90">
    <div class="container my-5">
      <div class="card mt-3 Card-box">
        <div class="card-body">
          <div class="row mb-2 mt-5 px-3">
              <div class="col-md-12 text-center ">
                  <i class="  fa-regular fa-circle-xmark delete-pop"></i>
              </div>
  
              <div class="text-center mt-4 mb-4">
                  <h5>{t("error_page")}</h5> 
              </div>
  
              <div class="text-center">
                  <p>{t("job_already_applyed")}</p>
              </div>
  
              <div class="text-center">
                  <button class="main-buttons mb-0" onClick={back}>{t('back')}</button>
                </div>
  
  
          </div>
        </div>
      </div>
    </div>
  </div>
  </section>
    </>
  )
}

export default JobApplyError;
