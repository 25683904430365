import React, {useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useAppContext from "../../AppContext";
import { useTranslation } from "react-i18next";
import i18n from '../../i18n';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import { actions } from "../../redux/companyReducer";
import { useDispatch } from 'react-redux';
import CryptoJS from "crypto-js";

function ResetPassword() {
  const appContext = useAppContext();
  const { t } = useTranslation();
  let { token } = useParams();
  let history = useNavigate()
  const [open, setOpen] = React.useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealconPwd, setIsRevealconPwd] = useState(false);
  const vaildPasswordRegex = RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+={}\[\]>?.<|:;'',`"/\\-])?[A-Za-z\d~!@#$%^&*()_+={}\[\]>?.<|:;'',`"/\\-]{8,20}$/
  );
  const [reset, setReset] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const { newPassword, confirmPassword } = reset;
  const [newpasswordError, setNewpasswordError] = useState(null);
  const [confirmpasswordError, setConfirmpasswordError] = useState(null);
  const [errorResponse,setErrorResponse] = useState(null);
  const [successResponse,setSuccessResponse] = useState(null);
  const dispatch = useDispatch();
  useEffect( () => {
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    dispatch(actions.setuserDetails({}));
  },[])
  const onInputChange = (e) => {
    e.preventDefault();
    setReset({ ...reset, [e.target.name]: e.target.value.trimStart() });
    if (e.target.name === "newPassword") {
      setNewpasswordError(null);
      if (e.target.value === "") {
        setNewpasswordError(t('new_password_error'));
      } else if (!vaildPasswordRegex.test(e.target.value)) {
        setNewpasswordError(t('new_password_balid'));
      }else if( reset.confirmPassword !== "" && e.target.value !== ""
      && reset.confirmPassword !== e.target.value ){
        setConfirmpasswordError(t('match_Error'));
      }else if(reset.confirmPassword !== "" && e.target.value !== ""
      && reset.confirmPassword === e.target.value ){
        setConfirmpasswordError(null);
      }
    } else if (e.target.name === "confirmPassword") {
      setConfirmpasswordError(null);
      if (e.target.value === "") {
        setConfirmpasswordError(t('confirm_new_password_error'));
      } else if( e.target.value !== "" && reset.newPassword !== ""
      && e.target.value !== reset.newPassword ){
        setConfirmpasswordError(t('match_Error'));
      }else if (!vaildPasswordRegex.test(e.target.value)) {
        setConfirmpasswordError(t('new_password_balid'));
        return;
      }
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setErrorResponse(null)
    setSuccessResponse(null)
    if (reset.newPassword === "") {
      setNewpasswordError(t('new_password_error'));
    }
    if (
      reset.newPassword !== "" &&
      !vaildPasswordRegex.test(reset.newPassword)
    ) {
      setNewpasswordError(t('new_password_balid'));
    }
    if (reset.confirmPassword === "") {
      setConfirmpasswordError(t('confirm_new_password_error'));
    }
    if (
      reset.confirmPassword !== "" &&
      !vaildPasswordRegex.test(reset.confirmPassword)
    ) {
      setConfirmpasswordError(t('new_password_balid'));
    } 
    if (
      reset.confirmPassword !== "" && reset.newPassword !== ""
      && reset.confirmPassword !== reset.newPassword 
    ) {
      setConfirmpasswordError(t('match_Error'));
    }
    else {
      if(reset.newPassword !== "" && reset.confirmPassword !== ""){
        let passwordEncryptedData = encryptData(reset.newPassword)
      setOpen(true);
      appContext.forgotPasswordReset(passwordEncryptedData, token).then(
        (response) => {
          console.log(response);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          if(response.status === false){
          
            if(response?.info?.response?.data?.errorCode === '21116'){
            setErrorResponse(t('already_rest_password'))
          }
            else if(response?.info?.response?.data?.errorCode === '22201' || response?.info?.response?.data?.errorCode === '22202'){
            setErrorResponse(t('password_mail_expired'))
            }else {
            setErrorResponse(t('password_reset_failed'))
            }
          
            setTimeout(() => {
              setOpen(false);
            
            }, 1000);
           
          }else {
            window.dataLayer.push({
              event:"Reset Password"
              });
          // setSuccessResponse(t('passord_reset_success'))
          localStorage.setItem("password_reset",t('passord_reset_success'))
            setOpen(false);
            setTimeout(() => {
              history('/login')
            
            }, 1000);
          
          }
        },
        (error) => {
          setTimeout(() => {
            setOpen(false);
            console.log(error.response.data.message);
           
          }, 1000);

          
        }
      );
      }
    }
  };
  function encryptData(newPassword){
    const secretPass = "XkhZG4fW2t2W";
    
    // const data = CryptoJS.AES.encrypt(
    //   JSON.stringify(newPassword),
    //   secretPass
    // ).toString();
    // return data;
    return CryptoJS.AES.encrypt(newPassword, secretPass).toString();
  }
  return (
    <>
      <div className="banner-login middle-toparea">
        <div className="container-fluid padding-body">
          <div className="sub-menus">
          <Link className="topI" to={'/'}>{t('top')}</Link> / <span> {t('reset_password')}</span>{" "}
          </div>

          <div className="login-header">
            <h3>{t('reset_password')}</h3>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="card mt-3 Card-box">
        <div className="validation">{errorResponse}</div>
        <div className="validation-success">{successResponse}</div>
          <div className="card-body">
            <form onSubmit={(e) => onSubmit(e)}>
              <div className="mb-3">
                <label
                  for="exampleInputPassword1"
                  className="form-label input-label"
                >
                 {t('new_password')}
                </label>
                <div className="dob-inp">
                <input
                  type={isRevealPwd ? "text" : "password"}
                  id="exampleInputPassword1"
                  name="newPassword"
                  value={newPassword}
                  onChange={(e) => onInputChange(e)}
                  className={`form-control custom-input custom-input-changeJa ${
                    newpasswordError ? "is-invalid password-errorshow" : ""
                  }`}
                />
                 {newpasswordError ? (
                  <div className="invalid-feedback ">{newpasswordError}</div>
                ) : (
                  ""
                )}
                <span
                        className="calender-icon"
                        onClick={() =>
                          setIsRevealPwd((prevState) => !prevState)
                        }
                      >
                        {" "}
                        <i
                          className={
                            isRevealPwd
                              ? "fa-solid fa-eye"
                              : "fa-solid fa-eye-slash"
                          }
                        ></i>
                      </span>
                    </div>
              </div>

              <div className="mb-3">
                <label
                  for="exampleInputPassword2"
                  className="form-label input-label"
                >
                  {t('confirm_password')}
                </label>
                <div className="dob-inp">
                <input
                 type={isRevealconPwd ? "text" : "password"}
                  id="exampleInputPassword2"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => onInputChange(e)}
                  className={`form-control custom-input custom-input-changeJa ${
                    confirmpasswordError ? "is-invalid password-errorshow" : ""
                  }`}
                />
                 {confirmpasswordError ? (
                  <div className="invalid-feedback ">{confirmpasswordError}</div>
                ) : (
                  ""
                )}
                  <span
                        className="calender-icon"
                        onClick={() =>
                          setIsRevealconPwd((prevState) => !prevState)
                        }
                      >
                        {" "}
                        <i
                          className={
                            isRevealconPwd
                              ? "fa-solid fa-eye"
                              : "fa-solid fa-eye-slash"
                          }
                        ></i>
                      </span>
                    </div>
              </div>

              <div className="text-center">
                <button className="main-buttons"> {t('submit')}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer position="bottom-right"
autoClose={800}
hideProgressBar={false}
newestOnTop={true}
closeOnClick
rtl={false}
limit={1}
pauseOnFocusLoss
pauseOnHover
theme="colored" />
    </>
  );
}

export default ResetPassword;
