import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate,Link } from "react-router-dom";
import Pagination from "../../pagination.js";
import i18n from "../../i18n";
import useAppContext from "../../AppContext.js";
import building from "../../Images/building.svg";
import industrySVG from "../../Images/suitcase.svg";
import locationSVG from "../../Images/Icon material-location-on.svg";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import companylogo from '../../Images/logo-company.png';
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux/companyReducer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Companylist = () => {
  const params = useParams();
  const counterState = useSelector((state) => state.company);
  const navigate = useNavigate();
  const appContext = useAppContext();
  const axios = appContext.getAxios();
  const { t } = useTranslation();
  const limit = 10;
  const [count, setCount] = useState(0);

  const [industry, setIndustry] = useState([]);
  const [allCompany, setAllCompany] = useState([]);
  const [tempIndustry, setTempIndustry] = useState(counterState.industry);
  const [selectedIndustry, setSelectedIndustry] = useState(counterState.industry);
  const [freeText,setFreeText] = useState(counterState.freeText);
  const [open, setOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = useState(parseInt(params.page));
  const [errorResponse,setErrorResponse] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.setuserDetails({}));
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));

    getIndustry();
      getAllCompany(parseInt(params.page));
      setTimeout(() => {
        window.scrollTo({top: 0})
      }, 0);
   

    // eslint-disable-next-line
   // window.addEventListener('popstate', onBackButtonEvent);
  }, [params.page]);
  function pageChange(page) {
    console.log(page);
    setCurrentPage(page);
    navigate("/company-list/" + page);
    getAllCompany(page);
  }
  // const onBackButtonEvent = (e) => {
  //   e.preventDefault();
  //   window.location.reload();
  // }
  function getIndustry() {
    axios
      .get("industry")
      .then((response) => {
        console.log(response);
        setIndustry(response.data.industryList);
        console.log(industry);
      })
      .catch((error) => {
        console.log("There was an error!", error);
      });
  }
  function getAllCompany(page) {
    setOpen(true);
    setErrorResponse(null)
    setCurrentPage(page);
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    console.log(selectedIndustry);
    let searchValues;
    let freeTextSearch;
    let url;
    if(freeText !== undefined && freeText !== null && freeText !== ""){
      console.log(freeText)
      setFreeText(freeText.trim());
      freeTextSearch = freeText.trim();
    }
   
    if(tempIndustry.length !== 0){
      url = "company?pageNo=" +page +"&size=" +limit +"&industry_id=" +tempIndustry + "&name=" + encodeURIComponent(freeText.trim())
      .replace('_', '%5C%5F')
      .replace(/\\/, '%5C')
      .replace('%25',"%5C%25") 
      searchValues = tempIndustry;
    }
    else {
      url = "company?pageNo=" +page +"&size=" +limit + "&name=" + encodeURIComponent(freeText.trim())
      .replace('_', '%5C%5F')
      .replace(/\\/, '%5C')
      .replace('%25',"%5C%25") 
    }
    axios
      .get(url)
      .then((response) => {
        console.log(response);
        setAllCompany(response.data.companyList);
        setCount(response.data.totalCount);
        console.log(allCompany);
        console.log(allCompany);
        dispatch(actions.setSearchCompanyfield(freeText));
        dispatch(actions.setSearchCompanyIndustryfield(tempIndustry));
        const totalPageCount = Math.ceil(response?.data?.totalCount / limit);
        console.log(totalPageCount)
        if(page > totalPageCount){
          setCurrentPage(totalPageCount);
          if(totalPageCount !== 0){
            console.log(currentPage);
          navigate("/company-list/" + totalPageCount);
          getAllCompany(totalPageCount);
          }
        }
        setOpen(false);
        window.dataLayer.push({
          event:"Company",
          search :searchValues,
          searchByfreeText : freeTextSearch
          });
      })
      .catch((error) => {
        setOpen(false);
        console.log("There was an error!", error);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setTimeout(() => {
          setErrorResponse(t('server_error'))
       }, 2000);
      });
  }

  function handleOnChangeIndustry(event) {
    if (event.target.checked) {
      console.log("checked");

      setTempIndustry((tempIndustry) => [
        parseInt(event.target.value),
        ...tempIndustry,
      ]);
      console.log(tempIndustry);
    } else if (!event.target.checked) {
      console.log("unchecked");

      setTempIndustry((current) =>
        current.filter((item) => {
          return item !== parseInt(event.target.value);
        })
      );
      console.log(tempIndustry);
    }
  }
  function IndustrySubmit() {

    setSelectedIndustry(tempIndustry);
    getAllCompany(1);
  }
  const handleOnChangeFreeText = (event) => {
    setFreeText(event.target.value)
    console.log(freeText)
  }
  function searchFreeText(){
      getAllCompany(1);
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      getAllCompany(1);
    }
  };
  function clearIndustry(){
    setTempIndustry([])
    //setSelectedIndustry([]);

  }
  function handleCloseIndustryModal() {
  // setTempIndustry(selectedIndustry)
  }
  function getClearCompany(){
     setTempIndustry(selectedIndustry)
     getAllCompany(1);
  }
  function numCompanyFormatter(num) {
    if(num != undefined){
    if(num > 999999){
      return (num / 10000).toLocaleString("en-US") + '万';
    }else if(num < 1000000 && num > 999){
      return (num/1000).toLocaleString("en-US") + '千';
    }else if(num < 1000 && num > 99){
      return (num/100).toLocaleString("en-US") + '百';
    }else if(num < 100){
      return (num).toLocaleString("en-US");
    }
  }
  }
  function numFormatter(num) {
    if(num != undefined && num !== 0 && num !== 1){
      if(num > 999999){
        return (num / 10000).toLocaleString("en-US") + '万円';
      }else if(num < 1000000 && num > 999){
        return (num/1000).toLocaleString("en-US") + '千円';
      }else if(num < 1000 && num > 99){
        return (num/100).toLocaleString("en-US") + '百円';
      }else if(num < 100){
        return (num).toLocaleString("en-US");
      }
    }
    if(num === 0){
       num = "上限なし"
       return num
    }
    if(num === 1){
      num = "応相談"
      return num
    }
  }
  return (
    <>
     <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js"></script>
        <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
      <section id="Hotjobs" className="top-90">
        <div className="padding-body mt-3">
          <div className="sub-text">
          <Link className="breadcurumb" to={"/"}>
              {t("top")}
            </Link>{" "} / <span>   {t("all_company")} </span>{" "} 
          </div>
          <div className="row d-flex align-items-center top-90">
            <div className="main-heading hot-jobhead">
              <h4>{t("all_company")}</h4>
            </div>
          </div>

          <div className="top-90">
            <div className="row mb-3 justify-content-center">
              <div className="col-md-3 custom-col3">
                <div className="card mb-3 Card-box-left">
                  <div className="card-body p-0 card-left">
                    <div className="left-top text-center">
                      <h6>{t("basic_conditions")}</h6>
                    </div>

                    <div className="p-4">
                      <div className="row">
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item d-flex justify-content-between align-items-start search-list">
                            <div className=" me-auto">
                              <div>
                                <span>
                                  <i className="fa-solid fa-suitcase search-list-icons"></i>
                                </span>
                                <span>{t("search_industry")}</span>
                              </div>
                              <div className="sub-search">
                              {industry.map((row) => {
                                  return (<>
                                    {tempIndustry.includes(row.industryId) ? <> <span >{row.industryTitle}</span>  
                                     </> : null}      
                                    </>)
                                })}
                                </div>
                            </div>
                            <a
                              href=""
                              className="edit-icon"
                              data-bs-toggle="modal"
                              data-bs-target="#industrysearch"
                            >
                              <i className="fa-solid fa-pen-to-square"></i>
                            </a>
                          </li>

                          <li className="list-group-item d-flex justify-content-between align-items-start search-list">
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control custom-input"
                                value={freeText}
                                placeholder={t('free_word')}
                                onChange={e => handleOnChangeFreeText(e)}
                                onKeyDown={handleKeyDown}
                                aria-label="Freeword Search"
                                aria-describedby="basic-addon2"
                              />
                              <span
                                className="input-group-text search-box"
                                id="basic-addon2" onClick={searchFreeText}
                              >
                                <i className="fa-solid fa-magnifying-glass"></i>
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9 custom-col9">
               
                <div className="row">
                {allCompany.length > 0 ?<>
                  <p className="justify-content-between d-flex"> <b>{count} {t('item_count')} ({(limit*(currentPage-1)+1)} {t('item_to')} {(limit*currentPage)-(limit-allCompany.length)} {t('item_display')}) </b>
                  <b className="validation">{errorResponse}</b>
                  </p>
                  {allCompany?.map((row, index) => (
                    <div className="card job-list-card ">
                      <div className="card-body p-4">
                        <div className="row">
                          {/* <div className="col-md-2 colmd-2 d-flex justify-content-center">
                            <div className="company-logo">
                              <img
                                src={(row?.companyLogo === ""||row?.companyLogo === null)? companylogo :  row?.companyLogo}
                                alt=""
                                
                              />
                            </div>
                          </div> */}

                          <div className="col-md-12 colmd-10 mt-2">
                          <span className="site_text_order "><h5> <span className='saperate-text '>{row?.catchPhrase}</span></h5></span>
                            <p className="site_text_order description-text ">{row?.description}</p>
                          </div>
                        </div>

                        <div className="details-section ">
                          <table className="table pt-2">
                            <tbody>
                            {(row?.industry?.industryName) ? <tr>
                                <td scope="row" className="details-head">
                                {t("industry")}
                                </td>
                                <td className="details-p site_text_order">
                                  {row?.industry?.industryName}
                                </td>
                              </tr> : <></>}
                              {(row?.capital) ?<tr>
                                <td scope="row" className="details-head">
                                {t("company_capital")}
                                </td>
                                <td className="details-p site_text_order">
                                {new Intl.NumberFormat('ja').format(row?.capital)}{t("yen")}
                                  {/* {numCompanyFormatter(parseInt(row?.capital))}{t("yen")} */}
                                  </td>
                              </tr> : <></>}
                              {/* {(row?.prefecture?.prefectureName) ?  <tr>
                                <td scope="row" className="details-head">
                                {t("location")}
                                </td>
                                <td className="details-p site_text_order">
                                  {row?.prefecture?.prefectureName}
                                </td>
                              </tr> :<></>} */}
                              {row?.prefectures?.length > 0 && (
                                <tr>
                                  <td scope="row" className="details-head">
                                   {t("location")}
                                  </td>
                                  <td className="details-p site_text_order">
                                  {row?.prefectures.map((prefecture, index) => (
                                    <span key={index}>
                                      {prefecture?.prefectureName}
                                      {index < row?.prefectures.length - 1 && ', '}
                                    </span>
                                  ))}
                                  </td>
                                </tr>
                              )}
                             
                            </tbody>
                          </table>
                        </div>

                        <div className="col-md-12 text-center mt-5 mb-4">
                          <Link to={`/company-details/${row?.companyId}`}>
                            {" "}
                            <button className="job-details singleline_header">  {t("more_about_company")}</button>
                          </Link>
                        </div>
                      </div>

                      <div className="row mb-3 justify-content-center p-4">
                        <div className="col-md-12">
                          {row?.jobs?.map((jobRow, index) => (
                            <div className="card job-card">
                              <div className="card-body p-4">
                                <div className="row">
                                  <div className="col-md-10 col-custom10">
                                  <span className="site_text_order">  <h5>{jobRow?.jobTitle}</h5></span>
                                    <p className="site_text_order description-text " description-text >{jobRow?.jobDescription}</p>

                                    <div className="row ps-2">
                                      {jobRow?.tags?.map((tagRow, index) => (
                                        <button className="tag-bg mb-3">
                                          {tagRow?.tagName}
                                        </button>
                                      ))}
                                    </div>
                                    <div  class="row pt-2 colr-catch-font "><span className='saperate-text '>{row?.catchPhrase}</span></div>
                                    <div className="row pt-4">
                                      {/* <div className="col-md-3 job-col2">
                                        <div className="icons-btm d-flex" >
                                          {" "}
                                          <div>
                                          <img
                                            src={building}
                                            alt=""
                                            className="icons-b pe-2"
                                          />{" "}
                                          </div>
                                <div className="table-text">
                                          <span className="font-16" data-toggle="tooltip" data-placement="bottom" title= {row?.companyName}>
                                            {row?.companyName}
                                          </span>
                                          </div>
                                        </div>
                                      </div> */}
                                      {(jobRow?.prefecture?.prefectureName)? <div className="col-md-4 job-col2">
                                        <div className="icons-btm d-flex" >
                                          {" "}
                                          <div>
                                          <img
                                            src={locationSVG}
                                            alt=""
                                            className="icons-l pe-2"
                                          />{" "}
                                          </div>
                                <div className="table-text">
                                          <span className="font-16" data-toggle="tooltip" data-placement="bottom" title={jobRow?.prefecture?.prefectureName}>
                                            {jobRow?.prefecture?.prefectureName}
                                          </span>
                                          </div>
                                        </div>
                                      </div> : <></>}  
                                      {(row?.industry?.industryName)? <div className="col-md-4 job-col2">
                                        <div className="icons-btm d-flex" >
                                          {" "}
                                          <div>
                                          <img
                                            src={industrySVG}
                                            alt=""
                                            className="icons-i pe-2"
                                          />{" "}
                                          </div>
                                <div className="table-text">
                                          <span className="font-16" data-toggle="tooltip" data-placement="bottom" title={row?.industry?.industryName}>
                                            {row?.industry?.industryName}
                                          </span>
                                          </div>
                                        </div>
                                      </div> :<></>}
                                      {(jobRow?.salaryRange?.minimumSalary)?<div className="col-md-4 job-col2">
                                        <div className="icons-btm d-flex">
                                          {" "}
                                          <div>
                                          <i className="fa-solid fa-yen-sign yen-font pe-2"></i>
                                          </div>
                                <div className="table-text">
                                          <span className="font-16 yen-color salary-font" data-toggle="tooltip" 
                                          data-placement="bottom" title={numFormatter(jobRow?.salaryRange?.minimumSalary) +" - "+  numFormatter(jobRow?.salaryRange?.maximumSalary)}>
                                            {numFormatter(jobRow?.salaryRange?.minimumSalary)}{" "}
                                            -{" "}
                                            {numFormatter(jobRow?.salaryRange?.maximumSalary)}
                                          </span>
                                          </div>
                                        </div>
                                      </div> : <></>}
                                    </div>
                                  </div>
                                  <div className="col-md-2 m-auto text-center col-custom-2">
                                    <Link to={`/job-details/${jobRow?.jobId}`}>
                                      {" "}
                                      <button className="job-details">
                                      {t("job_details")}
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </>:<div><h6 style={{ "textAlign": "center","fontWeight":"bold","lineHeight":"300px" }}>{t('no_results')}</h6></div>}
                  <Pagination
                    className="pagination-bar page-item page-list"
                    currentPage={currentPage}
                    totalCount={count}
                    pageSize={limit}
                    onPageChange={(page) => pageChange(page)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className="modal fade"
        id="industrysearch"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header border-none">
              <div className="col-md-12 text-center mt-60">
                <div className="main-heading hot-jobhead">
                  <h4> {t("search_by_industry")}</h4>
                </div>
              </div>
              <button
                type="button"
                className="Close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"  onClick={handleCloseIndustryModal}
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="search-area">
                <div>
                  <div>
                    <div className="row">
                      <div>
                        {industry?.map((row, index) => (
                          <div className="form-check form-check-inline mb-2">
                            <input
                              className="form-check-input form-chck"
                              type="checkbox"
                              onChange={(e) => handleOnChangeIndustry(e)}
                              id={"industry" + row?.industryId}
                              value={row?.industryId}
                              checked={tempIndustry.includes(
                                row?.industryId
                              )}
                            />
                            <label
                              className="form-check-label ps-2"
                              for="inlineCheckbox1"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title={row?.industryTitle}
                            >
                              {row.industryTitle}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer border-none ">
                <div className="col-md-12 text-center text-col12">
                  <button className="cancel-btn"  onClick={clearIndustry}>
                  {t("clear")}
                  </button>
                  <button
                    className="main-buttons sub-btn"
                    data-bs-dismiss="modal"
                    onClick={IndustrySubmit}
                  >
                     {t("submit")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer
        position="bottom-right"
        autoClose={800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />

    </>
    
  );
};

export default Companylist;
