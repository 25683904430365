import React, { useEffect, useState } from "react";
import useAppContext from "../../AppContext";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";
import i18n from '../../i18n';
import { Link } from "react-router-dom";
import { actions } from "../../redux/companyReducer";
import { useDispatch } from 'react-redux';
function ForgotPassword() {
  const appContext = useAppContext();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const [errorResponse,setErrorResponse] = useState(null);
  const [successResponse,setSuccessResponse] = useState(null);
  const dispatch = useDispatch();

  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"+-@$!%*?&#_+=,._0-9]+\.)+[^<>()[\]\.,;:\s@\"]{2,3})$/i
  );
  useEffect( () => {
    dispatch(actions.setuserDetails({}));
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));

  },[])

  const onInputChange = (e) => {
    e.preventDefault();
    setEmailError("");
    console.log(e.target.value)
    setEmail(e.target.value.trimStart());
 
    if (e.target.value === "") {
      setEmailError(t('email_error'));
    } else if (!validEmailRegex.test(e.target.value)) {
      setEmailError(t('valid_email'));
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setEmailError("");
    setErrorResponse(null)
    setSuccessResponse(null)
    if (email === "") {
      setEmailError(t('email_error'));
    } else if (!validEmailRegex.test(email)) {
      setEmailError(t('valid_email'));
    } else {
      setOpen(true);
      appContext.forgotPassword(email).then(
        (response) => {
          console.log(response);
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          if(response.status === false){
          
            if(response?.info?.response?.data?.errorCode === '21115'){
              setErrorResponse(t("user_blocked"))
              
            } else {
            setErrorResponse(t("passord_email_wrong"))
            }
          
            setTimeout(() => {
              setOpen(false);
            
            }, 1000);
           
          }else {
            setOpen(false);
            setEmail('')
            window.dataLayer.push({
              event:"Forgot Password"
              });
              setSuccessResponse(t('email_forgot_sent_succes'))
          }
        
        },
        (error) => {
          setTimeout(() => {
            setOpen(false);
          console.log(error.response.data.message);
        
          }, 1000);
         
         
        }
      );
    }
  };
  return (
    <>
      <div className="banner-login middle-toparea">
        <div className="container-fluid padding-body">
          -
          <div className="sub-menus">
          <Link className="topI" to={'/'}>{t('top')}</Link> / <span> {t('forgot_password')}</span>{" "}
          </div>
          <div className="login-header">
            <h3>{t('forgot_password')}</h3>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="card mt-3 Card-box">
        <div className="validation">{errorResponse}</div>
        <div className="validation-success">{successResponse}</div>
          <div className="card-body">
            <form onSubmit={(e) => onSubmit(e)}>
              <div className="text-start mt-5 mb-5">
                <p>{t('enter_your_email')}</p>
              </div>
              <div className="mb-3">
                <label for="name" className="form-label input-label">
                  {t('email_id')}
                </label>
                <input
                  type="text"
                  id="name"
                  aria-describedby="emailHelp"
                  maxLength={50}
                  value={email}
                  onChange={(e) => onInputChange(e)}
                  className={`form-control custom-input custom-input-changeJa ${
                    emailError ? "is-invalid" : ""
                  }`}
                />
                {emailError ? (
                  <div className="invalid-feedback ">{emailError}</div>
                ) : (
                  ""
                )}
              </div>

              <div className="text-center">
                <button className="main-buttons">{t('send')}</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer position="bottom-right"
autoClose={800}
hideProgressBar={false}
newestOnTop={true}
closeOnClick
rtl={false}
limit={1}
pauseOnFocusLoss
pauseOnHover
theme="colored" />
    </>
  );
}

export default ForgotPassword;
