import React, { useEffect, useState } from "react";
import useAppContext from "../../AppContext";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {Link, useNavigate, useParams} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";
import i18n from '../../i18n';
import { actions } from "../../redux/companyReducer";
import { useDispatch } from 'react-redux';
import CryptoJS from "crypto-js";
function Login() {
  const { t } = useTranslation();
  const appContext = useAppContext();
  const axios = appContext.getAxios();
  let history = useNavigate()
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = React.useState(null);
  const [passwordError, setPasswordError] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [response, setResponse] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [errorResponse,setErrorResponse] = useState(null);
  const [errorJobResponse,setErrorJobResponse] = useState(null);
  const [successResponse,setSuccessResponse] = useState(null);
  const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"+-@$!%*?&#_+=,._0-9]+\.)+[^<>()[\]\.,;:\s@\"]{2,3})$/i);
  const vaildPasswordRegex = RegExp( /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+={}\[\]>?.<|:;'',`"/\\-])?[A-Za-z\d~!@#$%^&*()_+={}\[\]>?.<|:;'',`"/\\-]{8,20}$/);
  let { token } = useParams();
  const [jobId,setJobId] =  useState();
  const [passwordReset,setPasswordReset] =  useState(null);
  const [invalidToken,setInvalidToken] =  useState(null);
  const [career,setCareer] =  useState(null);
  const [errorTokenResponse, setErrorTokenResponse] = useState(null);
  const dispatch = useDispatch();
  useEffect( () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    dispatch(actions.setuserDetails({}));
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    setJobId(localStorage.getItem("JOB_ID"));
    setPasswordReset(localStorage.getItem("password_reset"))
    setInvalidToken(localStorage.getItem("invalid_token"))
    setCareer(localStorage.getItem("career_Id"))
    setTimeout(() => {
      localStorage.removeItem("JOB_ID");
      localStorage.removeItem("password_reset");
      localStorage.removeItem("invalid_token")
      localStorage.removeItem("career_Id")
   }, 1000);
    if(token !== undefined){
      tokenVerify();
    }

  },[])
  function applyJob(){
    setErrorResponse(null)
    setErrorJobResponse(null)
    setSuccessResponse(null)
    setPasswordReset(null)
    setInvalidToken(null)
    setErrorTokenResponse(null)
    const job = { "jobId" : parseInt(jobId)};
    axios.post('job/apply',job).then(
        (response) => {
          console.log(response);
          if(response.status === 200){
          setResponse(true);
          window.dataLayer.push({
            event:"Job Apply",
            user:localStorage.getItem("userId"),
            });
          history("/jobapply-success");
          }
        },
        (error) => {

           if(error.response?.data.errorCode === 26201 || error.response?.data.errorCode === "26201"){
            setErrorMsg(true)
            setErrorJobResponse(t('job_not_found'))
            document.getElementById("successPopUpButton").click();
          }else if(error.response?.data.errorCode === "22202" || error.response?.data.errorCode === "1009"){
         
            setResponse(false);
            setErrorJobResponse(t('invalid_token'))
            document.getElementById("successPopUpButton").click();
          }else if(error.response?.data.errorCode === "21115" ){
            setResponse(false);
            setErrorJobResponse(t('job_user_unpublished'))
            document.getElementById("successPopUpButton").click();
          }else if(error.response?.data.errorCode === "21114" ){
            setResponse(false);
            setErrorJobResponse(t('job_user_deleted'))
            document.getElementById("successPopUpButton").click();
          }else if(error.response?.data.errorCode === "26301" ){
            history("/job-apply-error");
          }
          setTimeout(() => {
            setOpen(false);
          }, 1000);
      
        }
      );
}
function handleOnClickPopUp(){
  localStorage.removeItem("JOB_ID");
  document.getElementById("successPopUpCloseButton").click();
  if(errorMsg){
    history('/job-list/1');
  }else{
    appContext.logout();
    history('/job-details/'+jobId);
  }
  
  window.location.reload();
}
  const onInputChange = (e) => {
    e.preventDefault();
    console.log(e.target.value)
   
    if(e.target.name === 'email'){
      setEmail(e.target.value.trimStart());
      setEmailError(null)
      if(e.target.value === ''){
        setEmailError(t('email_error'))
        return;
      }
      else if((!validEmailRegex.test(e.target.value))){
        setEmailError(t('valid_email'));
        return;
      }
     
    }else if(e.target.name === 'password') {
      setPassword(e.target.value)
      setPasswordError(null)
      if(e.target.value === ''){
        setPasswordError(t('password_error'))
        return;
      }
      // else if(!(vaildPasswordRegex.test(e.target.value))){
      //   setPasswordError(t('valid_password'));
      //   return; 
      // }
    }
  
    
  };
  
  const onSubmit = (e) => {
    e.preventDefault();
    setEmailError(null)
    setPasswordError(null)
    setErrorJobResponse(null)
    setErrorResponse(null)
    setSuccessResponse(null)
    setPasswordReset(null)
    setInvalidToken(null)
    setErrorTokenResponse(null)
    if(email === ''){
      setEmailError(t('email_error'));
      
    }
    if(password === ''){
      setPasswordError(t('password_error'));
    }
     if(email !== '' && (!validEmailRegex.test(email))){
      setEmailError(t('valid_email'));
    }
     if(email !== '' && validEmailRegex.test(email) && password !== ''){
      let passwordEncryptedData = encryptData(password)
      console.log(passwordEncryptedData,"passwrd111")
      setOpen(true);
      appContext.signIn(email,passwordEncryptedData).then(
        (response) => {
          if(response.status === false){
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            if(response?.info?.response?.data?.errorCode === '21202' || response?.info?.response?.data?.errorCode === '21203'){
            setErrorResponse(t('user_notfound'))
          }
          else if(response?.info?.response?.data?.errorCode === '21204'){
            setErrorResponse(t('user_notfound'))
          }
          else if(response?.info?.response?.data?.errorCode === '21115'){
          setErrorResponse(t('user_blocked'))
          }else if(response?.info?.response?.data?.errorCode === '21114'){
          setErrorResponse(t('user_deleted'))
          }
          else if(response?.info?.response?.data?.errorCode === '21112' || response?.info?.response?.data?.errorCode === '21113'){
          setErrorResponse(t('invalid_Email_or_password'))
          }
          else if(response?.info?.response?.data?.errorCode === '21119'){
         // setErrorResponse(t('email_not_verified'))
           setOpen(false);
           document.getElementById("resendpopupButton").click();
          }
          else {
          setErrorResponse(t('login_failed'))
          }
            setOpen(false);          
          }else {
            console.log(career)
            if(jobId !== null && jobId != undefined && jobId != "null"){
              applyJob();
            } else if(career === "true"){
              history('/career-confirmation');
            }else{
              window.dataLayer.push({
                event:"Login",
                user:localStorage.getItem("userId"),
                });
                window.location.replace('/');
                setOpen(false);
            }          
          }       
        },
        (error) => {
          setTimeout(() => {
            setOpen(false);
       
           
          },1000);
        
        }
      );
    }
   
  };
  const tokenVerify =() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setSuccessResponse(null)
    setErrorJobResponse(null)
    setErrorResponse(null)
    let tokenV = {'token':token}
    setErrorResponse(null)
    setPasswordReset(null)
    setInvalidToken(null)
    setErrorTokenResponse(null)
    axios.put('users/verify',tokenV).then(
      (response) => {
        setOpen(false);
        console.log(response)
        
         setSuccessResponse(t("user_verified"))
      },
      (error) => {
        setOpen(false);
       
          if(error?.response?.data?.errorCode === '21301' || error?.response?.data?.errorCode === '21302' || error?.response?.data?.errorCode === '21303' ){
          setErrorResponse(t('user_verification_failed'))
        }
           if(error?.response?.data?.errorCode === '21304'){
          setErrorResponse(t('user_resnd_email'))
        }
        if(error?.response?.data?.errorCode === '21401'){
          setErrorResponse(t('user_active'))
        }
     
      }
    );
  }
  function resend() {
    setOpen(true);
    setErrorResponse(null)
    setErrorJobResponse(null)
    setSuccessResponse(null)
    setPasswordReset(null)
    setInvalidToken(null)
    setErrorTokenResponse(null)
    let resendData = { email: email };
    axios.put("users/resend/mail", resendData).then(
      (response) => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log(response);
        setOpen(false);
        // setSuccessResponse(t("mail_resend"))
        localStorage.setItem("mail_resend", t("mail_resend"));
        history("/registration-success");
      },
      (error) => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setOpen(false);
        if (error?.response?.data?.errorCode === "21401") {
          setErrorTokenResponse(t("user_active"));
        } else if(error.response?.data.errorCode === "21115" ){
          setErrorTokenResponse(t("job_user_unpublished"));
        } else if(error.response?.data.errorCode === "21114" ){
          setErrorTokenResponse(t("job_user_deleted"));
        } else {
          setErrorTokenResponse(t("error_resend"));
        }
      }
    );
  }
  function encryptData(passwrd){
    const secretPass = "XkhZG4fW2t2W";
    
    // const data = CryptoJS.AES.encrypt(
    //   JSON.stringify(passwrd),
    //   secretPass
    // ).toString();
    //   return data;
    return CryptoJS.AES.encrypt(passwrd, secretPass).toString();
  }
  return (
    <>
      <div className="banner-login middle-toparea">
        <div className="container-fluid padding-body">
          <div className="sub-menus">
          <Link className="topI" to={'/'}>{t('top')}</Link> / <span>{t('login')}</span>{" "}
          </div>

          <div className="login-header">
            <h3>{t('login')}</h3>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="card mt-3 Card-box">
        <div className="validation">{errorResponse}</div>
        <div className="validation">{invalidToken}</div>
        <div className="validation">{errorTokenResponse}</div>
        <div className="validation-success">{successResponse}</div>
        <div className="validation-success">{passwordReset}</div>
        
          <div className="card-body">
            <form onSubmit={(e) => onSubmit(e)}>
              <div className="mb-3">
                <label
                  for="exampleInputEmail1"
                  className="form-label input-label"
                >
                  {t('email_id')}
                </label>
                <input
                  type="text"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={email}
                  maxLength={50}
                  name="email"
                  onChange={(e) => onInputChange(e)}
                  className={`form-control custom-input custom-input-changeJa ${
                    emailError ? "is-invalid" : ""
                  }`}
                 />
                 {emailError ? <div  className="invalid-feedback " >{emailError}</div> : ''}
              </div>
              <div className="mb-3">

                
                <label
                  for="exampleInputPassword1"
                  className="form-label input-label"
                >
                  {t('password')}
                </label>
                <div className="dob-inp">
                <input
                  type={isRevealPwd ? "text" : "password"}
                  className={`form-control custom-input custom-input-changeJa ${
                    passwordError ? "is-invalid password-errorshow" : ""
                  }`}
                  id="exampleInputPassword1"
                  value={password}
                  name="password"
                  onChange={(e) => onInputChange(e)}
                />
                   {passwordError ? <div  className="invalid-feedback " >{passwordError}</div> : ''}
                 <span className="calender-icon" onClick={() => setIsRevealPwd(prevState => !prevState)}>  <i className={isRevealPwd ? "fa-solid fa-eye" : "fa-solid fa-eye-slash"}></i></span>
                </div>
               
              
              
              </div>
              <div className="mb-3 text-end">
                <div>
                    <a href="/forgot-password" className="forgot-p">
                    {t('forgot_password')} 
                    </a>
                </div>
              </div>
              <div className="text-center">
                <button className="main-buttons" type="submit">
                  {" "}
                  {t('login')}  
                </button>
              </div>

              <div className="text-center mt-5 mb-5">
                <p>
                {t('Need_account')} {" "}
                    <a href="/registration" className="forgot-p">
                    {t('register_here')} 
                    </a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer position="bottom-right"
autoClose={800}
hideProgressBar={false}
newestOnTop={true}
closeOnClick
rtl={false}
limit={1}
pauseOnFocusLoss
pauseOnHover
theme="colored" />
 <a href="" className="edit-icon" data-bs-toggle="modal" id="successPopUpButton" data-bs-target="#successPopup" hidden></a>
 
 <div className="modal fade" id="successPopup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-confirm ">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <div className="icon-box">
                                <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> 
                            </div>
                           { errorMsg === true ?
                             <Link to ="/job-list/1"><button type="button" id="successPopUpCloseButton" className="Close-btn" data-bs-dismiss="modal" aria-label="Close" style={{ "color": "white" }}>
                              <i className="fa-solid fa-xmark"></i></button></Link>  :
                              <button type="button" id="successPopUpCloseButton" className="Close-btn" data-bs-dismiss="modal" aria-label="Close" style={{ "color": "white" }}>
                              <i className="fa-solid fa-xmark"></i></button>}
                        </div>
                        <div className="modal-body text-center">
                           <p>{errorJobResponse}</p> 
                           
                            <button className="btn btn-success" data-dismiss="modal" onClick={handleOnClickPopUp}><span>{t('ok')}</span></button>
                        </div>
                    </div>
                </div>
            </div>


  {/* Resend */}
            <a
        href=""
        className="edit-icon"
        data-bs-toggle="modal"
        id="resendpopupButton"
        data-bs-target="#resendpopup"
        hidden
      ></a>
      <div
        className="modal fade"
        id="resendpopup"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body px-5 py-3">
              <div className="row mb-2 mt-5 px-3">
                <div className="col-md-12 text-center">
                  <i className="fa-solid fa-circle-info info-pop"></i>
                </div>
                <div className="text-center mt-4 mb-4">
                  <h5> {t("resend_heading")}</h5>
                </div>

                <div className="text-center">
                  <p>{t("resend_description")}</p>
                </div>
              </div>
              <div className="col-md-12 text-center mb-3">
                <button className="cancel-btn" data-bs-dismiss="modal">
                  {" "}
                  {t("cancel")}
                </button>
                <button
                  className="main-buttons sub-btn"
                  data-bs-dismiss="modal"
                  onClick={resend}
                >
                  {" "}
                  {t("resend")}
                </button>
              </div>
            </div>
            {/* <div className="modal-footer border-0">
                       
                    </div> */}
          </div>
        </div>
      </div>

    </>
  );
}
export default Login;
