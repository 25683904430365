import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import i18n from "../../i18n";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux/companyReducer";
import useAppContext from "../../AppContext";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import CryptoJS from "crypto-js";

function RegisterConfirmation() {
  const { t } = useTranslation();
  const counterState = useSelector((state) => state.company);
  const [user, setUser] = useState(counterState.user);
  let history = useNavigate();
  const dispatch = useDispatch();
  const appContext = useAppContext();
  const axios = appContext.getAxios();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = useState(null);
  const [career, setCareer] = useState(null);
  const [errorCode, setErrorCode] = useState();
  const [userBody, setUserBody] = useState({
    fullName: "",
    password: "",
    email: "",
    dateOfBirth: "",
    skill: "",
    experience: 0,
    resume: "",
    middleName: "",
    lastName: "",
    telNumber: "",
    prefecture: "",
    city: "",
    apartmentName: "",
    desiredOccupation: ""
  });
  const [jobId, setJobId] = useState();
  const [errorJobResponse, setErrorJobResponse] = useState(null);
  const [errorTokenResponse, setErrorTokenResponse] = useState(null);
  const [successResponse, setSuccessResponse] = useState(null);
  const [errorResponse, setErrorResponse] = useState(null);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    console.log(user);
    setJobId(localStorage.getItem("JOB_ID"));
    setCareer(localStorage.getItem("career_Id"));
    setTimeout(() => {
      localStorage.removeItem("JOB_ID");
      localStorage.removeItem("career_Id");
    }, 1000);
    console.log(jobId)
  }, []);
  function confirm(e) {
    e.preventDefault();
    setError(null)
    setErrorJobResponse(null);
    setErrorResponse(null);
    setErrorTokenResponse(null);
    setSuccessResponse(null);
    var checkBox = document.getElementById("inlineCheckbox1");
    var checkBox1 = document.getElementById("inlineCheckbox2");
    var checkBox2 = document.getElementById("inlineCheckbox3");
    if (checkBox.checked !== true) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setError(t("check_error"))
    } else if (checkBox1.checked !== true) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setError(t("check_error"))
    } else if (checkBox2.checked !== true) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      setError(t("check_error"))
    } else if (checkBox.checked === true && checkBox1.checked === true && checkBox2.checked === true) {


      // userBody.experience = parseFloat(user.experience);
      // userBody.location = JSON.stringify(user.location);
      userBody.dateOfBirth = user.dateOfBirth;
      userBody.email = user.email;
      userBody.fullName = user.fullName;
      // userBody.interestedJobCategory = user.interestedJobCategory;
      // userBody.location = user.location;
      encryptData(user.password, userBody)
      // userBody.password = user.password;
      //userBody.skill = user.skill;



      userBody.resume = user.file;
      // userBody.resume = user.resume;
      userBody.middleName = user.middleName;
      userBody.lastName = user.lastName;
      userBody.telNumber = user.telphone;
      userBody.prefecture = user.prefature;
      userBody.city = user.city;
      userBody.apartmentName = user.apartment;
      userBody.desiredOccupation = user.occupation;
      // if(userBody.middleName === "" || userBody.middleName === undefined){
      //   userBody.middleName = null;
      // }
      // if(userBody.apartmentName === "" || userBody.apartmentName === undefined){
      //   userBody.apartmentName = null;
      // }
      setOpen(true);
      console.log(career)
      let source;
      if (jobId != null && jobId != undefined && jobId != "null") {
        source = 2
      } else if (career === "true") {
        source = 1
      }
      else {
        source = 0
      }

      axios.post("users?source=" + source, userBody).then(
        (response) => {
          localStorage.setItem("usertemId", response.data.user_id);
          setOpen(false);

          window.dataLayer.push({
            event: "User Registration",
          });

          setTimeout(() => {
            console.log("jobId", jobId)

            if (jobId != null && jobId != undefined && jobId != "null") {
              applyJob();
            } else if (career === "true") {
              let data = { "userId": response.data.user_id }
              axios.post("careerCounselling/user/apply", data).then(
                (resp) => {

                  setOpen(false);

                  window.dataLayer.push({
                    event: "Career Counselling Job Apply",
                    user: localStorage.getItem("usertemId"),
                  });
                  console.log(resp);
                  history('/counseling-success');
                },
                (error1) => {
                  if (error1.response?.data.errorCode === "23701") {

                    history("/job-apply-error");
                  } else {

                    history("/job-apply-error");
                  }

                  setOpen(false);
                }
              );




            } else {
              history("/registration-success");
            }
          }, 1000);
          console.log(response);
        },
        (error) => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          if (error?.response?.data?.errorCode === '21111') {
            setErrorResponse(t("email_already_exist"))
          } else if (error?.response?.data?.errorCode === '21115') {
            setErrorResponse(t("suspened_user"))
          } else if (error?.response?.data?.errorCode === '21114') {
            setErrorResponse(t("user_deleted"))
          }
          else if (error?.response?.data?.errorCode === '21119') {
            setOpen(false);
            document.getElementById("resendpopupButton").click();
          }
          else {
            setErrorResponse(t("user_failed"))
          }

          setTimeout(() => {
            setOpen(false);
          }, 1000);
        }
      );
    }
  }
  function cancel(e) {
    e.preventDefault();
    localStorage.setItem("JOB_ID", jobId);
    localStorage.setItem("career_Id", career);
    history("/registration");
  }
  function applyJob() {
    setOpen(true);
    setErrorJobResponse(null)
    setErrorResponse(null)
    setErrorTokenResponse(null);
    setSuccessResponse(null)
    setError(null)
    setErrorCode(null)
    const job = { "jobId": parseInt(jobId) };
    axios.post('/job/user/apply/' + localStorage.getItem("usertemId"), job).then(
      (response) => {
        console.log(response);
        if (response.status === 200) {
          window.dataLayer.push({
            event: "Job Apply",
            user: localStorage.getItem("usertemId"),
          });
          history("/jobapply-success");
        }
      },
      (error) => {
        setErrorCode(error?.response?.data?.errorCode);
        if (error.response?.data.errorCode === 26201 || error.response?.data.errorCode === "26201") {

          setErrorJobResponse(t('job_not_found'))
          document.getElementById("successPopUpButton").click();
        } else if (error.response?.data.errorCode === "22202" || error.response?.data.errorCode === "1009") {

          setErrorJobResponse(t('invalid_token'))
          document.getElementById("successPopUpButton").click();
        } else if (error.response?.data.errorCode === "21115") {

          setErrorJobResponse(t('job_user_unpublished'))
          document.getElementById("successPopUpButton").click();
        } else if (error.response?.data.errorCode === "21114") {

          setErrorJobResponse(t('job_user_deleted'))
          document.getElementById("successPopUpButton").click();
        } else if (error.response?.data.errorCode === "26301") {
          history("/job-apply-error");
        }
        setTimeout(() => {
          setOpen(false);
        }, 1000);

      }
    );
  }
  function handleOnClickPopUp() {
    localStorage.removeItem("JOB_ID");
    localStorage.removeItem("career_Id");
    document.getElementById("successPopUpCloseButton").click();
    if (errorCode === "22202" || errorCode === "1009" || errorCode === "21114" || errorCode === "21115") {

      appContext.logout();
      history("/login");
    } else {
      history("/job-list/1");
    }


    window.location.reload();
  }
  function resend() {
    setOpen(true);
    setErrorJobResponse(null);
    setErrorResponse(null);
    setErrorTokenResponse(null);
    setSuccessResponse(null);
    setError(null)
    let resendData = { email: user.email };
    axios.put("users/resend/mail", resendData).then(
      (response) => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log(response);
        setOpen(false);
        // setSuccessResponse(t("mail_resend"))
        localStorage.setItem("mail_resend", t("mail_resend"));
        history("/registration-success");
      },
      (error) => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setOpen(false);
        if (error?.response?.data?.errorCode === "21401") {
          setErrorTokenResponse(t("user_active"));
        } else if (error.response?.data.errorCode === "21115") {
          setErrorTokenResponse(t("job_user_unpublished"));
        } else if (error.response?.data.errorCode === "21114") {
          setErrorTokenResponse(t("job_user_deleted"));
        } else {
          setErrorTokenResponse(t("error_resend"));
        }
      }
    );
  }
  function encryptData(passwrd, userBody) {
    const secretPass = "XkhZG4fW2t2W";

    // const data = CryptoJS.AES.encrypt(
    //   JSON.stringify(passwrd),
    //   secretPass
    // ).toString();
    userBody.password = CryptoJS.AES.encrypt(passwrd, secretPass).toString();
  }
  return (
    <>
      <div className="banner-login middle-toparea">
        <div className="container-fluid padding-body">
          <div className="sub-menus">
            <Link className="topI" to={"/"}>
              {t("top")}
            </Link>{" "}
            / <span>{t("user_confirmation")}</span>{" "}
          </div>

          <div className="login-header">
            <h3>{t("user_confirmation")}</h3>
          </div>
        </div>
      </div>
      .
      <div className="container my-5">
        <div className="card mt-3 Card-box pt-3">
          <div className="validation pt-3">{error}</div>
          <div className="validation">{errorResponse}</div>
          <div className="validation">{errorTokenResponse}</div>
          <div className="validation-success">{successResponse}</div>
          <div className="card-body">
            <form>
              <div className="row mb-2 mt-5">
                {/*   <div className="row ">
                  <div className="col-md-6">
                    <div className="mb-5">
                      <label for="name" className="form-label input-label">
                        {t("full_name")}{" "}
                      </label>
                      <div>
                        <label for="name">{user.fullName}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-5">
                      <label for="name" className="form-label input-label">
                        {t("dob")}
                      </label>
                      <div>
                        <label for="name">{user.dateOfBirth}</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-5">
                      <label for="name" className="form-label input-label">
                        {t("skills")}
                      </label>
                      <div className="dob-inp">
                        <label for="name">{user.skill}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-5">
                      <label for="name" className="form-label input-label">
                        {t("experience")}
                      </label>
                      <div>
                        <label for="name">{user.experience}</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-5">
                      <label for="name" className="form-label input-label">
                        {t("interesed_job_categories")}
                      </label>
                      <div className="dob-inp ">
                        <label for="name">{user.category}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-5">
                      <label for="name" className="form-label input-label">
                        {t("location")}
                      </label>
                      <div>
                        <label for="name">{user.locationName}</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-5">
                      <label for="name" className="form-label input-label">
                        {t("email_id")}
                      </label>
                      <div>
                        <label for="name">{user.email}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-5">
                      <label
                        for="exampleInputPassword1"
                        className="form-label input-label"
                      >
                        {t("password")}
                      </label>
                      <div>
                        <label for="name">{user.password}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-md-6">
                    <div className="mb-5">
                      <div>
                        <label for="name">
                          <a href="/" target="_blank">
                            view Resume
                          </a>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6"></div>
                </div>
                <hr />
                <div className="background-indicator">
                  <div className="row px-4 pt-3">
                    <div className="form-check form-check-inline mb-2">
                      <input
                        className="form-check-input form-chck"
                        type="checkbox"
                        id="inlineCheckbox1"
                        value="option1"
                      />
                      <label
                        className="check-label-form ps-2"
                        for="inlineCheckbox1"
                      >
                        <a
                          className="link-class"
                          href="https://sites.google.com/dxhub.co.jp/personal-information/%E3%83%9B%E3%83%BC%E3%83%A0"
                          target="_blank"
                        >
                          {t("handling_personal_info")}
                        </a>
                      </label>
                    </div>
                    <div className="form-check form-check-inline mb-2">
                      <input
                        className="form-check-input form-chck"
                        type="checkbox"
                        id="inlineCheckbox2"
                        value="option2"
                      />
                      <label
                        className="check-label-form ps-2"
                        for="inlineCheckbox2"
                      >
                        <a
                          className="link-class"
                          href="https://dxhub.co.jp/privacy-policy"
                          target="_blank"
                        >
                          {t("handling_personal_info")}
                        </a>
                        {t("privacy_policy_info")}
                      </label>
                    </div>
                    <div className="form-check form-check-inline mb-2">
                      <input
                        className="form-check-input form-chck"
                        type="checkbox"
                        id="inlineCheckbox3"
                        value="option3"
                      />
                      <label
                        className="check-label-form ps-2"
                        for="inlineCheckbox3"
                      >
                        <a
                          className="link-class"
                          href="https://sites.google.com/dxhub.co.jp/rebellious-corporation/%E3%83%9B%E3%83%BC%E3%83%A0"
                          target="_blank"
                        >
                          {t("handling_personal_info")}
                        </a>
                        {t("social_force_info")}
                      </label>
                    </div>
                  </div>
                </div>
              </div> */}
                <div class="row">
                  <div className="confirm_head">
                    <p className="font-18-bold" >  {t("full_name")} </p>
                  </div>

                  <div class="col-md-4">
                    <div class="mb-5">
                      <label for="name" class="form-label input-label fnd-16"><b>{t("last_name")}</b></label>
                      <div> <label for="name">{user.lastName}</label></div>
                    </div>

                  </div>
                  {/* {user.middleName !== null && user.middleName !== undefined  && user.middleName !== "" ?( */}
                  <div class="col-md-4">
                    <div class="mb-5">
                      <label for="name" class="form-label input-label  fnd-16"><b>{t("middle_name_confir")}</b></label>
                      <div> <label for="name">{user.middleName}</label></div>
                    </div>
                  </div>
                  {/* ):(<></>)}  */}
                  <div class="col-md-4">
                    <div class="mb-5">
                      <label for="name" class="form-label input-label  fnd-16"><b>{t("first_name")}</b></label>
                      <div><label for="name">{user.fullName}</label></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="mb-5">
                      <label for="name" class="form-label input-label fnd-16"><b>{t("tel_no")}</b></label>
                      <div><label for="name">{user.telphone}</label></div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-5">
                      <label for="emailHelp" class="form-label input-label fnd-16"><b>{t("email_id")}</b></label>
                      <div><label for="name">{user.email}</label></div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-5">
                      <label for="name" class="form-label input-label fnd-16"> <b>{t("desied_occupation")}</b></label>
                      <div><label for="name">{user.occupation}</label></div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-5">

                    </div>

                  </div>


                  {/* <div class="col-md-4">
              <div class="mb-5">
                <label for="name" class="form-label input-label fnd-16"><b>{t("dob")}</b></label>
                <div><label for="name">{user.dateOfBirth}</label></div>
              </div>
            </div> */}
                </div>
                {/* <div class="row">
            <div className="confirm_head">
              <p className="font-18-bold"> {t("current_aadr")} <br/>
                <span class="address-sub">都道府県・市区町村・番地・マンション名・部屋番号までのご入力をお願い致します。
                </span>
              </p>
            </div>
            <div class="col-md-4">
              <div class="mb-5">
                <label for="name" class="form-label input-label fnd-16"><b>{t("prefeature")}</b></label>
                <div><label for="name">{user.prefature}</label></div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-5">
                <label for="name" class="form-label input-label fnd-16"><b>{t("city")}</b></label>
                <div> <label for="name">{user.city}</label></div>
              </div>
            </div>
            {user.apartment !== null && user.apartment !== undefined  && user.apartment !== "" ?(
               <div class="col-md-4">
              <div class="mb-5">
                <label for="name" class="form-label input-label fnd-16"><b>{t("apartment_name_confir")}</b></label>
                <div> <label for="name">{user.apartment}</label></div>
              </div>
            </div> 
            ): (<></>)}
          </div> */}
                {/* {user.occupation && (
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-5">
                        <label for="name" class="form-label input-label fnd-16"> <b>{t("desied_occupation")}</b></label>
                        <div><label for="name">{user.occupation}</label></div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-5">

                      </div>

                    </div>
                  </div>
                )} */}

                {user.resume !== null && user.resume !== undefined && user.resume !== "" ? (<div class="row">
                  <div class="col-md-6">
                    <div class="mb-5">
                      <label for="name">
                        <a href={user.resume} target="_blank">
                          {t("view_resume")}
                        </a>
                      </label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-5">

                    </div>

                  </div>
                </div>) : (<></>)}
                <hr />
                <div className="background-indicator">
                  <div className="row px-4 pt-3">
                    <div className="form-check form-check-inline mb-2">
                      <input
                        className="form-check-input form-chck"
                        type="checkbox"
                        id="inlineCheckbox1"
                        value="option1"
                      />
                      <label
                        className="check-label-form ps-2"
                        for="inlineCheckbox1"
                      >
                        <a
                          className="link-class"
                          href="https://sites.google.com/dxhub.co.jp/personal-information/%E3%83%9B%E3%83%BC%E3%83%A0"
                          target="_blank"
                        >
                          {t("handling_personal_info")}
                        </a>
                      </label>
                    </div>
                    <div className="form-check form-check-inline mb-2">
                      <input
                        className="form-check-input form-chck"
                        type="checkbox"
                        id="inlineCheckbox2"
                        value="option2"
                      />
                      <label
                        className="check-label-form ps-2"
                        for="inlineCheckbox2"
                      >
                        <a
                          className="link-class"
                          href="https://dxhub.co.jp/privacy-policy"
                          target="_blank"
                        >
                          {t("privacy_policy_info")}
                        </a>

                      </label>
                    </div>
                    <div className="form-check form-check-inline mb-2">
                      <input
                        className="form-check-input form-chck"
                        type="checkbox"
                        id="inlineCheckbox3"
                        value="option3"
                      />
                      <label
                        className="check-label-form ps-2"
                        for="inlineCheckbox3"
                      >
                        <a
                          className="link-class"
                          href="https://sites.google.com/dxhub.co.jp/rebellious-corporation/%E3%83%9B%E3%83%BC%E3%83%A0"
                          target="_blank"
                        >
                          {t("social_force_info")}
                        </a>

                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 text-center">
                  <button className="cancel-btn" onClick={(e) => cancel(e)}>
                    {" "}
                    {t("cancel")}
                  </button>
                  <button
                    className="main-buttons sub-btn"
                    onClick={(e) => confirm(e)}
                  >
                    {" "}
                    {t("confirm")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>



      <a
        href=""
        className="edit-icon"
        data-bs-toggle="modal"
        id="resendpopupButton"
        data-bs-target="#resendpopup"
        hidden
      ></a>
      <div
        className="modal fade"
        id="resendpopup"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body px-5 py-3">
              <div className="row mb-2 mt-5 px-3">
                <div className="col-md-12 text-center">
                  <i className="fa-solid fa-circle-info info-pop"></i>
                </div>
                <div className="text-center mt-4 mb-4">
                  <h5> {t("resend_heading")}</h5>
                </div>

                <div className="text-center">
                  <p>{t("resend_description")}</p>
                </div>
              </div>
              <div className="col-md-12 text-center mb-3">
                <button className="cancel-btn" data-bs-dismiss="modal">
                  {" "}
                  {t("cancel")}
                </button>
                <button
                  className="main-buttons sub-btn"
                  data-bs-dismiss="modal"
                  onClick={resend}
                >
                  {" "}
                  {t("resend")}
                </button>
              </div>
            </div>
            {/* <div className="modal-footer border-0">
                       
                    </div> */}
          </div>
        </div>
      </div>
      <a
        href=""
        className="edit-icon"
        data-bs-toggle="modal"
        id="successPopUpButton"
        data-bs-target="#successPopup"
        hidden
      ></a>
      <div
        className="modal fade"
        id="successPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-confirm ">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <div className="icon-box">
                <i
                  className="fa fa-exclamation-triangle"
                  aria-hidden="true"
                ></i>
              </div>

              <Link to="/job-list/1">
                <button
                  type="button"
                  id="successPopUpCloseButton"
                  className="Close-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ color: "white" }}
                >
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </Link>
            </div>
            <div className="modal-body text-center">
              <p>{errorJobResponse}</p>
              <div>
                <button
                  className="btn btn-success"
                  data-dismiss="modal"
                  onClick={handleOnClickPopUp}
                >
                  <span>{t("ok")}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default RegisterConfirmation;
