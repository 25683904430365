import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { Link } from "react-router-dom";
import logo from '../../Images/privacy-img.png'
import { actions } from "../../redux/companyReducer";
import { useDispatch } from 'react-redux';
const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
      i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
      dispatch(actions.setuserDetails({}));
      // eslint-disable-next-line 
    }, []);
  return(
<body className="d-flex flex-column h-100">
  <div className="banner-login middle-toparea">
    <div className="container-fluid padding-body">
      <div className="sub-menus"><Link className="topI" to={'/'}><a style={{"color":"#DBDBDB"}}>{t('top')}</a></Link> / <span>{t('privacy_policy')}</span> </div>

      <div className="login-header">
        <h3>{t('privacy_policy')}</h3>
      </div>
    </div>

  </div>

  <div className="container my-5">
    <div className="card mt-3 Card-box">
      <div className="card-body">
   

      <div class="mb-4"><p>改定の効力発生日：2022年4月1日</p></div>
        <h5 class="mb-4">個人情報保護方針</h5>
        <div>
          <p>DXHUB株式会社は、各事業を実施する上で、お客様の個人情報がプライバシーを構成する重要な情報であることを深く認識し、業務において個人情報を取り扱う場合には、個人情報に関する法令及び個人情報保護のために定めた社内規定を定め、また、組織体制を整備し、個人情報の適切な保護に努めることにより、お客様を尊重し、当社に対する期待と信頼に応えていきます。</p>
        </div>
        <div>
          <div class="terms-privacy">
            <h5>個人情報の取得、利用、提供</h5>
          </div>
          <div class="pt-3 ps-4">
            <p>私たちは、事業活動の範囲内で個人情報の利用目的を特定し、その目的達成のために必要な限度で公正かつ適正に個人情報の取得、利用及び提供を行います。また、取得した個人情報の目的外利用をしないよう処置を講じます。</p>
          </div>
        </div>

        <div>
          <div class="terms-privacy">
            <h5>法令・規範の遵守</h5>
          </div>
          <div class="pt-3 ps-4">
            <p>私たちは、個人情報に関する法令、国が定める指針、その他の規範及び社会秩序を遵守を遵守するため、日本産業規格「個人情報保護マネジメントシステム‐要求事項」（JIS Q 15001･2017）に準拠した個人情報保護マネジメントシステムを確立し、適切に運用します。通信履歴、通話履歴、発信者情報等の通信の秘密に関わる情報については、電気通信事業法第4条その他の関連規定およびガイドラインに従い、適切に取り扱います。</p>
          </div>
        </div>


        <div>
          <div class="terms-privacy">
            <h5>個人情報の適切な管理</h5>
          </div>
          <div class="pt-3 ps-4">
            <p>私たちは、私たちが取り扱う個人情報について、不正アクセス、紛失、破壊、改ざん、漏えいなどの危険を十分に認識し、合理的な安全対策を実施するとともに、問題が発生した場合は適切な是正措置を講じます。</p>
          </div>
        </div>

        <div>
          <div class="terms-privacy">
            <h5>苦情・相談への対応</h5>
          </div>
          <div class="pt-3 ps-4">
            <p>私たちは、保有する個人情報についての苦情・相談は、下記の個人情報問合せ窓口に連絡頂くことにより、これに対応致します。</p>
          </div>
        </div>

        <div>
          <div class="terms-privacy">
            <h5>問い合わせへの対応</h5>
          </div>
          <div class="pt-3 ps-4">
            <p>私たちは、私たちが取り扱う個人情報について、本人から開示、訂正、利用停止及び苦情相談等のお問い合わせがあった場合は適正に対応します。</p>
          </div>
        </div>

        <div>
          <div class="terms-privacy">
            <h5>継続的改善</h5>
          </div>
          <div class="pt-3 ps-4">
            <p class="mb-4">私たちは、個人情報保護に関する管理規定及び管理体制を整備し、全社員で徹底して運用するとともに定期的な見直しを行い、継続的な改善に努めます。</p>
            <p>制定日：2015年10月7日<br></br>
            最終改訂日：2022年4月1日</p>

            <div class="mt-4 mb-4">
            <p>ＤＸＨＵＢ株式会社<br></br>
            代表取締役　澤田賢二</p>
            </div>
          </div>
        </div>

        <div class="privacy-backg">
          <h5 class="mb-4">＜個人情報保護方針・個人情報保護に関する連絡先＞</h5>

          <p>個人情報問合せ窓口 <br></br>
            個人情報保護管理者：澤田賢二 <br></br>
            TEL.075-496-5550</p>

            <div><img src={logo} alt="" width="120px" height="120px"/></div>
        </div>
        <div>
          <div class="terms-privacy">
            <h5>当社が取扱う個人情報について
            </h5>
          </div>
          <div class="pt-3 ps-4 pb-3" >
            <p>当社では「個人情報保護方針」に基づき個人情報の適切な保護に取り組んでいます。当社が事業の用に供するために取得し、または保有する個人情報について、以下の通りお知らせいたします。</p>
          </div>
        </div>
        <div>
            <h6>1. 個人情報の取扱事業者の名称 </h6>
          
          <div class="pt-3 ps-4">
            <p>ＤＸＨＵＢ株式会社  </p>
          </div>
          </div>
          <div>
            <h6>2. 個人情報の利用目的</h6>
        
          <div class="pt-3 ps-4">
            <p>当社が事業活動において取得し、または保有する個人情報の利用目的は、次の通りといたします。</p>
        </div>
      </div>

      <div>
        <h6><strong>1）保有個人情報（直接書面取得の場合の個人情報）</strong></h6>
      <div class="mt-4 mb-4">
        <div>
          <table class="table table-bordered table-privacy">
          <tbody>
          <tr>
          <th class="w30">個人情報の種別</th>
          <th>利用目的</th>
          </tr>
          <tr>
          <td class="bold">取引先情報</td>
          <td>業務管理、各種連絡、請求、支払い管理のため</td>
          </tr>
          <tr>
          <td class="bold">従業者情報</td>
          <td>従業者管理に係わる業務に利用するため（業務・労務・人事管理業務、給与関連業務、福利厚生業務など）</td>
          </tr>
          <tr>
          <td class="bold">採用応募者情報</td>
          <td>採用に係わる業務に利用するため（採用に関する情報提供、採用可否判断、採用業務に関する連絡など）</td>
          </tr>
          <tr>
          <td class="bold">退職者情報</td>
          <td>退職者との連絡、退職者からのお問合せへの対応に利用するため</td>
          </tr>
          <tr>
          <td class="bold">顧客情報</td>
          <td>取扱サービスの契約・サービス提供・サポート業務・アンケート実施・利用状況の分析のため</td>
          </tr>
          <tr>
          <td class="bold">お問合せ者情報</td>
          <td>お問合せに回答するため</td>
          </tr>
          <tr>
          <td class="bold">本人および代理人の情報（開示等請求時）</td>
          <td>開示等の求めに回答するため</td>
          </tr>
          <tr>
          <td class="bold">人材紹介に関わる応募者情報</td>
          <td>人材紹介に利用するため</td>
          </tr>
          </tbody>
          </table>
          </div>
          <p class="mb-3">その他、個別に書面で明示したとおりの利用目的とします。</p>
    </div>
  </div>

<div>
    <h6><strong>2）非開示個人情報（直接書面取得以外で取得する場合の個人情報）</strong></h6>

  <div class="mt-3 mb-3">
  
</div>
      <table class="table table-bordered table-privacy">
        <tbody>
        <tr>
        <th class="w30">個人情報の種別</th>
        <th>利用目的</th>
        </tr>
        <tr>
        <td class="bold">受託した業務により取得した個人情報</td>
        <td>契約及びそれに伴う連絡、受託業務の遂行、アフターケアなどに利用するため</td>
        </tr>
        <tr>
        <td class="bold">求人サイトから取得した情報</td>
        <td>求人者に対する採用の可否を判断・通知するため</td>
        </tr>
        </tbody>
        </table>
</div>
<div>
  <h6><strong>3. クッキー（Cookie）の使用について</strong></h6>

<div class="pt-3 ps-4 mb-4">
  <p>クッキーとはウェブサーバーとユーザーのウェブブラウザとの間で送受信される情報のことで、個々のブラウザの識別などに利用されます。</p>
  <p>当社が運営するサイトでは第三者配信の広告サービスならびにアクセス解析のサービスを利用しており、こうしたサービスはクッキーを使用しております。</p>
</div>
</div>

<div>
  <h6>【クッキーの利用目的】</h6>

<div class="pt-3 ps-4 ">
  
  <p>クッキーを通して収集したデータは、以下の目的で使用されます。</p>
    <div class="ps-3 pt-2 mb-4">

      <ol>
        <li class="mb-3">当社が運営するサイトの閲覧者の利便性向上のため</li>
        <li class="mb-3">ユーザーの嗜好や設定を記録するため</li>
        <li class="mb-3">コンテンツの人気度を判定するため</li>
        <li class="mb-3">当社あるいは第三者が運営するサイト上で表示される広告の配信、パーソナライズ、および効果測定を行うため</li>
        <li class="mb-3">当社が運営するサイトのトラフィック状況を把握し、サービスの改善へ繋げるため</li>
        </ol>

    </div>
</div>
</div>

<div>
  <h6>【クッキーのブロック】</h6>

<div class="pt-3 ps-4 ">
  
  <p>ほとんどのブラウザでは、クッキーの使用を拒否することができます。主要なブラウザにおけるクッキーの管理については、下記をご参照ください。</p>
    <div class="ps-3 pt-2 mb-4">

      <ul>
        <li class="mb-3"><a href="https://support.microsoft.com/ja-jp/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noopener noreferrer">Cookie の削除と管理を行う–Windows Help</a></li>
        <li class="mb-3"><a href="https://support.google.com/chrome/answer/95647" target="_blank" rel="noopener noreferrer">Chrome でCookie の削除、有効化、管理を行う–パソコン–Google Chrome ヘルプ</a></li>
        <li class="mb-3"><a href="https://support.mozilla.org/ja/kb/block-websites-storing-cookies-site-data-firefox" target="_blank" rel="noopener noreferrer">Firefox でCookie やサイトデータの保存をブロックする| Firefox ヘルプ</a></li>
        <li class="mb-3"><a href="https://support.apple.com/ja-jp/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer">MacのSafariでCookieとWebサイトのデータを管理する–Apple サポート</a></li>
        </ul>

    </div>

    <p class="pt-4 pb-4">クッキーをすべてブロックすると、多くのウェブサイトにおけるユーザビリティに悪影響がでる恐れがあります。</p>
</div>
</div>

<div>
  <h6><strong>4. 広告について</strong></h6>

<div class="pt-3 ps-4 mb-4">
  <p>当社は第三者配信の広告サービスを利用し、過去にユーザーが当社あるいは第三者が運営するサイトを訪問した際の情報に基づいて最適化された広告を配信することがあります。この最適化にはクッキーが用いられています。</p>
  <p>それぞれの広告サービスによる行動ターゲティング広告の停止（オプトアウト）をご希望の場合は、以下をご参照ください。</p>
</div>
</div>

<div>
  <h6>【Googleアドセンス】</h6>

<div class="pt-3 ps-4 ">
  
  <p>Googleアドセンスのパーソナライズ広告は広告設定の画面より無効化することができます。</p>
    <div class="ps-3 pt-2 mb-4">

      <ul>
        <li><a href="https://adssettings.google.com/authenticated" target="_blank" rel="noopener noreferrer">広告設定</a></li>
        </ul>

    </div>

    <p>または、<a href="http://www.aboutads.info/choices/" target="_blank" rel="noopener noreferrer">www.aboutads.info</a>にアクセスすることで、パーソナライズ広告に使われる第三者配信事業者のクッキーを無効化できます。</p>
</div>
</div>


<div>
  <h6>【Google広告】</h6>

<div class="pt-3 ps-4 ">
  
  <p>Googleにおけるパーソナライズ広告は広告設定の画面より無効化することができます。</p>
    <div class="ps-3 pt-2 mb-4">

      <ul>
        <li><a href="https://adssettings.google.com/authenticated" target="_blank" rel="noopener noreferrer">広告設定</a></li>
        </ul>

    </div>

    <p>Googleの広告におけるクッキーの取り扱い詳細については、以下のページをご確認ください。</p>

    <div class="ps-3 pt-2 mb-4">

      <ul>
        <li><a href="https://policies.google.com/technologies/ads" target="_blank" rel="noopener noreferrer">広告–ポリシーと規約–Google</a></li>
        </ul>

    </div>


  </div>
</div>

<div>
  <h6>【Facebook広告】</h6>

<div class="pt-3 ps-4 ">
  
  <p>Facebookではアカウントの広告表示設定の画面よりオプトアウトを行うことができます。詳しくは以下をご参照ください。</p>
    <div class="ps-3 pt-2 mb-4">

      <ul>
        <li><a href="https://www.facebook.com/help/109378269482053" target="_blank" rel="noopener noreferrer">Ad Preferences | Facebookヘルプセンター</a></li>
        </ul>

    </div>

</div>
</div>

<div>
  <h6>【WeChat広告】</h6>

<div class="pt-3 ps-4 ">
  
  <p>WeChat広告におけるクッキーの管理については、以下のクッキーポリシーをご確認ください。</p>
    <div class="ps-3 pt-2 mb-4">

      <ul>
        <li><a href="https://www.wechat.com/mobile/htdocs/ja/cookies_policy.html" target="_blank" rel="noopener noreferrer">WeChat -クッキーポリシー</a></li>
        </ul>

    </div>

</div>
</div>
<div>
  <h6><strong>5. アクセス解析について</strong></h6>

<div class="pt-3 ps-4 mb-4">
  <p>当サイトではGoogleアナリティクスを使用し、クッキーに基づいてトラフィックデータの収集を行っています。<a href="https://tools.google.com/dlpage/gaoptout/" target="_blank" rel="noopener noreferrer">Googleアナリティクスオプトアウトアドオン</a>を使用すると、データの収集を拒否することができます。</p>
  <p>Googleのポリシーについては以下をご参照ください。</p>

  <div class="ps-3 pt-2 mb-4">

    <ul>
      <li><a href="https://policies.google.com/technologies/partner-sites" target="_blank" rel="noopener noreferrer">Google のサービスを使用するサイトやアプリから収集した情報のGoogle による使用–ポリシーと規約–Google</a></li>
      </ul>

  </div>
</div>
</div>


<div>
  <h6><strong>6. 委託先の管理監督</strong></h6>

<div class="pt-3 ps-4 mb-4">
  <p>当社は、取扱いの全部又は一部を外部へ委託する場合は、委託先と機密保持を含む契約の締結、または、DXHUBが定める情報セキュリティポリシーに則った取扱いを徹底させ、委託先において個人情報の安全管理が図られるよう、必要かつ適切な管理監督を行います。</p>

</div>
</div>


<div>
  <h6><strong>7. 個人情報に関するお問合せ窓口</strong></h6>

<div class="pt-3 ps-4 mb-4">
  <p>下記の開示等の手続きについて、をご参照ください。

  </p>

</div>
</div>

<div>
  <h6><strong>8. 所属する認定個人情報保護団体の名称および苦情の解決の申し出先</strong></h6>

<div class="pt-3 ps-4 mb-4">
  <p>認定個人情報保護団体の名称：一般財団法人日本情報経済社会推進協会</p>
  <p>苦情の解決の申し出先：個人情報保護苦情相談室</p>
  <p>住所：〒106-0032 東京都港区六本木一丁目９番９号 六本木ファーストビル内</p>
  <p> 電話番号：03-5860-7565　／　0120-700-779</p>

</div>
</div>
<div>
  <h6><strong>9. 開示等の手続について</strong></h6>

<div class="pt-3 ps-4 mb-4">
  <p>当社では、保有個人情報に関して、ご本人の情報の開示等（利用目的の通知、開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止）を希望される場合には、お申し出いただいた方がご本人或いはその代理人であることを確認した上で、合理的な期間及び範囲で回答、情報の訂正、追加又は削除、利用停止、消去及び第三者への提供の停止をします。</p>
  <p>ただし、これらの情報の一部又は全部を利用停止または消去した場合、不本意ながらご要望に沿ったサービスの提供ができなくなることがあります。（保有個人情報は、上記（２）に記載しております）</p>
  <p>また、当社に対する個人情報のご提供は、ご本人様の任意のご意思によります。ご本人様が個人情報のご提供を拒否された場合は、開示等の求めに対する回答ができない場合がございますのでご了承願います。これによりご本人様が被った損害（逸失利益を含む）、不利益等について、当社は何らの賠償責任等を負いません。</p>

</div>
</div>

<div>
  <h6><strong>10. 開示等の受付方法・窓口</strong></h6>

<div class="pt-3 ps-4 mb-4">
  <p>保有個人情報に関するご本人情報の開示等を請求の場合は、下記の受付窓口までご連絡ください。ご連絡いただきましたら、当社所定の「個人情報開示等請求書」を郵送または、FAX、メール等でお送り致しますので、記入の上、必要書類を同封し送付にてお申し込み下さい。（送付料は請求者のご負担となります。）</p>
  <p>なお、個人情報の「開示」または「利用目的の通知」の請求について、1回につき事務手数料として1000円（税込）を振込にてご負担いただきます。「個人情報開示等請求書」に記載する当社指定の口座にお振込いただき、振込証等の写しを同封しお送り下さい。（振込手数料は請求者のご負担となります。）</p>
  <p>ご本人（または代理人）であることを確認した上で、書面の交付により回答いたします。</p>
</div>
</div>

<div>
  <h6 class="pb-4"><strong>必要書類</strong>
  </h6>
</div>

<div>
  <h6>【本人が請求する場合】</h6>

<div class="pt-3 ps-4 ">
  
  <p>開示等の請求等を行う場合は、以下の書類をご準備いただき、配達記録郵便にてお送り下さい。 </p>
    <div class="ps-3 pt-2 mb-4">

      <ol>
        <li class="mb-3">個人情報開示等請求書</li>
        <li class="mb-3">本人確認書類<br></br>運転免許証、パスポート、健康保険証などの、ご本人の氏名、現住所を確認できる書類のコピー<br></br>※上記以外の書類でも個別でご対応致します。</li>
        <li class="mb-3">手数料の支払証書<br></br>(個人情報の訂正、追加、削除、利用停止、消去、第三者提供の停止の場合は不要です。)</li>
        </ol>

    </div>

</div>
</div>

<div>
  <h6>【代理人が請求する場合】</h6>

<div class="pt-3 ps-4 ">
  
  <p>開示等の求めを行う者が、法定代理人もしくは本人が委任した代理人である場合は、前項の書類に加えて、それぞれ下記の書類を同封下さい。</p>
    <div class="ps-3 pt-2 mb-4">
      <p><strong>（法定代理人の場合）</strong></p>

      <ol>
        <li class="mb-3">法定代理権があることを確認するための書類<br></br>戸籍謄本、親権者の場合は扶養家族が記入された健康保険の被保険者証のコピーも可</li>
        <li class="mb-3">法定代理人本人であることを確認するための書類<br></br>運転免許証、パスポート、健康保険証などの、代理人の氏名、現住所を確認できる書類のコピー<br></br>※上記以外の書類でも個別でご対応致します。</li>
        </ol>

    </div>

    <div class="ps-3 pt-2 mb-4">
      <p><strong>（委任による代理人の場合）</strong></p>

      <ol>
        <li class="mb-3">委任状（個人情報開示等請求書添付書類）</li>
        <li class="mb-3">ご本人の印鑑証明書（3ケ月以内に発行されたもの）</li>
        <li class="mb-3">委任による代理人本人であることを確認するための書類<br></br>運転免許証、パスポート、健康保険証などの、ご本人の氏名、現住所を確認できる書類のコピー<br></br>※上記以外の書類でも個別でご対応致します。</li>
        </ol>

    </div>

    <div class="ps-3 pt-2 mb-4">
      <p><strong>【問合せ窓口】</strong></p>

      <table class="table table-bordered table-privacy">
        <tbody>
        <tr>
        <th class="w30">窓口の名称</th>
        <th>個人情報問合せ窓口</th>
        </tr>
        <tr>
        <td class="bold">連絡先</td>
        <td>個人情報保護管理者：澤田賢二<p></p>
        <p>住所：京都市下京区中堂寺栗田町93番地京都リサーチパーク6号館2階</p>
        <p>電話/FAX ：075-496-5550/075-496-5577</p>
        <p>電子メール：privacy@dxhub.co.jp</p>
        </td>
        </tr>
        </tbody>
        </table>

    </div>

</div>
</div>



<div>
  <h6><strong>11. 個人情報の安全管理措置</strong></h6>

<div class="pt-3 ps-4 mb-4">
  <p>当社は、個人情報保護のための体制の整備・改善、従業者に対する教育・啓発活動、個人情報を取り扱うエリアへの入退館管理および個人情報へのアクセス制御、ウイルス対策ソフトの導入等の情報セキュリティ施策によって、個人情報の安全管理措置を講じ、その漏えい等の防止に努めます。また、保有を継続しない個人情報は、適切な方法で確実に廃棄または消去します。</p>
  <p>また当社は、これらの安全管理措置が適切に講じられていることを担保するために第三者機関による情報セキュリティに関する認証を取得し、継続的に個人情報保護体制を維持、改善してまいります。</p>
  <p>当社は、個人情報を保管している以下の国における個人情報の保護に関する制度を把握した上で安全管理措置を実施します。</p>
  <p>＜当社における個人情報の保管国＞</p>
  <p>・日本</p>
  <p>・アメリカ</p>
  <p>・ドイツ　　等</p>
  <p>・Googledatacenter<br></br> <a href="https://www.google.com/about/datacenters/locations/">https://www.google.com/about/datacenters/locations/</a></p>
</div>
</div>




      </div>
    </div>
  </div>


  <script src="js/bootstrap.bundle.js"></script>

</body>
)};

export default PrivacyPolicy;
