import React from 'react';
import ReactDOM from 'react-dom/client';

import './i18n';
import App from './App';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './vendor/fontawesome/css/all.css';
import './index.css';
import { refreshAccessToken, AppContextProvider } from "./AppContext";
import { Provider } from "react-redux";
import store ,{persistor} from "./redux/store";
import {PersistGate} from 'redux-persist/integration/react'
<script src="js/bootstrap.bundle.js" ></script>

const root = ReactDOM.createRoot(document.getElementById('root'));
refreshAccessToken().then((value) =>
root.render(
<AppContextProvider init={value}>
<Provider store={store}>
   <PersistGate loading={null} persistor={persistor}>
    <App />
  </PersistGate>
</Provider>
</AppContextProvider>,
)

);