import React, { useEffect, useState } from "react";
import useAppContext from "../../AppContext";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Link, useNavigate } from "react-router-dom";
import { actions } from "../../redux/companyReducer";
import { useDispatch } from 'react-redux';
function Inquiry() {
  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"+-@$!%*?&#_+=,._0-9]+\.)+[^<>()[\]\.,;:\s@\"]{2,3})$/i
  );
  const nameValidation =  RegExp(
    /[*|\":<>[\]{}`\\()';,.+=-@&$0-9]/i
  );
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const appContext = useAppContext();
  const axios = appContext.getAxios();
  const navigate = useNavigate();
  const [inquiry, setInquiry] = useState({
    name: "",
    email: "",
    question: "",
    description: "",
  });
  const { name, email, question, description } = inquiry;
  const [nameError, setNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [questionError, setQuestionError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  const [descriptionCount, setDescriptionCount] = useState(null);
  const [errorResponse,setErrorResponse] = useState(null);
  const [successResponse,setSuccessResponse] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    dispatch(actions.setuserDetails({}));
  }, []);
  const onInputChange = async (e) => {
    e.preventDefault();
    setInquiry({ ...inquiry, [e.target.name]: e.target.value.trimStart() });
    if (e.target.name === "name") {
      setNameError(null);
    }
    if (e.target.name === "email") {
      setEmailError(null);
    }
    if (e.target.name === "question") {
      setQuestionError(null);
    }
    if (e.target.name === "description") {
      setDescriptionError(null);
    }
    if (e.target.name === "name" && e.target.value === "") {
      setNameError(t("name_enquiry_validation"));
    }
    if(e.target.name === "name" && e.target.value !== "" && (nameValidation.test(e.target.value))){
      setNameError(t("valid_name"));
    }
    if (e.target.name === "email") {
      if (e.target.value == "") {
        setEmailError(t("email_enquiry_validation"));
      } else if (!validEmailRegex.test(e.target.value)) {
        setEmailError(t("valid_email"));
      }
    } 
    // else if (e.target.name === "question" && e.target.value === "") {
     
    //   setQuestionError(t("question_enquiry_validation"));
    // } 
    else if (e.target.name === "description" && e.target.value === "") {
      setDescriptionCount(null)
      setDescriptionError(t("description_enquiry_validation"));
    } else if(e.target.name === "description" && e.target.value !== ""){
      setDescriptionCount(e.target.value.length)
      console.log(descriptionCount)
    }
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    setErrorResponse(null)
    setSuccessResponse(null)
    validation();
    if (
      inquiry.name !== "" &&
      inquiry.email !== "" &&
      validEmailRegex.test(inquiry.email) && !nameValidation.test(inquiry.name)
    ) {
      setOpen(true);
      axios.post("inquiry", inquiry).then(
        (response) => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          console.log(response);
          setSuccessResponse(t("enquiry_sent_success"))
          inquiry.name = "";
          inquiry.email = "";
          inquiry.question = "";
          inquiry.description = "";
          setDescriptionCount(null)
          window.dataLayer.push({
            event:"Enquiry",
            });
         
          setOpen(false);
         // navigate("/enquiry-success");
        },
        (error) => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          setErrorResponse(t("enquriy_failed"))
          setTimeout(() => {
            setOpen(false);
          }, 1000);
        }
      );
    }
  };
  const validation = async () => {
    setNameError(null);
    setEmailError(null);
    setQuestionError(null);
    setDescriptionError(null);

    if (inquiry.name === "") {
      setNameError(t("name_enquiry_validation"));
    }
    if(inquiry.name !== "" && nameValidation.test(inquiry.name)){
      setNameError(t("valid_name"));
    }
    if (inquiry.email === "") {
      setEmailError(t("email_enquiry_validation"));
    }
    if (inquiry.email !== "" && !validEmailRegex.test(inquiry.email)) {
      setEmailError(t("valid_email"));
    }
    // if (inquiry.question === "") {
    //   setQuestionError(t("question_enquiry_validation"));
    // }
    if (inquiry.description === "") {
      setDescriptionCount(null)
      setDescriptionError(t("description_enquiry_validation"));
    }
  };
  return (
    <>
      <div className="banner-login middle-toparea">
        <div className="container-fluid padding-body">
          <div className="sub-menus">
            <Link className="topI" to={"/"}>
              {t("top")}
            </Link>{" "}
            / <span> {t("inquiry_form")}</span>{" "}
          </div>

          <div className="login-header">
            <h3>{t("inquiry_form")}</h3>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="card mt-3 Card-box">
        <div className="validation">{errorResponse}</div>
        <div className="validation-success">{successResponse}</div>
          <div className="card-body">
            <form onSubmit={(e) => onSubmit(e)}>
              <div className="mb-3">
                <label for="name" className="form-label input-label">
                  {t("full_name")}
                </label>
                <input
                  type="text"
                  id="name"
                  aria-describedby="emailHelp"
                  maxLength={100}
                  name="name"
                  value={name}
                  onChange={(e) => onInputChange(e)}
                  className={`form-control custom-input custom-input-changeJa ${
                    nameError ? "is-invalid" : ""
                  }`}
                />
                {nameError ? (
                  <div className="invalid-feedback ">{nameError}</div>
                ) : (
                  ""
                )}
              </div>

              <div className="mb-3">
                <label for="name" className="form-label input-label">
                  {t("email_id")}
                </label>
                <input
                  type="text"
                  id="name"
                  aria-describedby="emailHelp"
                  maxLength={50}
                  name="email"
                  value={email}
                  onChange={(e) => onInputChange(e)}
                  className={`form-control custom-input custom-input-changeJa ${
                    emailError ? "is-invalid" : ""
                  }`}
                />
                {emailError ? (
                  <div className="invalid-feedback ">{emailError}</div>
                ) : (
                  ""
                )}
              </div>

              {/* <div className="mb-3">
                <label for="name" className="form-label input-label">
                  {t("question")}
                </label>
              
                 <textarea
                  id="name"
                  rows="5"
                  maxLength={500}
                  name="question"
                  value={question}
                  onChange={(e) => onInputChange(e)}
                  className={`form-control textare-custom custom-input-changeJa ${
                    questionError ? "is-invalid" : ""
                  }`} >
                 
                </textarea>
                {questionError ? (
                  <div className="invalid-feedback ">{questionError}</div>
                ) : (
                  ""
                )}
              </div> */}

              <div className="mb-3">
                <label for="name" className="form-label input-label">
                  {t("detailed_description")}
                </label>
                <textarea
                  id="exampleFormControlTextarea1"
                  rows="5"
                  maxLength={1000}
                  name="description"
                  value={description}
                  onChange={(e) => onInputChange(e)}
                  className="form-control textare-custom custom-input-changeJa"
                >
                 
                </textarea>
           {descriptionCount ? (
                  // <div className="info-feedback "> {t("name_enquiry_validation")} : {descriptionCount}</div>
                  <div className="textCount">{descriptionCount}/2000</div>
                ) : (
                  ""
                  
                )} 
               
              </div>

              <div className="text-center">
                <button type="submit" className="main-buttons">
                  {" "}
                  {t("submit")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer
        position="bottom-right"
        autoClose={800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default Inquiry;
