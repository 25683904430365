import {BrowserRouter as Router,Routes, Route} from 'react-router-dom'
import React, { useEffect } from "react";
import Home from './components/home/home.js';
import Companydetails from './components/company-details/company-details.js';
import Counseling from './components/counseling/counseling.js';
import Registration from './components/registration/registration.js';
import Jobdetails from'./components/job-details/job-details.js';
import StartPage from './components/start-page/start-page.js';
import Login from './components/login/login.js';
import Jobsearch from './components/job-search/job-search.js';
import Joblist from './components/job-list/job-list.js';
import Companylist from './components/company-list/company-list.js';
import JobApplication from './components/job-application/job-application.js';
import Footer from "./components/footer/footer.js";
import ForgotPassword from './components/forgot-password/forgot-password.js';
import ResetPassword from './components/reset-password/reset-password.js';
import ServiceDetails from './components/service-details/service-details.js';
import BlogDetails from './components/blog-details/blog-details.js'
import Faq from './components/faq/faq.js';
import Inquiry from './components/inquiry/inquiry.js';
import PrivacyPolicy from './components/privacy-policy/privacy-policy.js';
import TermsOfUse from './components/terms-of-use/terms-of-use.js';
import Error from './components/error/error.js';
import TagManager from 'react-gtm-module'
import JobapplySuccess from './components/jobapply-success/jobapply-success.js';
import RegistrationSuccess from './components/registration-success/registration-success.js';
import CounslingSuccess from './components/counsling-success/counsling-success.js';
import EnquirySucess from './components/enquiry-sucess/enquiry-sucess.js';
import ConfirmationCareer from './components/confirmation-career/confirmation-career.js';
import RegisterConfirmation from './components/register-confirmation/register-confirmation.js';
import JobApplyError from './components/job-apply-error/job-apply-error.js';
function App() {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID
  }
  TagManager.initialize(tagManagerArgs)

  return (
    <div className="App">
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />}>
          <Route path="" element={<StartPage />} />
          <Route path="company-details/:id" element={<Companydetails />} />
          <Route path="job-details/:id" element={<Jobdetails />} />
          <Route path="blog-details/:id" element={<BlogDetails />} />
          <Route path="service-details" element={<ServiceDetails />} />
          <Route path="counseling" element={<Counseling />} />
          <Route path="job-search" element={<Jobsearch />} />
          <Route path="job-list/:page" element={<Joblist />} />
          <Route path="job-apply" element={<JobApplication />} />
          <Route path="company-list/:page" element={<Companylist />} />
          <Route path="registration" element={<Registration />} />
          <Route path="login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password/:token" element={<ResetPassword />} />
          <Route path="inquiry" element={<Inquiry />} />
          <Route path="faq" element={<Faq />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-of-use" element={<TermsOfUse />} />
          <Route path="login/:token" element={<Login />} />
          <Route path="jobapply-success" element={<JobapplySuccess />} />
          <Route path="registration-success" element={<RegistrationSuccess />} />
          <Route path="counseling-success" element={<CounslingSuccess />} />
          <Route path="enquiry-success" element={<EnquirySucess />} />
          <Route path="career-confirmation" element={<ConfirmationCareer />} />
          <Route path="register-confirmation" element={<RegisterConfirmation />} />
          <Route path="job-apply-error" element={<JobApplyError />} />
        </Route>
        <Route path="error" element={<Error />} />
        <Route path='*' exact={true} element={<Error />}  />
    </Routes>
 
   </Router>

  </div>
  );
}

export default App;
