import React, { useEffect, useState } from "react";
import useAppContext from "../../AppContext";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../redux/companyReducer";
function Registration() {
  let history = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [errorResponse, setErrorResponse] = useState(null);
  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"+-@$!%*?&#_+=,._0-9]+\.)+[^<>()[\]\.,;:\s@\"]{2,3})$/i
  );
  const vaildPasswordRegex = RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+={}\[\]>?.<|:;'',`"/\\-])?[A-Za-z\d~!@#$%^&*()_+={}\[\]>?.<|:;'',`"/\\-]{8,20}$/
  );
  const nameValidation = RegExp(/[*|\":<>[\]{}`\\()';,.+=-@&$0-9]/i);
  const phoneValidation = RegExp(/^[0-9０-９]+$/i)
  const appContext = useAppContext();
  // const [dateOpen, setDateOpen] = useState(false);
  const axios = appContext.getAxios();
  const [user, setUser] = useState({
    fullName: "",
    password: "",
    email: "",
    dateOfBirth: "",
    location: "",
    skill: "",
    experience: "",
    interestedJobCategory: "",
    file: "",
    resume: "",
    middleName:"",
    lastName:"",
    telphone:"",
    prefature:"",
    city:"",
    apartment:"",
    occupation:""
  });

  const {
    fullName,
    password,
    email,
    dateOfBirth,
    location,
    skill,
    experience,
    interestedJobCategory,
    middleName,
    lastName,
    telphone,
    prefature,
    city,
    apartment,
    occupation
  } = user;
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [fullNameError, setFullNameError] = useState(null);
  const [dateOfBirthError, setDateOfBirthError] = useState(null);
  const [locationError, setLocationError] = useState(null);
  const [skillError, setSkillError] = useState(null);
  const [experienceError, setExperienceError] = useState(null);
  const [interestedJobCategoryError, setInterestedJobCategoryError] =
    useState(null);
  const [locale, setLocale] = React.useState("ja");
  const [datePickerValue, setDatePickerValue] = React.useState(null);
  const [category, setCategory] = useState([]);
  const [selectedJobCategory, setSelectedJobCategory] = useState([]);
  const [selectedJobSubCategory, setSelectedJobSubCategory] = useState([]);
  const [interestedCategory, setInterestedCategory] = useState("");
  const [count, setCount] = useState(0);
  const [maxDate, setmaxDate] = React.useState(dayjs(new Date()));
  const [jobId, setJobId] = useState();
  const [tempList, setTempList] = useState([]);
  const [tempCatList, setTemCatpList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [dummyLocation, setDummyLocation] = useState();
  const [locationName, setLocationName] = useState();
  const [checkedLocation, setCheckedLocation] = useState(0);
  const [errorJobResponse, setErrorJobResponse] = useState(null);
  const [errorTokenResponse, setErrorTokenResponse] = useState(null);
  const [successResponse, setSuccessResponse] = useState(null);
  const [dummyDob, setDummyDob] = useState(null);
  const [logoError, setLogoError] = useState(null);
  const [career, setCareer] = useState(null);
  const dispatch = useDispatch();
  const counterState = useSelector((state) => state.company);
  const [userBody, setUserBody] = useState(counterState.user);
  const [image, setImage] = useState(null);
  const [middleNameError, setMiddleNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [telphoneError, setTelphoneError] = useState(null);
  const [prefatureError, setPrefatureError] = useState(null);
  const [cityError, setCityError] = useState(null);
  const [occupationError, setOccupationError] = useState(null);
  const [apartmentError, setApartmentError] = useState(null);
  const [resume, setResume] = useState(null);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    getAllCategory();
    getAllArea();
    console.log(localStorage.getItem("JOB_ID"))
    setJobId(localStorage.getItem("JOB_ID"));
    setCareer(localStorage.getItem("career_Id"));
    getUserDetails();
    setTimeout(() => {
      localStorage.removeItem("JOB_ID");
      localStorage.removeItem("career_Id");
    }, 1000);
  }, []);
  const onInputChange = (e) => {
    e.preventDefault();
    setUser({ ...user, [e.target.name]: e.target.value.trimStart() });
    console.log(selectedJobSubCategory);
    if (e.target.name === "fullName") {
      setFullNameError(null);
    }
    if (e.target.name === "middleName") {
      setMiddleNameError(null);
    }
    if (e.target.name === "lastName") {
      setLastNameError(null);
    }
    if (e.target.name === "telphone") {
      setTelphoneError(null);
    }
    if (e.target.name === "prefature") {
      setPrefatureError(null);
    }
    if (e.target.name === "city") {
      setCityError(null);
    }
    if (e.target.name === "apartment") {
      setApartmentError(null);
    }
    if (e.target.name === "occupation") {
      setOccupationError(null);
    }
    // if (e.target.name === "dateOfBirth" && dateOfBirthError !== "invalidDate") {
    //   setDateOfBirthError(null);
    // }
    // if (e.target.name === "location") {
    //   setLocationError(null);
    // }
    // if (e.target.name === "interestedJobCategory") {
    //   setInterestedJobCategoryError(null);
    // }
     // if (e.target.name === "skill") {
    //   setSkillError(null);
    // }
    // if (e.target.name === "experience") {
    //   setExperienceError(null);
    // }
    if (e.target.name === "fullName" && e.target.value === "") {
      setFullNameError(t("fullname_error"));
    }
    if (
      e.target.name === "fullName" &&
      e.target.value !== "" &&
      nameValidation.test(e.target.value)
    ) {
      setFullNameError(t("valid_name"));
    }
    if (
      e.target.name === "middleName" &&
      e.target.value !== "" &&
      nameValidation.test(e.target.value)
    ) {
      setMiddleNameError(t("middle_valid"));
    }
    if (e.target.name === "lastName" && e.target.value === "") {
      setLastNameError(t("lastName_error"));
    }
    if (
      e.target.name === "lastName" &&
      e.target.value !== "" &&
      nameValidation.test(e.target.value)
    ) {
      setLastNameError(t("valid_lastName"));
    }
    // if (e.target.name === "dateOfBirth" && e.target.value === "") {
    //   setDateOfBirthError(t("dob_error"));
    // }
    // if (e.target.name === "dateOfBirth" && datePickerValue === null) {
    //   setDateOfBirthError(t("dob_error"));
    // }
    if (e.target.name === "telphone" && e.target.value === "") {
      setTelphoneError(t("telephone_error"));
    }
    if (
      e.target.name === "telphone" &&
      e.target.value !== "" &&
      !phoneValidation.test(e.target.value)
    ) {
      setTelphoneError(t("valid_telephone"));
    }
    if (e.target.name === "prefature" && e.target.value === "") {
      setPrefatureError(t("prefature_error"));
    }
    if (e.target.name === "city" && e.target.value === "") {
      setCityError(t("city_error"));
    }
    // if (e.target.name === "apartment" && e.target.value === "") {
    //   setApartmentError(t("apartment_error"));
    // }
    if (e.target.name === "occupation" && e.target.value === "") {
      setOccupationError(t("occupation_error"));
    }
    // if (e.target.name === "skill" && e.target.value === "") {
    //   setSkillError(t("skill_error"));
    // }
    // if (e.target.name === "experience" && e.target.value === "") {
    //   setExperienceError(t("experience_error"));
    // }
    // if (
    //   e.target.name === "experience" &&
    //   e.target.value !== "" &&
    //   !/^(\d{0,2})?([.]\d{1,1})?$/.test(e.target.value)
    // ) {
    //   setExperienceError(t("experience_valid"));
    // }
    // if (e.target.name === "location" && e.target.value === "") {
    //   setLocationError(t("location_error"));
    // }
    // if (e.target.name === "interestedJobCategory" && e.target.value === "") {
    //   setInterestedJobCategoryError(t("category_error"));
    // }
    if (e.target.name === "email") {
      setEmailError(null);
      if (e.target.value === "") {
        setEmailError(t("email_error"));
      } else if (!validEmailRegex.test(e.target.value)) {
        setEmailError(t("valid_email"));
      }
    } else if (e.target.name === "password") {
      setPasswordError(null);
      if (e.target.value === "") {
        setPasswordError(t("password_error"));
      } else if (!vaildPasswordRegex.test(e.target.value)) {
        setPasswordError(t("valid_password"));
      }
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setErrorResponse(null);
    setErrorJobResponse(null);
    setErrorTokenResponse(null);
    setSuccessResponse(null);
    setDummyDob(null);
    let categor = "";
    for (const iterator of selectedJobSubCategory) {
      categor =
        categor === "" ? categor + iterator : categor + " , " + iterator;
      console.log(categor);
    }
    // console.log(datePickerValue);
    // if(datePickerValue !== null && datePickerValue !== undefined && datePickerValue != "Invalid date"){
    //   user.dateOfBirth = moment(datePickerValue).format("YYYY-MM-DD");
    // }
   
    // console.log(user.dateOfBirth);
    user.interestedJobCategory = categor;
    setUser({
      ...user,
      // ["dateOfBirth"]: moment(datePickerValue).format("YYYY-MM-DD"),
      ["interestedJobCategory"]: categor,
    });
    console.log(dateOfBirthError);
    validationError(categor);
    console.log(count);
    console.log(user);
    if(!phoneValidation.test(user.telphone)){
      return;
    }
    // if(user.dateOfBirth === "Invalid date"){
    //   return;
    // }
    if (
      user.fullName !== "" &&
      // user.dateOfBirth !== "" &&
      user.email !== "" &&
      user.lastName !== "" &&
      user.telphone !== "" &&
      // user.prefature !== "" &&
      user.password !== "" &&
      // user.city !== "" &&
      user.occupation !== "" &&
      vaildPasswordRegex.test(user.password) &&
      validEmailRegex.test(user.email) &&
      !nameValidation.test(user.lastName) && 
      !nameValidation.test(user.fullName) && !(user.telphone.length < 8)
    ) {
   
      user.category = interestedCategory;
      // user.locationName = locationName;
      dispatch(actions.setuserDetails(user));
      localStorage.setItem("JOB_ID", jobId);
      localStorage.setItem("career_Id",career)
      history("/register-confirmation");
      // document.getElementById("conformationPopupButton").click();
    }
  };
  const validationError = (catego) => {
    setCount(0);
    setEmailError(null);
    setPasswordError(null);
    setFullNameError(null);
    setSkillError(null);
    setExperienceError(null);
    // if (dateOfBirthError !== "invalidDate") {
    //   setDateOfBirthError(null);
    // }

    setLocationError(null);
    setInterestedJobCategoryError(null);
    if (user.fullName === "") {
      setFullNameError(t("fullname_error"));
      setCount(1);
    }
    if (user.fullName !== "" && nameValidation.test(user.fullName)) {
      setFullNameError(t("valid_name"));
    }
    if (user.lastName === "") {
      setLastNameError(t("lastName_error"));
    }
    if (user.lastName !== "" && nameValidation.test(user.lastName)) {
      setLastNameError(t("valid_lastName"));
    }
    if (user.middleName !== "" && nameValidation.test(user.middleName)) {
      setMiddleNameError(t("middle_valid"));
    }
    // console.log(datePickerValue);
    // if (datePickerValue === null || dateOfBirthError === "invalidDate" || datePickerValue === "Invalid date") {
    //   setDateOfBirthError(t("dob_error"));
    //   setCount(1);
    // }
    if (user.telphone === "") {
      setTelphoneError(t("telephone_error"));
    }
    if (user.telphone !== "" &&
      !phoneValidation.test(user.telphone)
    ) {
      setTelphoneError(t("valid_telephone"));
    }
    if (user.telphone !== "" &&  user.telphone.length < 8
  ) {
    setTelphoneError(t("minlength_telephone"));
  }
    if (user.prefature === "") {
      setPrefatureError(t("prefature_error"));
    }
    if (user.city === "") {
      setCityError(t("city_error"));
    }
    // if (user.apartment === "") {
    //   setApartmentError(t("apartment_error"));
    // }
    if (user.occupation === "") {
      setOccupationError(t("occupation_error"));
    }
    // if (user.skill === "") {
    //   setSkillError(t("skill_error"));
    //   setCount(1);
    // }
    // if (user.experience === "") {
    //   setExperienceError(t("experience_error"));
    //   setCount(1);
    // }
    // if (
    //   user.experience !== "" &&
    //   !/^(\d{0,2})?([.]\d{1,1})?$/.test(user.experience)
    // ) {
    //   setExperienceError(t("experience_valid"));
    //   setCount(1);
    // }
    // if (user.location === "") {
    //   setLocationError(t("location_error"));
    //   setCount(1);
    // }
    // console.log(catego);
    // if (catego === "") {
    //   setInterestedJobCategoryError(t("category_error"));
    //   setCount(1);
    // }
    if (user.email === "") {
      setEmailError(t("email_error"));
      setCount(1);
    }
    if (user.email !== "" && !validEmailRegex.test(user.email)) {
      setEmailError(t("valid_email"));
      setCount(1);
    }
    if (user.password === "") {
      setPasswordError(t("password_error"));
      setCount(1);
    }
    if (user.password !== "" && !vaildPasswordRegex.test(user.password)) {
      setPasswordError(t("valid_password"));
      setCount(1);
    }

    console.log(count);
  };
  const getAllCategory = () => {
    axios.get("category").then(
      (response) => {
        console.log(response);
        setCategory(response.data.categoryList);
      },
      (error) => {}
    );
  };
  const getAllArea = () => {
    axios.get("category/area/").then(
      (response) => {
        console.log(response);
        setAreaList(response.data.areaList);
      },
      (error) => {}
    );
  };
  const handleOnChangeJobCategory = (event, jobSubCategory) => {
    console.log(jobSubCategory);
    if (event.target.checked) {
      console.log("checked");
      jobSubCategory.map((jobSubCategoryDetails) => {
        setSelectedJobSubCategory((selectedJobSubCategory) => [
          parseInt(jobSubCategoryDetails.subId),
          ...selectedJobSubCategory,
        ]);
      });
      setSelectedJobCategory((selectedJobCategory) => [
        parseInt(event.target.value),
        ...selectedJobCategory,
      ]);
      console.log(selectedJobCategory);
    } else if (!event.target.checked) {
      console.log("unchecked");
      jobSubCategory.map((jobSubCategoryDetails) => {
        setSelectedJobSubCategory((current) =>
          current.filter((item) => {
            return item !== parseInt(jobSubCategoryDetails.subId);
          })
        );
      });
      setSelectedJobCategory((current) =>
        current.filter((item) => {
          return item !== parseInt(event.target.value);
        })
      );
      console.log(selectedJobCategory);
    }
  };
  const handleOnChangeJobSubCategory = (event, subCategory, categoryId) => {
    if (event.target.checked) {
      console.log("checked");
      setSelectedJobSubCategory((selectedJobSubCategory) => [
        parseInt(event.target.value),
        ...selectedJobSubCategory,
      ]);
      console.log(selectedJobSubCategory);
      let flag;
      for (let i = 0; i < subCategory.length; i++) {
        if (
          document.getElementById("subCategory" + subCategory[i].subId)
            .checked === true
        ) {
          flag = 1;
          console.log(flag);
        } else {
          flag = 0;
          break;
        }
      }
      if (flag === 1) {
        setSelectedJobCategory((selectedJobCategory) => [
          parseInt(categoryId),
          ...selectedJobCategory,
        ]);
      }
    } else if (!event.target.checked) {
      console.log("unchecked");
      setSelectedJobSubCategory((current) =>
        current.filter((item) => {
          return item !== parseInt(event.target.value);
        })
      );
      setSelectedJobCategory((current) =>
        current.filter((item) => {
          return item !== parseInt(categoryId);
        })
      );
    }
  };
  function clearCategory() {
    let categorytempLIst = [];
    let categorytempSubLIst = [];
    if (tempList.length !== 0) {
      if (tempList.length > selectedJobSubCategory.length) {
        setSelectedJobSubCategory(tempList);
        setSelectedJobCategory(tempCatList);
      } else {
        for (const iterator of selectedJobSubCategory) {
          let tempData = tempList.find((element) => element === iterator);
          categorytempSubLIst.push(tempData);
        }
        setSelectedJobSubCategory(categorytempSubLIst);
        for (const iterator of tempCatList) {
          let tempData1 = selectedJobCategory.find(
            (element) => element === iterator
          );
          categorytempLIst.push(tempData1);
        }
        setSelectedJobCategory(categorytempLIst);
      }
    } else {
      setSelectedJobCategory([]);
      setSelectedJobSubCategory([]);
    }
  }
  const categorySubmit = () => {
    if (setSelectedJobSubCategory.length !== 0) {
      setTempList(selectedJobSubCategory);
      setTemCatpList(selectedJobCategory);
      setInterestedJobCategoryError(null);
      setIntergetestCategory();
    }
  };
  // function onError(e) {
  //   console.log(e);
  //   if (e === "maxDate") {
  //     setDateOfBirthError(t("invalid_date"));
  //   } else if (e === "invalidDate") {
  //     setDateOfBirthError(t("invalid_date"));
  //   } else {
  //     setDateOfBirthError(null);
  //   }
  // }
  function setIntergetestCategory() {
    let val = "";
    for (const iterator of category) {
      if (
        iterator?.subCategoryList !== undefined &&
        iterator?.subCategoryList !== undefined &&
        iterator?.subCategoryList.length !== 0
      ) {
        for (const resp of iterator.subCategoryList) {
          console.log(resp);
          let tempdata = selectedJobSubCategory.find(
            (element) => resp.subId === element
          );
          if (tempdata !== undefined) {
            if (val === "") {
              val = val + resp.subCategoryTitle;
            } else {
              val = val + " , " + resp.subCategoryTitle;
            }
          }
        }
      }
    }
    setInterestedCategory(val);
    console.log(interestedCategory);
    console.log(val);
    console.log(category);
    console.log(selectedJobSubCategory);
  }
  // function onDateUpdate(newValue) {
  //   if (newValue !== "") {
  //     setDateOfBirthError(null);
  //     setDatePickerValue(new Date(newValue));
  //   }
  // }
  function onLocation(event, prefature) {
    if (event.target.checked) {
      setDummyLocation(prefature);
      setCheckedLocation(prefature.prefectureId);
      //console.log(dummyLocation)
      console.log(checkedLocation);
    } else {
    }
  }
  function clearLocation() {
    setCheckedLocation(user.location);
    setDummyLocation("");
  }
  function submitLocation() {
    if (dummyLocation !== "") {
      user.location = dummyLocation.prefectureId;
      setLocationName(dummyLocation.prefectureTitle);
      setLocationError(null);
    }
  }
  function resend() {
    setOpen(true);
    setErrorJobResponse(null);
    setErrorResponse(null);
    setErrorTokenResponse(null);
    setSuccessResponse(null);
    let resendData = { email: user.email };
    axios.put("users/resend/mail", resendData).then(
      (response) => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        console.log(response);
        setOpen(false);
        // setSuccessResponse(t("mail_resend"))
        localStorage.setItem("mail_resend", t("mail_resend"));
        history("/registration-success");
      },
      (error) => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setOpen(false);
        if (error?.response?.data?.errorCode === "21401") {
          setErrorTokenResponse(t("user_active"));
        } else {
          setErrorTokenResponse(t("error_resend"));
        }
      }
    );
  }
  function applyJob() {
    setOpen(true);
    setErrorJobResponse(null);
    setErrorResponse(null);
    setErrorTokenResponse(null);
    setSuccessResponse(null);
    const job = { jobId: parseInt(jobId) };
    axios
      .post("/job/user/apply/" + localStorage.getItem("usertemId"), job)
      .then(
        (response) => {
          console.log(response);
          if (response.status === 200) {
            window.dataLayer.push({
              event: "Job Apply",
              user: localStorage.getItem("usertemId"),
            });
            history("/jobapply-success");
          }
        },
        (error) => {
          if (
            error.response?.data.errorCode === 26201 ||
            error.response?.data.errorCode === "26201"
          ) {
            setErrorJobResponse(t("job_not_found"));
            document.getElementById("successPopUpButton").click();
          } else if (
            error.response?.data.errorCode === "22202" ||
            error.response?.data.errorCode === "1009"
          ) {
            setErrorJobResponse(t("invalid_token"));
            document.getElementById("successPopUpButton").click();
          } else if (error.response?.data.errorCode === "21115") {
            setErrorJobResponse(t("job_user_unpublished"));
            document.getElementById("successPopUpButton").click();
          } else if (error.response?.data.errorCode === "21114") {
            setErrorJobResponse(t("job_user_deleted"));
            document.getElementById("successPopUpButton").click();
          }else if(error.response?.data.errorCode === "26301" ){
          
            history("/job-apply-error");
          }
          setTimeout(() => {
            setOpen(false);
          }, 1000);
        
        }
      );
  }
  function handleOnClickPopUp() {
    localStorage.removeItem("JOB_ID");
    document.getElementById("successPopUpCloseButton").click();

    history("/job-list/1");

    window.location.reload();
  }
  function confirm() {
    user.experience = parseFloat(user.experience);
    user.location = JSON.stringify(user.location);
    setOpen(true);
    axios.post("users", user).then(
      (response) => {
        localStorage.setItem("usertemId", response.data.user_id);
        setOpen(false);

        window.dataLayer.push({
          event: "User Registration",
        });

        setTimeout(() => {
          if (jobId !== null) {
            applyJob();
          } else {
            history("/registration-success");
          }
        }, 1000);
        console.log(response);
      },
      (error) => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        if (error?.response?.data?.errorCode === "21111") {
          setErrorResponse(t("email_already_exist"));
        } else if (error?.response?.data?.errorCode === "21115") {
          setErrorResponse(t("suspened_user"));
        } else if (error?.response?.data?.errorCode === "21114") {
          setErrorResponse(t("user_deleted"));
        } else if (error?.response?.data?.errorCode === "21119") {
          setOpen(false);
          document.getElementById("resendpopupButton").click();
        } else {
          setErrorResponse(t("user_failed"));
        }

        setTimeout(() => {
          setOpen(false);
        }, 1000);
      }
    );
  }
  function onFileSelect(e) {
    setErrorResponse(null);
    setErrorJobResponse(null);
    setErrorTokenResponse(null);
    setSuccessResponse(null);
    if (e.target.files.length > 0) {
      let image = e.target.files[0];
      if (image.size <= "10485760") {
        console.log(e.target.files[0]);
        const formData = new FormData();
        formData.append("uploadfile", image);
        setOpen(true);
        axios
          .post("file/upload", formData)
          .then((response) => {
            console.log(response);

            let filePath = response?.data?.filepath.split("/");
            if (filePath !== undefined && filePath !== null) {
              setImage(filePath[filePath.length - 1]);
            }
            console.log(filePath[filePath.length - 1]);
            user.file = filePath[filePath.length - 1];
            user.resume = response?.data?.location;
            setResume(response?.data?.location)
            setLogoError(null);
            setOpen(false);
          })
          .catch((error) => {
            setOpen(false);
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            if(error?.response?.data?.errorCode === "2004"){

              setErrorResponse(t("invalid_file"));
            }else {
              setErrorResponse(t("not_responding"));
            }
          });
        e.target.value = "";
      } else {
        setLogoError(t("file_format_size_validation"));
      }
    } else {
    }
  }
  function getUserDetails() {
    console.log(userBody);
    if (
      userBody !== undefined &&
      userBody !== null &&
      Object.keys(userBody).length === 0 &&
      userBody.email !== undefined &&
      userBody.email !== null
    ) {
      user.experience = parseFloat(userBody.experience);
      user.location = JSON.stringify(userBody.location);
      // user.dateOfBirth = user.dateOfBirth;
      user.email = userBody.email;
      user.fullName = userBody.fullName;
      user.interestedJobCategory = userBody.interestedJobCategory;
      user.location = userBody.location;
      user.password = userBody.password;
      user.skill = userBody.skill;
      // console.log(userBody.dateOfBirth)
      // if(userBody.dateOfBirth !== undefined && userBody.dateOfBirth !== null && userBody.dateOfBirth !== "Invalid date"){
      //   setDatePickerValue(new Date(userBody.dateOfBirth));
      // }
      setInterestedCategory(userBody.category);
      setLocationName(userBody.locationName);
      setSelectedJobSubCategory(userBody.interestedJobCategory);
      setCheckedLocation(userBody.location);
      user.file = userBody.file;
      user.resume = userBody.resume;
      user.middleName = userBody.middleName;
      user.lastName = userBody.lastName;
      user.telphone = userBody.telphone;
      user.prefature = userBody.prefature;
      user.city = userBody.city;
      user.apartment = userBody.apartment;
      user.occupation = userBody.occupation;
      setImage(userBody.file);
      setResume(userBody.resume)
    }
  }
  function fileOPen(e){
    e.preventDefault();
    document.getElementById('myFileJInput').click()
  }
  function deleteFile() {
    setImage("");
    setLogoError(null);
    setResume(null)
    user.file = "";
    user.resume = "";
  }

  return (
    <>
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js"></script>
      <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
      <div className="banner-login middle-toparea">
        <div className="container-fluid padding-body">
          <div className="sub-menus">
            <Link className="topI" to={"/"}>
              {t("top")}
            </Link>{" "}
            / <span>{t("user_register")}</span>{" "}
          </div>

          <div className="login-header">
            <h3>{t("user_register")}</h3>
          </div>
        </div>
      </div>
      .
      <div className="container my-5">
        <div className="card mt-3 Card-box">
          <div className="validation">{errorResponse}</div>
          <div className="validation">{errorTokenResponse}</div>
          <div className="validation-success">{successResponse}</div>
          <div className="card-body">
            <form onSubmit={(e) => onSubmit(e)}>
              <div class="row">
              <div className='confirm_head'>
              <p className='font-18-bold '> {t("full_name")} </p>
               </div>
                <div class="col-md-4">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">
                    {t("last_name")}
                    </label>
                    <input
                      type="text"
                      id="name"
                      aria-describedby="emailHelp"
                      maxLength={50}
                      name="lastName"
                      value={lastName}
                      onChange={(e) => onInputChange(e)}
                      className={`form-control custom-input custom-input-changeJa ${
                        lastNameError ? "is-invalid" : ""
                      }`}
                    />
                     {lastNameError ? (
                      <div className="invalid-feedback ">{lastNameError}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
               
                <div class="col-md-4">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">
                    {t("middle_name")}
                    </label>
                    <input
                      type="text"
                      id="name"
                      aria-describedby="emailHelp"
                      maxLength={50}
                      name="middleName"
                      value={middleName}
                      onChange={(e) => onInputChange(e)}
                      className={`form-control custom-input custom-input-changeJa ${
                        middleNameError ? "is-invalid" : ""
                      }`}
                    />
                     {middleNameError ? (
                      <div className="invalid-feedback ">{middleNameError}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">
                    {t("first_name")}
                    </label>
                    <input
                      type="text"
                      id="name"
                      aria-describedby="emailHelp"
                      maxLength={50}
                      name="fullName"
                      value={fullName}
                      onChange={(e) => onInputChange(e)}
                      className={`form-control custom-input custom-input-changeJa ${
                        fullNameError ? "is-invalid" : ""
                      }`}
                    />
                    {fullNameError ? (
                      <div className="invalid-feedback ">{fullNameError}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">
                    {t("tel_no")}
                    </label>
                    <input
                      type="text"
                      id="name"
                      aria-describedby="emailHelp"
                      maxLength={15}
                      name="telphone"
                      value={telphone}
                      onChange={(e) => onInputChange(e)}
                      className={`form-control custom-input custom-input-changeJa ${
                        telphoneError ? "is-invalid" : ""
                      }`}
                    />
                     {telphoneError ? (
                      <div className="invalid-feedback ">{telphoneError}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="emailHelp" class="form-label input-label">
                    {t("email_id")}
                    </label>
                    <input
                      type="text"
                      id="name"
                      aria-describedby="emailHelp"
                      maxLength={50}
                      name="email"
                      value={email}
                      onChange={(e) => onInputChange(e)}
                      className={`form-control custom-input custom-input-changeJa ${
                        emailError ? "is-invalid" : ""
                      }`}
                    />
                    {emailError ? (
                      <div className="invalid-feedback ">{emailError}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/* <div class="col-md-4">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">
                      {t("dob")}
                    </label>
                    <div class="dob-inp">
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={locale}
                      > */}
                        {/* <DesktopDatePicker
                          open={dateOpen}
                          onClose={() => setDateOpen(false)}
                          value={datePickerValue}
                          maxDate={maxDate}
                          className="form-control custom-input"
                          inputFormat="YYYY-MM-DD"
                          onError={(e) => onError(e)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onClick={() => setDateOpen(true)}
                              inputProps={{
                                ...params.inputProps,
                                readOnly: true,
                              }}
                            />
                          )}
                          onChange={(newValue) =>
                            setDatePickerValue(new Date(newValue))
                          }
                          onAccept={(newValue) => onDateUpdate(newValue)}
                        /> */}
                        {/* {dateOfBirthError ? (
                          <div
                            className="invalid-feedback "
                            style={{ display: "block" }}
                          >
                            {dateOfBirthError}
                          </div>
                        ) : (
                          ""
                        )} */}
                      {/* </LocalizationProvider>
                    </div>
                  </div>
                </div> */}
              </div>
              {/* <div class="row"> */}
                {/* <div>
                  <p>
                  
                    <strong>{t("current_aadr")}</strong> </p>
                    <p class="address-sub">
                    {t("current_aadr_mandatory")}
                  
                    </p>
                  
                </div> */}
                {/* <div className='confirm_head'>
              <p className='font-18-bold'>{t("current_aadr")}  </p>
              </div> */}
              {/* <p class="address-sub">
                    {t("current_aadr_mandatory")} */}
                    {/* 都道府県・市区町村・番地・マンション名・部屋番号までのご入力をお願い致します。 */}
                    {/* </p> */}
           
                {/* <div class="col-md-4">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">
                    {t("prefeature")}
                    </label>
                    <input
                      type="text"
                      id="name"
                      aria-describedby="emailHelp"
                      maxLength={50}
                      name="prefature"
                      value={prefature}
                      onChange={(e) => onInputChange(e)}
                      className={`form-control custom-input custom-input-changeJa ${
                        prefatureError ? "is-invalid" : ""
                      }`}
                    />
                     {prefatureError ? (
                      <div className="invalid-feedback ">{prefatureError}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div> */}
                {/* <div class="col-md-4">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">
                    {t("city")}
                    </label>
                    <input
                      type="text"
                      id="name"
                      aria-describedby="emailHelp"
                      maxLength={50}
                      name="city"
                      value={city}
                      onChange={(e) => onInputChange(e)}
                      className={`form-control custom-input custom-input-changeJa ${
                        cityError ? "is-invalid" : ""
                      }`}
                    />
                     {cityError ? (
                      <div className="invalid-feedback ">{cityError}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div> */}
                {/* <div class="col-md-4">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">
                    {t("apartment_name")}
                    </label>
                    <input
                      type="text"
                      id="name"
                      aria-describedby="emailHelp"
                      maxLength={50}
                      name="apartment"
                      value={apartment}
                      onChange={(e) => onInputChange(e)}
                      className={`form-control custom-input custom-input-changeJa ${
                        apartmentError ? "is-invalid" : ""
                      }`}
                    />
                     {apartmentError ? (
                      <div className="invalid-feedback ">{apartmentError}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div> */}

              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="name" class="form-label input-label">
                    {t("desied_occupation")}
                    </label>
                    <input
                      type="text"
                      id="name"
                      aria-describedby="emailHelp"
                      maxLength={50}
                      name="occupation"
                      value={occupation}
                      onChange={(e) => onInputChange(e)}
                      className={`form-control custom-input custom-input-changeJa ${
                        occupationError ? "is-invalid" : ""
                      }`}
                    />
                     {occupationError ? (
                      <div className="invalid-feedback ">{occupationError}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                   
                   <label for="name" class="form-label input-label">
                    {t("password")}
                    </label>
                    <div className="dob-inp">
                      <input
                        type={isRevealPwd ? "text" : "password"}
                        id="exampleInputPassword1"
                        name="password"
                        value={password}
                        onChange={(e) => onInputChange(e)}
                        className={`form-control custom-input custom-input-changeJa  ${
                          passwordError ? "is-invalid password-errorshow" : ""
                        }`}
                      />
                        {passwordError ? (
                      <div className="invalid-feedback ">{passwordError}</div>
                    ) : (
                      ""
                    )}
                      <span
                        className="calender-icon"
                        onClick={() =>
                          setIsRevealPwd((prevState) => !prevState)
                        }
                      >
                        {" "}
                        <i
                          className={
                            isRevealPwd
                              ? "fa-solid fa-eye"
                              : "fa-solid fa-eye-slash"
                          }
                        ></i>
                      </span>
                    </div>

                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                   
                  <label for="name" class="form-label input-label">
                      {t('resume_head')}  <span style={{ paddingLeft: '7px' }}>{t('resume_optional')}</span>
                    </label>
                   
                    {image ? (
                         <div className="col-md-7 mb-3">
                        
                         <div className="position-relative">
                       
                        {image ? (
                         <>
                         <span > <a  href={resume} target="_blank">
                         {t("view_resume")}
                          </a></span>
                           <span className="deleteIcon position-relative" onClick={deleteFile}>
                             <i className="fa fa-times" aria-hidden="true"></i>
                           </span>
                           </>
                         ) : (
                           ""
                         )}
                         </div>
                       
                        </div>
                        ) : (
                         <> 
                        
                          <div class="dob-inp">
                          
                      <input
                        class="form-control custom-input"
                        type="button" name="myfile"
                        onClick={e => fileOPen(e)} className={`form-control custom-input custom-input-changeJa ${
                          logoError ? "is-invalid password-errorshow" : ""
                        }`} 
                      /> 
                      {logoError ? (
                      <div className="invalid-feedback ">{logoError}</div>
                    ) : (
                      ""
                    )}
                       <input
                             type="file" id="myFileJInput" style={{"display":"none"}}
                                accept=".pdf , .docx" name="myFileJInput"
                                 onChange={(e) => onFileSelect(e)} />
                      <span class="calender-icon">
                        <i class="fa-solid fa-arrow-up-from-bracket"></i>
                      </span>
                      
                    </div>
                  
                   </>
                        )}

              



                  </div>
                </div>
                <div class="col-md-6">
                  
                </div>
              </div>
              <div className="text-center">
                <button type="submit" className="main-buttons">
                  {t("submit")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* category popup */}
      <div
        className="modal fade"
        id="jobcategory"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header border-none">
              <div className="col-md-12 text-center mt-60">
                <div className="main-heading hot-jobhead">
                  <h4>{t("selected_category")}</h4>
                </div>
              </div>
              <button
                type="button"
                className="Close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={clearCategory}
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="search-area">
                {category?.map((row, index) => (
                  <div>
                    <div className="row">
                      {row.subCategoryList.length > 0 ? (
                        <div className="popup-head mt-3 mb-3 d-flex">
                          <h6>{row?.categoryTitle}</h6>
                        </div>
                      ) : null}
                    </div>
                    <div>
                      {row?.subCategoryList?.map((sub, index) => (
                        <>
                          <div className="form-check form-check-inline mb-2">
                            <input
                              className="form-check-input form-chck"
                              type="checkbox"
                              onChange={(e) =>
                                handleOnChangeJobSubCategory(
                                  e,
                                  row?.subCategoryList,
                                  row?.id
                                )
                              }
                              id={"subCategory" + sub?.subId}
                              value={sub?.subId}
                              checked={selectedJobSubCategory.includes(
                                sub?.subId
                              )}
                            />
                            {/* <input className="form-check-input form-chck" type="checkbox" id="inlineCheckbox1" value={sub.checked} onChange={e=>onSubCategoryChange(sub,row)} /> */}
                            <label
                              className="form-check-label ps-2"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title={sub?.subCategoryTitle}
                              for="inlineCheckbox1"
                            >
                              {sub?.subCategoryTitle}
                            </label>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="modal-footer border-none ">
              <div className="col-md-12 text-center text-col12">
                <button
                  className="cancel-btn"
                  data-bs-dismiss="modal"
                  onClick={clearCategory}
                >
                  {t("cancel")}
                </button>
                <button
                  className="main-buttons sub-btn"
                  data-bs-dismiss="modal"
                  onClick={categorySubmit}
                >
                  {t("add")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* location popup */}
      <div
        className="modal fade"
        id="joblocation"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header border-none">
              <div className="col-md-12 text-center mt-60">
                <div className="main-heading hot-jobhead">
                  <h4>{t("selected_location")}</h4>
                </div>
              </div>
              <button
                type="button"
                className="Close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={clearLocation}
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="search-area">
                {areaList?.map((row, index) => (
                  <div>
                    <div className="row">
                      {row.prefectures.length > 0 ? (
                        <div className="popup-head mt-3 mb-3 d-flex">
                          <h6>{row?.areaTitle}</h6>
                        </div>
                      ) : null}
                    </div>
                    <div>
                      {row?.prefectures?.map((sub, index) => (
                        <>
                          <div className="form-check form-check-inline mb-2">
                            <input
                              className="form-check-input form-chck"
                              type="radio"
                              name="radioLOc"
                              id={sub?.prefectureId}
                              value={sub?.prefectureId}
                              onChange={(e) => onLocation(e, sub)}
                              checked={checkedLocation == sub.prefectureId}
                            />
                            {/* <input className="form-check-input form-chck" type="checkbox" id="inlineCheckbox1" value={sub.checked} onChange={e=>onSubCategoryChange(sub,row)} /> */}
                            <label
                              className="form-check-label ps-2"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title={sub?.prefectureTitle}
                              for="inlineCheckbox1"
                            >
                              {sub?.prefectureTitle}
                            </label>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="modal-footer border-none ">
              <div className="col-md-12 text-center text-col12">
                <button
                  className="cancel-btn"
                  data-bs-dismiss="modal"
                  onClick={clearLocation}
                >
                  {t("cancel")}
                </button>
                <button
                  className="main-buttons sub-btn"
                  data-bs-dismiss="modal"
                  onClick={submitLocation}
                >
                  {t("add")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer
        position="bottom-right"
        autoClose={800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
      {/* <a
        href=""
        className="edit-icon"
        data-bs-toggle="modal"
        id="resendpopupButton"
        data-bs-target="#resendpopup"
        hidden
      ></a>
      <div
        className="modal fade"
        id="resendpopup"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body px-5 py-3">
              <div className="row mb-2 mt-5 px-3">
                <div className="col-md-12 text-center">
                  <i className="fa-solid fa-circle-info info-pop"></i>
                </div>
                <div className="text-center mt-4 mb-4">
                  <h5> {t("resend_heading")}</h5>
                </div>

                <div className="text-center">
                  <p>{t("resend_description")}</p>
                </div>
              </div>
              <div className="col-md-12 text-center mb-3">
                <button className="cancel-btn" data-bs-dismiss="modal">
                  {" "}
                  {t("cancel")}
                </button>
                <button
                  className="main-buttons sub-btn"
                  data-bs-dismiss="modal"
                  onClick={resend}
                >
                  {" "}
                  {t("resend")}
                </button>
              </div>
            </div>
          
          </div>
        </div>
      </div>
      <a
        href=""
        className="edit-icon"
        data-bs-toggle="modal"
        id="successPopUpButton"
        data-bs-target="#successPopup"
        hidden
      ></a>
      <div
        className="modal fade"
        id="successPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-confirm ">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <div className="icon-box">
                <i
                  className="fa fa-exclamation-triangle"
                  aria-hidden="true"
                ></i>
              </div>

              <Link to="/job-list/1">
                <button
                  type="button"
                  id="successPopUpCloseButton"
                  className="Close-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ color: "white" }}
                >
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </Link>
            </div>
            <div className="modal-body text-center">
              <p>{errorJobResponse}</p>
              <div>
                <button
                  className="btn btn-success"
                  data-dismiss="modal"
                  onClick={handleOnClickPopUp}
                >
                  <span>{t("ok")}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <a
        href=""
        className="edit-icon"
        data-bs-toggle="modal"
        id="conformationPopupButton"
        data-bs-target="#conformationPopup"
        hidden
      ></a>
      <div
        className="modal fade"
        id="conformationPopup"
        tabindex="-1"
        aria-labelledby="exampleConformationModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content position-modal">
            <div className="modal-body p-0">
              <div className="row mb-2 mt-5 px-5 py-3">
                <h5 className="pb-5">{t("confiramation_heading")}</h5>

                <div className="row ">
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label for="name" className="form-label input-label">
                        {t("full_name")}{" "}
                      </label>
                      <div>
                        <label for="name">{fullName}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label for="name" className="form-label input-label">
                        {t("dob")}
                      </label>
                      <div>
                        <label for="name">{dummyDob}</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label for="name" className="form-label input-label">
                        {t("skills")}
                      </label>
                      <div className="dob-inp">
                        <label for="name">{skill}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label for="name" className="form-label input-label">
                        {t("experience")}
                      </label>
                      <div>
                        <label for="name">{experience}</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label for="name" className="form-label input-label">
                        {t("interesed_job_categories")}
                      </label>
                      <div className="dob-inp ">
                        <label for="name">{interestedCategory}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label for="name" className="form-label input-label">
                        {t("location")}
                      </label>
                      <div>
                        <label for="name">{locationName}</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label for="name" className="form-label input-label">
                        {t("email_id")}
                      </label>
                      <div>
                        <label for="name">{email}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-4">
                      <label
                        for="exampleInputPassword1"
                        className="form-label input-label"
                      >
                        {t("password")}
                      </label>
                      <div>
                        <label for="name">{password}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ backgroundColor: "#f3f1f1" }}>
                <div className="row px-5 py-3">
                  <div className="form-check form-check-inline mb-2">
                    <input
                      className="form-check-input form-chck"
                      type="checkbox"
                      id="inlineCheckbox1"
                      value="option1"
                    />
                    <label
                      className="check-label-form ps-2"
                      for="inlineCheckbox1"
                    >
                      {t("handling_personal_info")}
                    </label>
                  </div>
                  <div className="form-check form-check-inline mb-2">
                    <input
                      className="form-check-input form-chck"
                      type="checkbox"
                      id="inlineCheckbox2"
                      value="option2"
                    />
                    <label
                      className="check-label-form ps-2"
                      for="inlineCheckbox2"
                    >
                      {t("privacy_policy_info")}
                    </label>
                  </div>
                  <div className="form-check form-check-inline mb-2">
                    <input
                      className="form-check-input form-chck"
                      type="checkbox"
                      id="inlineCheckbox3"
                      value="option3"
                    />
                    <label
                      className="check-label-form ps-2"
                      for="inlineCheckbox3"
                    >
                      {t("social_force_info")}
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-center mb-3">
                <button className="cancel-btn" data-bs-dismiss="modal">
                  {" "}
                  {t("cancel")}
                </button>
                <button className="main-buttons sub-btn">
                  {" "}
                  {t("confirm")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
export default Registration;
