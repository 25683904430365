import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation } from 'react-router-dom';
import i18n from '../../i18n';
import logo from '../../Images/dcareer.svg'
import Avatar from 'react-avatar';
import useAppContext from "../../AppContext";
import { useDispatch } from "react-redux";
import { actions } from "../../redux/companyReducer";
import "bootstrap/dist/css/bootstrap.min.css";
import "@popperjs/core";
import "bootstrap";
import Footer from "../footer/footer";

// import ReactGA from 'react-ga';
// const TRACKING_ID = "UA-252930977-1"; // OUR_TRACKING_ID
// ReactGA.initialize(TRACKING_ID); 
window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  let mybutton = document.getElementById("myBtn");
  if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
    mybutton.style.display = "block";
  } else {
    mybutton.style.display = "none";
  }
}

function topFunction() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}
const Home = () => {
  const appContext = useAppContext();
  const location = useLocation();
  const { t } = useTranslation();
  const [language, setLanguage] = useState();
  const [isLogin, setIsLogin] = useState();
  const [isShow, setIsShow] = useState(true);
  const dispatch = useDispatch();
  // let isLogin = localStorage.getItem("userId")
  let username = localStorage.getItem("userName");
  useEffect(() => {
    setInterval(() => {
     setIsLogin(localStorage.getItem("userId"));
    }, 500);
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    if (localStorage.getItem("LANGUAGE")) {
      setLanguage(localStorage.getItem("LANGUAGE"))
    }
    else {
      setLanguage("ja");
      i18n.changeLanguage("ja");
      setLanguage(localStorage.setItem("LANGUAGE","ja"))
    }
    if ((window.location.pathname === "/login" || window.location.pathname === "/registration" || window.location.pathname === "/job-apply" ||    
      window.location.pathname === "/forgot-password") && appContext.isLoggedin()) {
      window.location.replace("/")
    }
    if (window.location.pathname === "/") {
     //  window.location.replace("/start-page")
    }
    if(localStorage.getItem("JOB_ID") && (window.location.pathname !== "/login" && window.location.pathname !== "/registration")){
      localStorage.removeItem("JOB_ID");
    }
    if(window.location.pathname === "/career-confirmation" && !appContext.isLoggedin()){
      window.location.replace("/")
    }
   
    if ((window.location.pathname !== "/register-confirmation" && window.location.pathname !== "/registration")) {
      dispatch(actions.setuserDetails({}));
    }
   
  }, []);
  function clickLogo(){
    window.scrollTo(0, 0);
  }
  const logout = (e) => {
    e.preventDefault();
    window.dataLayer.push({
      event:"Logout"
    });
    localStorage.removeItem("accessToken");
    localStorage.removeItem("id");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userId");
    window.location.reload();
  } 

  console.log(language)
  function closeNavBar(){
    document.getElementById("myNavBar").click();
  }
  useEffect(() => {
    console.log(location)
    // ReactGA.pageview(location.pathname + location.search);
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: location
      }
    });
    }, [location]);
   function onCompany(){
      setIsShow(false)
      dispatch(actions.setSearchCompanyfield(''));
      dispatch(actions.setSearchCompanyIndustryfield(''));
    }
  return (
    <body className="d-flex flex-column h-100">
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
      <header>
        <nav className="navbar navbar-expand-lg navbar-light  main-header">
          <div className="container-fluid padding-body">
            <Link to={"/"}>
              <img src={logo} alt="" className="img-logo" onClick={clickLogo} />
            </Link>
            <button className="navbar-toggler border-none" type="button" id="myNavBar"
             data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
              <i className="fa-solid fa-bars-staggered"></i>
            </button>
            <div className=" justify-content-end display-none" id="navbarScroll">
              <ul className="nav nav-bg">
                <li className="nav-item  dropdown ">
                  <a className="nav-link nav-menu dropdown-toggle " href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
                   aria-expanded="false" onClick={()=>setIsShow(true)}>
                    {t('job_search')}
                  </a>
                  <ul className="dropdown-menu drop-pop" aria-labelledby="navbarDropdown" id="myDropDown" style={ !isShow ? { display:'none'} : {}}>
                    <li><a className="dropdown-item nav-menu border-bottom" href="/job-search" onClick={()=>setIsShow(false)}>{t('job_search')} </a></li>
                    <li><a className="dropdown-item nav-menu" href="/company-list/1" onClick={()=>onCompany()}>{t('company_search')}</a></li>

                  </ul>
                </li>
                <li className="nav-item ">
                  <a className="nav-link nav-menu" href="/service-details">{t('service_details')}</a>
                </li>
                <li className="nav-item ms-4">
                  {isLogin ?
                    <li className="nav-item  dropdown" >
                      <div className="nav-link dropdown-toggle user-name" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ "color": "#656565" }}>
                        {/* <Avatar name={username} color="#00CC99" size="50" round={true} /> */}
                        {username?.length <= 15 && <span>{username}</span>}
                        {username?.length > 15 && <span>{username.slice(0,15)+"..."}</span>}
                      </div>
                      <ul className="dropdown-menu drop-pop" aria-labelledby="navbarDropdown">
                        <li><a className="dropdown-item nav-menu" style={{ "cursor": "pointer" }} onClick={logout}>{t('logout')}&nbsp; &nbsp;
                          <i class='fas fa-sign-out-alt'></i></a></li>
                      </ul>
                    </li>
                    : <Link to={"/login"}>
                      <button className="login-btn"> <i className="fa-solid fa-arrow-right-to-bracket login-icon pe-2" ></i> {t('login')}</button>
                    </Link>}

                </li>
                <li className="nav-item  ms-4">
                { !isLogin&& <Link to={"/job-apply"}>
                    <button className="counseling-btn">{t('click_here_for_career')}</button>
                  </Link> }
                  {isLogin&& <Link to={"/career-confirmation"}>
                    <button className="counseling-btn">{t('click_here_for_career')}</button>
                  </Link> }
                </li>
              </ul>

            </div>
          </div>
        </nav>

        <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
          <div className="offcanvas-header">
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body">
            <ul className="nav ">
              <li className="nav-item nav-w ">
                {isLogin ?
                  // <Avatar style={{ "paddingTop": "2px", "marginBottom": "10px", "marginLeft": "auto", "marginRight": "auto", "display": "block" }} name="Rami" color="#00CC99" size="100" round={true} />
                  <>
                  {username?.length <= 20 && <a className="nav-link nav-menu" style={{"fontSize":"25px","fontWeight":"bold"}}>{username}</a>}
                  {username?.length > 20 && <a className="nav-link nav-menu" style={{"fontSize":"25px","fontWeight":"bold"}}>{username.slice(0,20)+"..."}</a>}
                  </>
                 : null}
              </li>
              <li className="nav-item nav-w">
                <a className="nav-link nav-menu" href="/job-search" onClick={closeNavBar}>{t('job_search')}</a>
              </li>
              <li className="nav-item nav-w">
                <a className="nav-link nav-menu" href="/company-list/1" onClick={closeNavBar}>{t('company_search')}</a>
              </li>
              <li className="nav-item nav-w">
                <a className="nav-link nav-menu" href="/service-details" onClick={closeNavBar}>{t('service_details')}</a>
              </li>
              <li className="nav-item nav-w" onClick={closeNavBar}>
                {isLogin ? <a className="nav-link nav-menu" href="/" onClick={logout}>{t('logout')} &nbsp; &nbsp;<i class='fas fa-sign-out-alt'></i></a> : null}
              </li>
              <li className="nav-item nav-w  ms-4" onClick={closeNavBar}>
                {isLogin ?
                  null
                  : <a className="btn login-btn" href="/login"> <div style={{ "marginTop": "8px" }}><i className="fa-solid fa-arrow-right-to-bracket login-icon pe-2" ></i> {t('login')}</div></a>}
              </li>
              <li className="nav-item nav-w ms-4">
              
                { !isLogin&& <Link to={"/job-apply"}>
                    <a className="btn counseling-btn"  onClick={closeNavBar}> <div style={{ "marginTop": "8px" }}>{t('click_here_for_career')}</div></a>
                  </Link> }
                  {isLogin&& <Link to={"/career-confirmation"}>
                  <a className="btn counseling-btn"  onClick={closeNavBar}> <div style={{ "marginTop": "8px" }}>{t('click_here_for_career')}</div></a>
                  </Link> }
              </li>
            </ul>
          </div>
        </div>
      </header>

      <button onClick={topFunction} id="myBtn" title="Go to top"> <i class="fa-solid fa-arrow-up-long"></i></button>
        <Outlet />
        <div class="clearfix"></div>
        <Footer />
    </body>
  )
};


export default Home;