import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import useAppContext from "../../AppContext";
import building from "../../Images/building.svg";
import industrySVG from "../../Images/suitcase.svg";
import locationSVG from "../../Images/Icon material-location-on.svg";
import commentLogo from "../../Images/comment-img.png";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import dateFormat from 'dateformat';
import { actions } from "../../redux/companyReducer";
import { useDispatch } from 'react-redux';
function Jobdetails() {
  let { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [job, setJob] = useState();
  const [relatedJobs, setRelatedJobs] = useState([]);
  const appContext = useAppContext();
  const axios = appContext.getAxios();
  const [response, setResponse] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [errorCode, setErrorCode] = useState();
  const [errorJobResponse,setErrorJobResponse] = useState(null);
  const [errorResponseMsg,setErrorResponseMsg] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
  
    dispatch(actions.setuserDetails({}));
    if (localStorage.getItem("JOB_ID")) {
      applyJob();
      localStorage.removeItem("JOB_ID");
    } else {
      getJobDetailsById();
    }
    setTimeout(() => {
      window.scrollTo({top: 0})
    }, 0);
  }, [id]);

  const getJobDetailsById = () => {
    setOpen(true);
    axios.get("job/details/" + id).then(
      (response) => {
        console.log(response);
        setJob(response?.data?.data);
        setRelatedJobs(response?.data?.relatedJobs);
        setOpen(false);
      },
      (error) => {
        setOpen(false);
        setResponse(false);
        setErrorMsg(true);
        setErrorCode(error.response?.data?.errorCode);
        setTimeout(() => {
          document.getElementById("successPopUpButton").click();
       }, 200);
        console.log(error?.response)
      }
    );
  };
  function handleOnClickApplyJob() {
    console.log("applied ");
    if (localStorage.getItem("accessToken")) {
      applyJob();
    } else {
      document.getElementById("jobApplyHiidenButton").click();
    }
  }
  function handleOnClickRegistration() {
    localStorage.setItem("JOB_ID", id);
    navigate("/registration");
  }
  function handleOnClickLogin() {
    localStorage.setItem("JOB_ID", id);
    navigate("/login");
  }
  function applyJob() {
    setErrorMsg(false);
    setErrorJobResponse(null)
    setErrorResponseMsg(null)
    const job = { jobId: parseInt(id) };
    axios.post("job/apply", job).then(
      (response) => {
        console.log(response);

        if (response.status === 200) {
          window.dataLayer.push({
            event:"Job Apply",
            user:localStorage.getItem("userId"),
            });
          setResponse(true);
          navigate("/jobapply-success");
          
        }
        setOpen(false);
      },
      (error) => {
        console.log(error);
        setErrorCode(error?.response?.data?.errorCode);
        if(error.response?.data.errorCode === 26201 || error.response?.data.errorCode === "26201"){
          setErrorJobResponse(t('job_not_found'))
          document.getElementById("successPopUpButton").click();
        }else if(error.response?.data.errorCode === "22202" || error.response?.data.errorCode === "1009"){
          localStorage.setItem("JOB_ID", id);
          localStorage.setItem("invalid_token",t('invalid_token'))
          setErrorJobResponse(t('invalid_token'))
          appContext.logout();
          navigate("/login");
        }else if(error.response?.data.errorCode === "21115" ){
       
          setErrorJobResponse(t('job_user_unpublished'))
          document.getElementById("successPopUpButton").click();
        }else if(error.response?.data.errorCode === "21114" ){
        
          setErrorJobResponse(t('job_user_deleted'))
          document.getElementById("successPopUpButton").click();
        }else if(error.response?.data.errorCode === "26301" ){
          navigate("/job-apply-error");
        }
     
        setOpen(false);
        setResponse(false);
     
       
      }
    );
  }
  function handleOnClickPopUp() {
   
    document.getElementById("successPopUpCloseButton").click();
    if(response === false && errorCode === "22202" || errorCode === "1009"  || errorCode === "21114" || errorCode === "21115"  ){
   
     appContext.logout();
     navigate("/login");
    }else{
      localStorage.removeItem("JOB_ID");
      navigate("/job-list/1");
    }
  
    window.location.reload();
  }
  function onJobClick(jobId) {
    id = jobId;
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getJobDetailsById();
    navigate("/job-details/"+jobId);
  }
  function numFormatter(num) {
    if(num != undefined && num !== 0 && num !== 1){
      if(num > 999999){
        return (num / 10000).toLocaleString("en-US") + '万円';
      }else if(num < 1000000 && num > 999){
        return (num/1000).toLocaleString("en-US") + '千円';
      }else if(num < 1000 && num > 99){
        return (num/100).toLocaleString("en-US") + '百円';
      }else if(num < 100){
        return (num).toLocaleString("en-US");
      }
    }
    if(num === 0){
       num = "上限なし"
       return num
    }
    if(num === 1){
      num = "応相談"
      return num
    }
  }
  return (
    <>
      <section id="Hotjobs" class="top-90">
        <div class="padding-body mt-3">
          <div class="sub-text">
            {" "}
            <Link
              to={"/"}
              style={{ color: "#a5a6a8", textDecoration: "none" }}
            >
              {" "}
              {t("top")}
            </Link>{" "}
            /{" "}
            <Link
              to={
                "/job-list/1?area=&prefecture=&category=&subCategory=&industry=&skill=&maxSalary=&minSalary="
              }
              style={{ textDecoration: "none", color: "#a5a6a8" }}
            >
              {t("all_jobs")}
            </Link>{" "}
            / <span>{t("job_details")}</span>{" "}
          </div>

          <div class="top-90">
            <div class="row mb-3 justify-content-center">
              <div class="col-md-10">
                <div class="card job-list-card">
                  <div class="card-body py-4 px-5">
                    <div class="row">
                      <div class="col-md-10 col-custom10">
                        <span className="site_text_order ">
                          {" "}
                          <h5>{job?.JobName}</h5>
                          <h8 style={{"fontSize":"12px"}}>{t('last_updated_date')}{dateFormat(job?.updatedDate,"dd/mm/yyyy")}</h8>
                       
                        </span>
                        <div className="row pt-3 colr-catch ">{job?.Company?.catchPhrase}</div>
                     
                        <div class="row pt-4">
                          {/* <div class="col-md-3 job-col2">
                            <div class="icons-btm d-flex">
                              {" "}
                              <div>
                              <img
                                src={building}
                                alt=""
                                class="icons-b pe-2"
                              />{" "}
                              </div>
                              <div>
                              <span class="font-16"  data-toggle="tooltip" data-placement="bottom" title= {job?.Company?.companyName} >
                                {job?.Company?.companyName}
                              </span>
                              </div>
                            </div>
                          </div> */}
                          {/* {(job?.prefecture?.prefectureName)?
                          <div class="col-md-4 job-col2">
                            <div class="icons-btm d-flex" >
                              {" "}
                              <div>
                              <img
                                src={locationSVG}
                                alt=""
                                class="icons-l pe-2"
                              />{" "}
                              </div>
                              <div>
                              <span class="font-16" data-toggle="tooltip" data-placement="bottom" title= {job?.prefecture?.prefectureName}>
                                {job?.prefecture?.prefectureName}
                              </span>
                              </div>
                            </div>
                          </div>:<></>}  */}
                          {job?.prefectures?.length > 0 && (
                          <div class="col-md-4 job-col2">
                            <div class="icons-btm d-flex">
                              <div>
                                <img src={locationSVG} alt="" class="icons-l pe-2" />
                              </div>
                              <div
                                style={{
                                  width: '75%',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap'
                                }}
                                title={job?.prefectures
                                  ?.map((prefecture) => prefecture?.prefectureName)
                                  .join(', ')}>
                                {job?.prefectures?.map((prefecture, index) => (

                                  <span
                                    key={index}
                                    class="font-16"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                  >
                                    {prefecture.prefectureName}
                                  </span>
                                )).reduce((prev, curr) => [prev, ', ', curr])}

                              </div>
                            </div>
                          </div>
                          )}
                          {(job?.Company?.industry?.industryName) ? <div class="col-md-4 job-col2">
                            <div class="icons-btm d-flex">
                              {" "}
                              <div>
                              <img
                                src={industrySVG}
                                alt=""
                                class="icons-i pe-2"
                              />{" "}
                               </div>
                              <div>
                              <span class="font-16" data-toggle="tooltip" data-placement="bottom" title= {job?.Company?.industry?.industryName}>
                                {job?.Company?.industry?.industryName}
                              </span>
                              </div>
                            </div>
                          </div>:<></>} 
                          {(job?.salaryRange?.minSalary)?<div class="col-md-4 job-col2">
                            <div class="icons-btm d-flex">
                              {" "}
                              <div>
                              <i class="fa-solid fa-yen-sign yen-font pe-2"></i>{" "}
                              </div>
                              <div>
                              <span class="font-16 yen-color salary-font" data-toggle="tooltip" data-placement="bottom" title= {numFormatter(job?.salaryRange?.minSalary) + "-" + numFormatter(job?.salaryRange?.maxSalary)}>
                                {numFormatter(job?.salaryRange?.minSalary)} -{" "}
                                {numFormatter(job?.salaryRange?.maxSalary)}
                              </span>
                              </div>
                            </div>
                          </div>:<></>}
                        </div>
                      
                      </div>
                      <div class="col-md-2 m-auto text-center col-custom-2 my-5">
                        <button
                          id="jobApplyHiidenButton"
                          data-bs-toggle="modal"
                          data-bs-target="#applyjob"
                          hidden
                        ></button>
                        <button
                          class="apply-btn"
                          onClick={handleOnClickApplyJob}
                        >
                          {t("apply_Now")}
                        </button>
                      </div>
                    </div>

                    <div class="details-section pt-4">
                      <table class="table">
                        <tbody>
                          <tr>
                            <td scope="row" class="details-head">
                              {t("job_Description")}{" "}
                            </td>
                            <td class="details-p site_text_order">
                              {" "}
                              {job?.description}
                            </td>
                          </tr>
                          {(job?.employement_type?.employementType)? <tr>
                            <td scope="row" class="details-head">
                              {t("employee_Type")}
                            </td>
                            <td class="details-p site_text_order">
                              {" "}
                              {job?.employement_type?.employementType}
                            </td>
                          </tr>:<></>}
                          {(job?.benefits)?<tr>
                            <td scope="row" class="details-head">
                              {t("benefit")}
                            </td>
                            <td class="details-p site_text_order">
                              {" "}
                              {job?.benefits}
                            </td>
                          </tr>:<></>}
                          
                          {(job?.holiday)?<tr>
                            <td scope="row" class="details-head">
                              {t("vacation")}
                            </td>
                            <td class="details-p site_text_order">
                              {" "}
                              {job?.holiday}
                            </td>
                          </tr>:<></>}
                          
                          {(job?.workingHours)?  <tr>
                            <td scope="row" class="details-head">
                              {t("working_hour")}
                            </td>
                            <td class="details-p site_text_order">
                              {" "}
                              {job?.workingHours}
                            </td>
                          </tr>:<></>}
                        

                          <tr>
                            <td scope="row" class="details-head">
                              {t("job_Category")}
                            </td>
                            <td class="details-p site_text_order">
                              {job?.jobSubCategory?.map((category, index) => (
                                <span>
                                  {category?.subcategoryName}{" "}
                                  <span>
                                    {job.jobSubCategory.length - 1 !== index
                                      ? ","
                                      : ""}
                                  </span>
                                </span>
                              ))}
                            </td>
                          </tr>

                          <tr>
                            <td scope="row" class="details-head">
                              {t("skill")}
                            </td>
                            <td class="details-p site_text_order">
                              {job?.skills?.map((row, index) => (
                                <span>
                                  {" "}
                                  {row?.skillName}{" "}
                                  <span>
                                    {job.skills.length - 1 !== index ? "," : ""}
                                  </span>
                                </span>
                              ))}
                            </td>
                          </tr>
                          {(job?.conditions)? <tr>
                            <td scope="row" class="details-head">
                              {t("condition")}
                            </td>
                            <td class="details-p site_text_order">
                              {" "}
                              {job?.conditions}
                            </td>
                          </tr>:<></>}
                         
                          {(job?.comments)? <tr>
                            <td scope="row" class="details-head">
                              {t("advisor_comment")}
                            </td>
                            <td class="details-p">
                              <div class="row">
                                <div class="col2 m-auto justify-content-center align-items-center d-flex">
                                  <div class="comment-border">
                                    {" "}
                                    <img
                                      src={commentLogo}
                                      alt=""
                                      width="94"
                                      height="94"
                                    />{" "}
                                  </div>
                                </div>
                                <div class="col10 m-auto">
                                  <div class="comment-bg site_text_order">
                                    {job?.comments}
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>:<></>}
                         
                        </tbody>
                      </table>
                    </div>

                  {/*    <div class="details-section ">
                      <div class="ps-2">
                        <h5>{t("company_profile")}</h5>
                      </div>
                      <table class="table pt-2">
                        <tbody>
                          <tr>
                            <td scope="row" class="details-head">
                              {t("catchphrase")}
                            </td>
                            <td class="details-p site_text_order">
                             <span > {job?.Company?.catchPhrase}</span>
                            </td>
                          </tr>
                       {(job?.Company?.capital)? <tr>
                            <td scope="row" class="details-head">
                              {t("company_capital")}
                            </td>
                            <td class="details-p site_text_order">
                              {" "}
                              {numFormatter(parseInt(job?.Company?.capital))} {t("yen")}
                            </td>
                          </tr>:<></>}
                          {(job?.Company?.headquartersLocation)?<tr>
                            <td scope="row" class="details-head">
                              {t("company_head_office")}
                            </td>
                            <td class="details-p site_text_order">
                              {job?.Company?.headquartersLocation}
                            </td>
                          </tr>:<></>}
                          
                          {(job?.Company?.companyURL)?<tr>
                            <td scope="row" class="details-head">
                              {t("url")}
                            </td>
                            <td class="details-p site_text_order">
                              {" "}
                              <a
                                href={job?.Company?.companyURL}
                                target="_blank"
                              >
                                {job?.Company?.companyURL}
                              </a>{" "}
                            </td>
                          </tr>:<></>} 
                          
                        </tbody>
                      </table>
                    </div>*/}
                  </div>

                  <div class="col-md-12 m-auto text-center col-custom-2 my-5">
                    <button
                      id="jobApplyHiidenButton"
                      data-bs-toggle="modal"
                      data-bs-target="#applyjob"
                      hidden
                    ></button>
                    <button class="apply-btn" onClick={handleOnClickApplyJob}>
                      {t("apply_Now")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="top-90">
            <div class="row mb-3 justify-content-center">
              <div class="col-md-10">
                {relatedJobs?.map((row, index) => (
                  <div class="card job-card">
                    <div class="card-body p-4">
                      <div class="row">
                        <div class="col-md-10 col-custom10">
                          <span className="site_text_order">
                            {" "}
                            <h5>{row.jobName}</h5>
                          </span>
                          <p className="site_text_order description-text">{row.description}</p>

                          <div class="row ps-2">
                            {row?.tags?.map((tag, index) => (
                              <button class="tag-bg mb-3">{tag.tagName}</button>
                            ))}
                          </div>
                          <div  class="row pt-2 colr-catch-font "><span className='saperate-text '>{row?.Company?.catchPhrase}</span></div>
                          <div class="row pt-4">
                            {/* <div class="col-md-3 job-col2">
                              <div class="icons-btm d-flex">
                                {" "}
                                <div>
                                <img
                                  src={building}
                                  alt=""
                                  class="icons-b pe-2"
                                />{" "}
                                </div>
                                <div className="table-text">
                                <span class="font-16" data-toggle="tooltip" data-placement="bottom" title=  {row?.Company?.companyName}>
                                  {row?.Company?.companyName}
                                </span>
                                </div>
                              </div>
                            </div> */}
                            {(row?.prefecture?.prefectureName)? <div class="col-md-4 job-col2">
                              <div class="icons-btm d-flex">
                                {" "}
                                <div>
                                <img
                                  src={locationSVG}
                                  alt=""
                                  class="icons-l pe-2"
                                />{" "}
                                </div>
                                <div className="table-text">
                                <span class="font-16" data-toggle="tooltip" data-placement="bottom" title=  {row?.prefecture?.prefectureName}>
                                  {row?.prefecture?.prefectureName}
                                </span>
                                </div>
                              </div>
                            </div>:<></>}
                            {(row?.Company?.industry?.industryName)?<div class="col-md-4 job-col2">
                              <div class="icons-btm  d-flex">
                                {" "}
                                <div>
                                <img
                                  src={industrySVG}
                                  alt=""
                                  class="icons-i pe-2"
                                />{" "}
                                </div>
                                <div className="table-text">
                                <span class="font-16" data-toggle="tooltip" data-placement="bottom" title= {row?.Company?.industry?.industryName}>
                                  {row?.Company?.industry?.industryName}
                                </span>
                                </div>
                              </div>
                            </div>:<></>}
                            {(row?.salaryRange?.minimumSalary)?<div class="col-md-4 job-col2">
                              <div class="icons-btm  d-flex">
                                {" "}
                                <div>
                                <i class="fa-solid fa-yen-sign yen-font pe-2"></i>{" "}
                                </div>
                                <div className="table-text">
                                <span class="font-16 yen-color salary-font"  data-toggle="tooltip" data-placement="bottom" title= {numFormatter(row?.salaryRange?.minimumSalary) + "-" + numFormatter(
                                    row?.salaryRange?.maximumSalary
                                  )}>
                                  {numFormatter(
                                    row?.salaryRange?.minimumSalary
                                  )}{" "}
                                  -{" "}
                                  {numFormatter(
                                    row?.salaryRange?.maximumSalary
                                  )}
                                </span>
                                </div>
                              </div>
                            </div>:<></>}

                          </div>
                        </div>
                        <div class="col-md-2 m-auto text-center col-custom-2">
                          <button
                            class="job-details"
                            onClick={(e) => onJobClick(row?.jobId)}
                          >
                            {t("job_details")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer
        position="bottom-right"
        autoClose={800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
      <a
        href=""
        class="edit-icon"
        data-bs-toggle="modal"
        id="successPopUpButton"
        data-bs-target="#successPopup"
        hidden
      ></a>

      <div
        class="modal fade"
        id="successPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-confirm ">
          <div class="modal-content">
            <div class="modal-header justify-content-center">
              <div class="icon-box">
               
                  <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
              </div>
              <button
                onClick={handleOnClickPopUp}
                type="button"
                id="successPopUpCloseButton"
                class="Close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ color: "white" }}>
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div class="modal-body text-center">
            {errorMsg === true?  (<p>{t("job_not_found")}</p>) : (<p>{errorJobResponse}</p>)}
           
            <div>  <button
                class="btn btn-success"
                data-dismiss="modal"
                onClick={handleOnClickPopUp}
              >
                <span>{t("ok")}</span>
              </button></div>
            </div>
          </div>
        </div>
      </div>

      {/* Job Apply Popup Modal */}
      <div
        class="modal fade"
        id="applyjob"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header border-none">
              <div class="col-md-12 text-center mt-60"></div>
              <button
                type="button"
                class="Close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div class="modal-body p-0 mb-5">
              <div class="row d-flex align-items-center">
                <div class="text-center singleline_header">
                 
                  <h5 className="break-line">{t('new_user_or_existing')}</h5>
                  <h5 className="break-line">{t('new_user_or_existing_line_2')} </h5>
                </div>

                <div class="col-md-12 text-center">
                  <button
                    class="reg-btn"
                    data-bs-dismiss="modal"
                    onClick={handleOnClickRegistration}
                  >
                    {t("new_registration")}
                  </button>
                  <button
                    class="main-buttons sub-btn"
                    data-bs-dismiss="modal"
                    onClick={handleOnClickLogin}
                  >
                    {t("login")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default Jobdetails;
