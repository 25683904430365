import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import useAppContext, { refreshAccessToken } from '../../AppContext';
import i18n from "../../i18n";
import { actions } from "../../redux/companyReducer";
import { useDispatch } from 'react-redux';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
function ConfirmationCareer(){
  let history = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] =useState(false);
  const appContext = useAppContext();
  const axios = appContext.getAxios();
  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const [resume, setResume] = useState('');
  const [errorJobResponse, setErrorJobResponse] = useState(null);
  const [errorResponseMsg,setErrorResponseMsg] = useState(null);
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    dispatch(actions.setuserDetails({}));
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    let refreshToken = localStorage.getItem("refreshToken")
    // setOpen(true);
    // if(refreshToken !== null && refreshToken !== undefined){
    //   refreshAccessToken();
    // }
 
    getUserDetails();
  }, []);
  function getUserDetails(){
    setOpen(true);
    setErrorJobResponse(null)
    setErrorResponseMsg(null)
    axios.get('users/details').then(
      (response) => {
        console.log(response.data);
        setUser(response?.data)
        let file = process.env.REACT_APP_FILE_URL + response?.data?.resume
        console.log(file)
        setResume(file)
        setOpen(false);
      },
      (error) => {

       if(error.response?.data.errorCode === "22202" || error.response?.data.errorCode === "1009"){
        
          setErrorJobResponse(t('invalid_token'))
        }else if(error.response?.data.errorCode === "21115" ){
         
          setErrorJobResponse(t('job_user_unpublished'))
        }else if(error.response?.data.errorCode === "21114" ){
          
          setErrorJobResponse(t('job_user_deleted'))
        }
        setTimeout(() => {
          setOpen(false);
        }, 800);
        document.getElementById("successPopUpButton").click();
       
     
      }
    );
  }
  function onSubmit(e){
    e.preventDefault();
    setErrorJobResponse(null)
    setErrorResponseMsg(null)
    setOpen(true);
    axios.post('careerCounselling/apply').then(
      (response) => {
          console.log(response);
          setOpen(false);
          window.dataLayer.push({
            event:"Career Counselling Job Apply",
            });
          history('/counseling-success');
      },
      (error) => {
        if(error.response?.data.errorCode === "22202" || error.response?.data.errorCode === "1009"){
        
          setErrorJobResponse(t('invalid_token'))
          document.getElementById("successPopUpButton").click();
        }else if(error.response?.data.errorCode === "21115" ){
         
          setErrorJobResponse(t('job_user_unpublished'))
          document.getElementById("successPopUpButton").click();
        }else if(error.response?.data.errorCode === "21114" ){
          
          setErrorJobResponse(t('job_user_deleted'))
          document.getElementById("successPopUpButton").click();
        }else if(error.response?.data.errorCode === "23701" ){
          
          history("/job-apply-error");
        }
        setTimeout(() => {
          setOpen(false);
        }, 800);
       
      }
  );
  }
  function handleOnClickPopUp() {
  
    document.getElementById("successPopUpCloseButton").click();
    appContext.logout();
    history("/login");

    window.location.reload();
  }
  return (
    <>
       <div className="d-flex flex-column h-100">

<div className="banner-login middle-toparea">
    <div className="container-fluid padding-body">
        <div className="sub-menus"><Link className="topI" to={'/'}><a style={{"color":"#DBDBDB"}}>{t('top')}</a></Link> / <span>{t('user_confirmation')}</span> </div>

        <div className="login-header">
            <h3>{t('user_confirmation')}</h3>
        </div>
    </div>

</div>
   <section id="Hotjobs" className="top-90">
       
      <div className="container my-5">
        <div className="card mt-3 Card-box">
          <div className="card-body">
         
            <form onSubmit={(e) => onSubmit(e)}>
            <div class="row">
            <div className='confirm_head'>
              <p className='font-18-bold '> {t("full_name")} </p>
            </div>
            <div class="col-md-4">
              <div class="mb-5">
                <label for="name" class="form-label input-label fnd-16"><b>{t("last_name")}</b></label>
                <div> <label for="name">{user.lastName}</label></div>
              </div>

            </div>
           {/* {user.middle_name !== null && user.middle_name !== undefined  && user.middle_name !== "" ?( */}
           <div class="col-md-4">
              <div class="mb-5">
                <label for="name" class="form-label input-label fnd-16"><b>{t("middle_name_confir")}</b></label>
                <div> <label for="name">{user.middle_name}</label></div>
              </div>
            </div>
            {/* ):(<></>)}  */}
         
            <div class="col-md-4">
              <div class="mb-5">
                <label for="name" class="form-label input-label fnd-16"><b>{t("first_name")}</b></label>
                <div><label for="name">{user.firstName}</label></div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="mb-5">
                <label for="name" class="form-label input-label fnd-16"><b>{t("tel_no")}</b></label>
                <div><label for="name">{user.phoneNumber}</label></div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-5">
                <label for="emailHelp" class="form-label input-label fnd-16"><b>{t("email_id")}</b></label>
                <div><label for="name">{user.email}</label></div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-5">
                <label for="name" class="form-label input-label fnd-16"><b>{t("dob")}</b></label>
                <div><label for="name">{user.dateOfBirth}</label></div>
              </div>
            </div>
          </div>
          <div class="row">
            <div className='confirm_head'>
              <p className='font-18-bold'>{t("current_aadr")}  <br/>
                {/* <span class="address-sub">都道府県・市区町村・番地・マンション名・部屋番号までのご入力をお願い致します。
                </span> */}
              </p>
            </div>
            <div class="col-md-4">
              <div class="mb-5">
                <label for="name" class="form-label input-label fnd-16"><b>{t("prefeature")}</b></label>
                <div><label for="name">{user.prefecture}</label></div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-5">
                <label for="name" class="form-label input-label fnd-16"><b>{t("city")}</b></label>
                <div> <label for="name">{user.city}</label></div>
              </div>
            </div>
            {/* {user.apartmentName !== null && user.apartmentName !== undefined  && user.apartmentName !== "" ?(  */}
            <div class="col-md-4">
              <div class="mb-5">
                <label for="name" class="form-label input-label fnd-16"><b>{t("apartment_name_confir")}</b></label>
                <div> <label for="name">{user.apartmentName}</label></div>
              </div>
            </div> 
            {/* ): (<></>)} */}
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="mb-5">
                <label for="name" class="form-label input-label"><b>{t("desied_occupation")}</b></label>
                <div><label for="name">{user.desiredOccupation}</label></div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-5">
            
              </div>

            </div>
          </div>
          {user.resume !== null && user.resume !== undefined  && user.resume !== "" ?(<div class="row">
            <div class="col-md-6">
              <div class="mb-5">
              <label for="name">
                          <a  href={resume} target="_blank">
                          {t("view_resume")}
                          </a>
                        </label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="mb-5">
             
              </div>

            </div>
          </div>):(<></>)}
              <div className="text-center">
                <button type="submit" className="main-buttons mb-0">
                  {t("submit")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
     
      </section>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <a
        href=""
        className="edit-icon"
        data-bs-toggle="modal"
        id="successPopUpButton"
        data-bs-target="#successPopup"
        hidden
      ></a>
      <div
        className="modal fade"
        id="successPopup"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-confirm ">
          <div className="modal-content">
            <div className="modal-header justify-content-center">
              <div className="icon-box">
                <i
                  className="fa fa-exclamation-triangle"
                  aria-hidden="true"
                ></i>
              </div>

              <Link to="/login">
                <button
                  type="button"
                  id="successPopUpCloseButton"
                  className="Close-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ color: "white" }}
                >
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </Link>
            </div>
            <div className="modal-body text-center">
              <p>{errorJobResponse}</p>
              <div>
                <button
                  className="btn btn-success"
                  data-dismiss="modal"
                  onClick={handleOnClickPopUp}
                >
                  <span>{t("ok")}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ConfirmationCareer;
