import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom'
import axios from "axios"
import Pagination from '../../pagination.js';
import Spinner from "../spinner/spinner";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { actions } from "../../redux/companyReducer";
import { useDispatch } from 'react-redux';
const Joblist = () => {
  const location = useLocation()
  const params = useParams();
  const navigate = useNavigate();
  const search = useLocation().search;
  const { t } = useTranslation();
  const limit = 10;
  const [count, setCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(parseInt(params.page));
  const [lastPage, setLastPage] = useState();
  const [allJobs, setAllJobs] = useState([]);
  const [area, setArea] = useState([]);
  const [category, setCategory] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [salary, setSalary] = useState([]);
  const [skill, setSkill] = useState([]);
  const [isLoadedAllJobs, setIsLoadedAllJobs] = useState(false);

  const [selectedPrefecture, setSelectedPrefecture] = useState([])
  const [selectedArea, setSelectedArea] = useState([]);
  const [selectedJobCategory, setSelectedJobCategory] = useState([]);
  const [selectedJobSubCategory, setSelectedJobSubCategory] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selectedMaxSalary, setSelectedMaxSalary] = useState();
  const [selectedMinSalary, setSelectedMinSalary] = useState();

  const [tempPrefecture, setTempPrefecture] = useState([])
  const [tempArea, setTempArea] = useState([]);
  const [tempJobCategory, setTempJobCategory] = useState([]);
  const [tempJobSubCategory, setTempJobSubCategory] = useState([]);
  const [tempSkill, setTempSkill] = useState([]);
  const [tempIndustry, setTempIndustry] = useState([]);
  const [tempMaxSalary, setTempMaxSalary] = useState([]);
  const [tempMinSalary, setTempMinSalary] = useState([]);

  const [isDecimalmin, setIsDecimalMin] =  useState(false);
  const [isDecimalmax, setIsDecimalMax] =  useState(false);
  const [salaryError, setSalaryError] = useState(false);
  const [sameSalaryError, setSameSalaryError] = useState(false);
  const [freeText, setFreeText] = useState(new URLSearchParams(search).get('freeText') ? new URLSearchParams(search).get('freeText') : "");
  const [errorResponse,setErrorResponse] = useState(null);
  const initArea = new URLSearchParams(search).get('area') ? convertArray(new URLSearchParams(search).get('area').split(',')) : [];
  const initPrefecture = new URLSearchParams(search).get('prefecture') ? convertArray(new URLSearchParams(search).get('prefecture').split(',')) : [];
  const initJobCategory = new URLSearchParams(search).get('category') ? convertArray(new URLSearchParams(search).get('category').split(',')) : [];
  const initJobSubCategory = new URLSearchParams(search).get('subCategory') ? convertArray(new URLSearchParams(search).get('subCategory').split(',')) : [];
  const initIndustry = new URLSearchParams(search).get('industry') ? convertArray(new URLSearchParams(search).get('industry').split(',')) : [];
  const initSkill = new URLSearchParams(search).get('skill') ? convertArray(new URLSearchParams(search).get('skill').split(',')) : [];
  const initMaxSalary = new URLSearchParams(search).get('maxSalary') ? convertArray(new URLSearchParams(search).get('maxSalary').split(',')) : [];
  const initMinSalary = new URLSearchParams(search).get('minSalary') ? convertArray(new URLSearchParams(search).get('minSalary').split(',')) : [];

  const maxValue = 999999999;
  const minValue = 0;
  const [salaryList, setSalaryList] = useState()
  const alphabets = [
    'a','b','c','d','e','f','g','h','i','j','k','l', 'm','n','o','p','q','r','s','t','u','v','w','x','y','z',
    'A','B','C','D','E', 'F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z',
    '+','-','*','/','!','@','#','$','%','^',"&",'(',')','-','_','"','{','}','`','~','/',',','<','>','?',';',':'
    ];
    const dispatch = useDispatch();
  useEffect(() => {
   
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    dispatch(actions.setuserDetails({}));
    setSelectedArea(initArea);
    setSelectedPrefecture(initPrefecture);
    setSelectedJobCategory(initJobCategory);
    setSelectedJobSubCategory(initJobSubCategory);
    setSelectedIndustry(initIndustry);
    setSelectedSkill(initSkill);
    setSelectedMinSalary(initMinSalary);
    setSelectedMaxSalary(initMaxSalary);

    setTempArea(initArea);
    setTempPrefecture(initPrefecture);
    setTempJobCategory(initJobCategory);
    setTempJobSubCategory(initJobSubCategory);
    setTempIndustry(initIndustry);
    setTempSkill(initSkill);
    setTempMinSalary(initMinSalary);
    setTempMaxSalary(initMaxSalary);


    getAllJobsInit(currentPage);
    getCategory();
    getArea();
    getIndustry();
    getSalary();
    getSkill();
  
    getAllSalary();
    setTimeout(() => { window.scrollTo(0, 0); }, 100);
    // eslint-disable-next-line 
    window.onpopstate = function(e){
      if(e.state){
        e.preventDefault();
        window.location.reload();
      }
     
  };
  }, []);

  async function getAllJobs(page) {
    setErrorResponse(null)
    await axios.get(process.env.REACT_APP_BASE_URL + "job/list?page=" + page + "&limit=" + limit + "&industry=" + tempIndustry +
      "&area=" + tempArea + "&minSalary=" + tempMinSalary  +
      "&prefecture=" + tempPrefecture + "&jobCategory=" + tempJobCategory +
      "&jobSubCategory=" + tempJobSubCategory + "&skill=" + tempSkill + "&freeText=" + encodeURIComponent(freeText))
      .then((response) => {
        setAllJobs(response.data.data.jobList);
        setIsLoadedAllJobs(true);
        setCount(response.data.total);
        setLastPage(response?.data?.totalPages);
        console.log(allJobs,lastPage);
        window.dataLayer.push({
          event:"Job",
          });
      }).catch(error => {
        setIsLoadedAllJobs(true);
        console.log('There was an error!', error);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setTimeout(() => {
          setErrorResponse(t('server_error'))
       }, 2000);
      });
  }
  async function getAllJobsInit(page) {
    console.log(initMinSalary);
    setErrorResponse(null)
    await axios.get(process.env.REACT_APP_BASE_URL + "job/list?page=" + page + "&limit=" + limit + "&industry=" + initIndustry +
      "&area=" + initArea + "&minSalary=" + initMinSalary +
      "&prefecture=" + initPrefecture + "&jobCategory=" + initJobCategory +
      "&jobSubCategory=" + initJobSubCategory + "&skill=" + initSkill + "&freeText=" + encodeURIComponent(freeText))
      .then((response) => {
        setAllJobs(response.data.data.jobList);
        setIsLoadedAllJobs(true);
        setCount(response.data.total);
        setLastPage(response?.data?.totalPages);
        const totalPageCount = Math.ceil(response?.data?.total / limit);
        console.log(totalPageCount)
        if(page > totalPageCount){
          setCurrentPage(totalPageCount);
          if(totalPageCount !== 0){
            console.log(currentPage);
           
            console.log(tempMinSalary);
            navigatePage(totalPageCount);
          }
        }
        console.log(allJobs);
        window.dataLayer.push({
          event:"Job",
          });
      }).catch(error => {
        setIsLoadedAllJobs(true);
        console.log('There was an error!', error);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setTimeout(() => {
          setErrorResponse(t('server_error'))
       }, 2000);
      });
  }
  function getArea() {
    axios.get(process.env.REACT_APP_BASE_URL + "category/area").then((response) => {
      setArea(response.data.areaList);
      console.log(area);
    }).catch(error => {
      console.log('There was an error!', error);
    });
  }
  function getCategory() {
    axios.get(process.env.REACT_APP_BASE_URL + "category").then((response) => {
      setCategory(response.data.categoryList);
      console.log(category);
      addSubCategoryList(response.data.categoryList);
    }).catch(error => {
      console.log('There was an error!', error);
    });
  }
  function getIndustry() {
    axios.get(process.env.REACT_APP_BASE_URL + "industry").then((response) => {
      setIndustry(response.data.industryList);
      console.log(industry);
    }).catch(error => {
      console.log('There was an error!', error);
    });
  }
  function getSalary() {
    axios.get(process.env.REACT_APP_BASE_URL + "salary").then((response) => {
      setSalary(response.data);
      console.log(salary);
    }).catch(error => {
      console.log('There was an error!', error);
    });
  }
  function getSkill() {
    axios.get(process.env.REACT_APP_BASE_URL + "skill").then((response) => {
      setSkill(response.data.skillList);
      console.log(skill);
    }).catch(error => {
      console.log('There was an error!', error);
    });
  }


  function addSubCategoryList(list) {
    let categoryId = location?.state?.selectedJobCategory;
    if (categoryId?.length === 1) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === parseInt(categoryId)) {
          list[i].subCategoryList.map((jobSubCategoryDetails) => {
            setSelectedJobSubCategory(selectedJobSubCategory => [parseInt(jobSubCategoryDetails.subId), ...selectedJobSubCategory]);
            return 0;
          })
        }
      }
    }
  }
  function navigatePage(page) {
    setCurrentPage(page);
    navigate("/job-list/" + page + "?area=" + tempArea + "&prefecture=" + tempPrefecture + "&category=" + tempJobCategory + "&subCategory=" + tempJobSubCategory +
      "&industry=" + tempIndustry + "&skill=" + tempSkill + "&minSalary=" + tempMinSalary + "&freeText=" + freeText);
    getAllJobs(page);
  }
  function pageChange(page) {
    setCurrentPage(page);
     if (page >= 1 && page <= lastPage) {
    navigatePage(page);
    window.scrollTo(0, 0);
     }
  }


  const handleOnChangeArea = (event, prefectures) => {
    console.log(prefectures)
    if (event.target.checked) {
      console.log("checked")
      prefectures.map((prefectureDetails) => {
        setTempPrefecture(tempPrefecture => [parseInt(prefectureDetails.prefectureId), ...tempPrefecture]);
        return 0;
      })
      setTempArea(tempArea => [parseInt(event.target.value), ...tempArea]);
      console.log(tempArea);
    } else if (!event.target.checked) {
      console.log("unchecked")
      prefectures.map((prefectureDetails) => {
        setTempPrefecture(current =>
          current.filter(item => {
            return item !== parseInt(prefectureDetails.prefectureId);
          }),);
        return 0;
      })
      setTempArea(current =>
        current.filter(item => {
          return item !== parseInt(event.target.value);
        }),);
      console.log(tempArea);
    }
  };
  const handleOnChangePrefecture = (event, prefectures, areaId) => {
    if (event.target.checked) {
      console.log("checked")
      setTempPrefecture(tempPrefecture => [parseInt(event.target.value), ...tempPrefecture]);
      let flag;
      for (let i = 0; i < prefectures.length; i++) {
        if (document.getElementById("prefecture" + prefectures[i].prefectureId).checked === true) {
          flag = 1;
          console.log(flag)
        } else {
          flag = 0;
          break;
        }
      }
      if (flag === 1) {
        setTempArea(tempArea => [parseInt(areaId), ...tempArea]);
      }
    } else if (!event.target.checked) {
      console.log("unchecked")
      setTempPrefecture(current =>
        current.filter(item => {
          return item !== parseInt(event.target.value);
        }),);
      setTempArea(current =>
        current.filter(item => {
          return item !== parseInt(areaId);
        }),);
    }
    console.log(tempPrefecture);
  };
  const handleOnChangeJobCategory = (event, jobSubCategory) => {
    console.log(jobSubCategory)
    if (event.target.checked) {
      console.log("checked")
      jobSubCategory.map((jobSubCategoryDetails) => {
        setTempJobSubCategory(tempJobSubCategory => [parseInt(jobSubCategoryDetails.subId), ...tempJobSubCategory]);
        return 0;
      })
      setTempJobCategory(tempJobCategory => [parseInt(event.target.value), ...tempJobCategory]);
      console.log(tempJobCategory);
    } else if (!event.target.checked) {
      console.log("unchecked")
      jobSubCategory.map((jobSubCategoryDetails) => {
        setTempJobSubCategory(current =>
          current.filter(item => {
            return item !== parseInt(jobSubCategoryDetails.subId);
          }),);
        return 0;
      })
      setTempJobCategory(current =>
        current.filter(item => {
          return item !== parseInt(event.target.value);
        }),);
      console.log(tempJobCategory);
    }
  };
  const handleOnChangeJobSubCategory = (event, subCategory, categoryId) => {
    if (event.target.checked) {
      console.log("checked")
      setTempJobSubCategory(tempJobSubCategory => [parseInt(event.target.value), ...tempJobSubCategory]);
      console.log(tempJobSubCategory);
      let flag;
      for (let i = 0; i < subCategory.length; i++) {
        if (document.getElementById("subCategory" + subCategory[i].subId).checked === true) {
          flag = 1;
          console.log(flag)
        } else {
          flag = 0;
          break;
        }
      }
      if (flag === 1) {
        setTempJobCategory(tempJobCategory => [parseInt(categoryId), ...tempJobCategory]);
      }
    } else if (!event.target.checked) {
      console.log("unchecked")
      setTempJobSubCategory(current =>
        current.filter(item => {
          return item !== parseInt(event.target.value);
        }),);
      setTempJobCategory(current =>
        current.filter(item => {
          return item !== parseInt(categoryId);
        }),);
    }
  };
  const handleOnChangeIndustry = (event) => {
    if (event.target.checked) {
      setTempIndustry(tempIndustry => [parseInt(event.target.value), ...tempIndustry]);
      console.log(tempIndustry);
    } else if (!event.target.checked) {
      setTempIndustry(current =>
        current.filter(item => {
          return item !== parseInt(event.target.value);
        }),);
      console.log(tempIndustry)
    }
  };
  const handleOnChangeSkill = (event) => {
    if (event.target.checked) {
      setTempSkill(tempSkill => [parseInt(event.target.value), ...tempSkill]);
      console.log(tempSkill);
    } else if (!event.target.checked) {
      setTempSkill(current =>
        current.filter(item => {
          return item !== parseInt(event.target.value);
        }),);
      console.log(tempSkill)
    }
  };
  const handleOnChangeMaxSalary = (event) => {
    if(event.target.value.length > 9){
      return
    }
    salaryCheck(tempMinSalary,event.target.value);
    if (!event.target.value) {
      setTempMaxSalary("");
    } else {
      validateDecimalMax(event.target.value);
      if (event.target.value <= minValue) {
        event.target.value = minValue;

      } else if (event.target.value >= maxValue) {
        event.target.value = event.target.value.slice(0, 10);

      }
      setTempMaxSalary(event.target.value);
      console.log(tempMaxSalary)
    }
  }
  const handleOnChangeMinSalary = (event) => {
    setTempMinSalary(event.target.value);
    // if(event.target.value.length > 9){
    //   return
    // }
    // salaryCheck(event.target.value,tempMaxSalary);
    // console.log(event.target.value)
    // if (!event.target.value) {
    //   setTempMinSalary("");
    // } else {
    //   validateDecimalMin(event.target.value);
    //   if (event.target.value <= minValue) {
    //     event.target.value = minValue;

    //   } else if (event.target.value >= maxValue) {
    //     event.target.value = event.target.value.slice(0, 10);

    //   }
    //   setTempMinSalary(event.target.value);
    //   console.log(tempMinSalary);
    // }
  };
  function salaryCheck(min,max){
    if(parseInt(min) > parseInt(max)){
      console.log(min," ",max)
      console.log("true")
      setSalaryError(true);
      setSameSalaryError(false);   
    }else if(parseInt(min) < parseInt(max)){
      console.log(min," ",max)
      console.log("false")
      setSalaryError(false);
      setSameSalaryError(false);        
    }else if(parseInt(min) === parseInt(max)){
      console.log(min," ",max)
      console.log("false")
      setSalaryError(false);
      setSameSalaryError(true);   
    }   
  }
  function validateDecimalMax(num) {
    let value = num;
    let regex = /^[-+]?[0-9]+\.[0-9]+$/;
    let isValidated = regex.test(value);
    if (isValidated) {
      setIsDecimalMax(true);
    } else {
      setIsDecimalMax(false);
    }
 }
 function validateDecimalMin(num) {
  let value = num;
  let regex = /^[-+]?[0-9]+\.[0-9]+$/;
  let isValidated = regex.test(value);
  if (isValidated) {
    setIsDecimalMin(true);
  } else {
    setIsDecimalMin(false);
  }
}
  const handleOnChangeFreeText = (event) => {
    setFreeText(event.target.value)
    console.log(freeText)
  }

  function submitArea() {
    setSelectedArea(tempArea);
    setSelectedPrefecture(tempPrefecture);
    navigatePage(1);
  }
  function submitCategory() {
    setSelectedJobCategory(tempJobCategory);
    setSelectedJobSubCategory(tempJobSubCategory);
    navigatePage(1);
  }
  function submitIndustry() {
    setSelectedIndustry(tempIndustry);
    navigatePage(1);
  }
  function submitSkill() {
    setSelectedSkill(tempSkill);
    navigatePage(1);
  }
  function submitSalary() {
    setSelectedMaxSalary(tempMaxSalary);
    setSelectedMinSalary(tempMinSalary);
    navigatePage(1);
  }
  function searchFreeText() {
    navigatePage(1);
  }
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      searchFreeText();
    }
  }


  function clearArea() {
    setTempArea([]);
    setTempPrefecture([]);
    setSelectedArea([]);
    setSelectedPrefecture([]);
  }
  function clearCategory() {
    setTempJobCategory([]);
    setTempJobSubCategory([]);
    setSelectedJobCategory([]);
    setSelectedJobSubCategory([]);
  }
  function clearIndustry() {
    setTempIndustry([]);
    setSelectedIndustry([]);
  }
  function clearSalary() {
    setTempMaxSalary("");
    setTempMinSalary("");
    setSelectedMaxSalary("");
    setSelectedMinSalary("")
  }
  function clearSkill() {
    setTempSkill([]);
    setSelectedSkill([]);
  }
  function handleCloseSalaryModal() {
    setSelectedMinSalary(tempMinSalary);
    setSelectedMaxSalary(tempMaxSalary);
  }
  function handleCloseAreaModal() {
    setSelectedArea(tempArea);
    setSelectedPrefecture(tempPrefecture);
    console.log(selectedArea);
  }
  function handleCloseCategoryModal() {
    setSelectedJobCategory(tempJobCategory);
    setSelectedJobSubCategory(tempJobSubCategory);
    console.log(selectedJobCategory);
  }
  function handleCloseIndustryModal() {
    setSelectedIndustry(tempIndustry);
  }
  function handleCloseSkillModal() {
    setSelectedSkill(tempSkill)
  }
  function numFormatter(num) {
    if(num != undefined && num !== 0 && num !== 1){
    if(num > 999999){
      return (num / 10000).toLocaleString("en-US") + '万円';
    }else if(num < 1000000 && num > 999){
      return (num/1000).toLocaleString("en-US") + '千円';
    }else if(num < 1000 && num > 99){
      return (num/100).toLocaleString("en-US") + '百円';
    }else if(num < 100){
      return (num).toLocaleString("en-US");
    }
  }
  if(num === 0){
     num = "上限なし"
     return num
  }
  if(num === 1){
    num = "応相談"
    return num
  }

  }
  function convertArray(ary) {
    let tempAry = ary;
    for (let i = 0; i < ary.length; i++) {
      ary[i] = parseInt(tempAry[i]);
    }
    return ary;
  }
  function getAllSalary(){
    axios.get(process.env.REACT_APP_BASE_URL + "salaryCondition").then((response) => {
      setSalaryList(response.data.salaryConditionList);
    }).catch(error => {
      console.log('There was an error!', error);
    });
    console.log(salaryList,"salaryList")
  }

  return (
    <div data-testid="Joblist">
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js"></script>
      <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
      <body class="d-flex flex-column h-100">

        <section id="Hotjobs" class="top-90">
          <div class="padding-body mt-3">
            <div class="sub-text"><Link className="topI" to={'/'}><a style={{ "color": "#a5a6a8" }}>{t('top')}</a></Link>  / <span>{t('all_jobs')} </span> </div>
            <div class="row d-flex align-items-center top-90">
              <div class="main-heading hot-jobhead">
                <h4>{t('all_jobs')}</h4>
              </div>
            </div>

            <div class="top-90">
              <div class="row mb-3 justify-content-center">

                <div class="col-md-3 custom-col3">
                  <div class="card mb-3 Card-box-left">
                    <div class="card-body p-0 card-left">
                      <div class="left-top text-center">
                        <h6>{t('basic_conditions')}</h6>
                      </div>

                      <div class="p-4">
                        <div class="row">
                          <ul class="list-group  list-group-flush">
                            <li
                              class="list-group-item d-flex justify-content-between align-items-start search-list">
                              <div class=" me-auto" style={{"overflow":"auto"}}>
                                <div><span>
                                  <i class="fa-solid fa-location-dot search-list-icons"></i>
                                </span> <span class="search-title">{t('search_location')}</span> </div>
                                <div className="sub-search">
                                  {area.map((areaDetails) => {
                                    return (<>
                                      {areaDetails.prefectures.map((prefectureDetails) => {
                                        return (
                                          <>
                                            {selectedPrefecture?.includes(prefectureDetails.prefectureId) ?
                                              <span style={{ "marginRight": "10px" }}> {prefectureDetails?.prefectureTitle}</span>
                                              : null}
                                          </>
                                        )
                                      })}
                                    </>)
                                  })}
                                </div>

                              </div>
                              <a href="" class="edit-icon" data-bs-toggle="modal" data-bs-target="#placesearch">
                                <i class="fa-solid fa-pen-to-square"></i></a>
                            </li>


                            <li
                              class="list-group-item d-flex justify-content-between align-items-start search-list">
                              <div class=" me-auto" style={{"overflow":"auto"}}>
                                <div><span><i class="fa-solid fa-laptop search-list-icons"></i>
                                </span><span>{t('search_job_category')}</span> </div>
                                <div className="sub-search">
                                  {category.map((categoryDetails) => {
                                    return (<>
                                      {categoryDetails.subCategoryList.map((subCategoryDetails) => {
                                        return (
                                          <>
                                            {selectedJobSubCategory?.includes(subCategoryDetails.subId) ?
                                              <span style={{ "marginRight": "10px" }}>{subCategoryDetails.subCategoryTitle}</span> : null}
                                          </>
                                        )
                                      })}
                                    </>)
                                  })}
                                </div>
                              </div>
                              <a href="" class="edit-icon" data-bs-toggle="modal" data-bs-target="#occupationsearch">
                                <i class="fa-solid fa-pen-to-square"></i></a>
                            </li>

                            <li
                              class="list-group-item d-flex justify-content-between align-items-start search-list">
                              <div class=" me-auto" style={{"overflow":"auto"}}>
                                <div><span>
                                  <i class="fa-solid fa-suitcase search-list-icons"></i></span>
                                  <span>{t('search_industry')}</span> </div>
                                <div className="sub-search">
                                  {industry.map((industryDetails) => {
                                    return (<>
                                      {selectedIndustry?.includes(industryDetails.industryId) ?
                                        <span style={{ "marginRight": "10px" }}>{industryDetails.industryTitle}</span> : null}                                  </>)
                                  })}
                                </div>
                              </div>
                              <a href="" class="edit-icon" data-bs-toggle="modal" data-bs-target="#industrysearch">
                                <i class="fa-solid fa-pen-to-square"></i></a>
                            </li>
                            <li
                              class="list-group-item d-flex justify-content-between align-items-start search-list">
                              <div class=" me-auto" style={{"overflow":"auto"}}>
                                <div> <span>
                                  <i class="fa-solid fa-yen-sign search-list-icons"></i></span>
                                  <span>{t('search_salary_range')}</span> </div>
                                <div className="sub-search">
                                  {(selectedMinSalary?.length !== 0 && selectedMaxSalary?.length === 0) &&
                                    <div id="1st" >{numFormatter(selectedMinSalary)}
                                      {  <i class="fa-solid fa-yen-sign search-list-icons" style={{ "font-size": "15px" }}></i>} </div> }
                                  {(selectedMaxSalary?.length !== 0 && selectedMinSalary?.length !== 0) &&
                                    <div id="2nd" >{numFormatter(selectedMinSalary)}  <i class="fa-solid fa-yen-sign search-list-icons"
                                      style={{ "font-size": "15px" }}></i> {t('to')} &emsp;{numFormatter(selectedMaxSalary)} <i class="fa-solid fa-yen-sign search-list-icons"
                                        style={{ "font-size": "15px" }}></i></div> }
                                  {(selectedMaxSalary?.length !== 0 && selectedMinSalary?.length === 0) &&
                                    <div id="3rd">{numFormatter(selectedMaxSalary)}  <i class="fa-solid fa-yen-sign search-list-icons" style={{ "font-size": "15px" }}></i></div> }
                                        
                                </div>
                              </div>
                              <a href="" class="edit-icon" data-bs-toggle="modal" data-bs-target="#salaryrange"><i
                                class="fa-solid fa-pen-to-square"></i></a>
                              
                            </li>

                            <li
                              class="list-group-item d-flex justify-content-between align-items-start search-list">
                              <div class=" me-auto" style={{"overflow":"auto"}}>
                                <div> <span>
                                  <i class="fa-solid fa-star search-list-icons"></i></span>
                                  <span>{t('search_skill_based')}</span> </div>
                                <div className="sub-search">
                                  {skill.map((skillDetails) => {
                                    return (<>
                                      {selectedSkill?.includes(skillDetails.skillId) ?
                                        <span style={{ "marginRight": "10px" }}>{skillDetails.skillTitle}</span> : null}                                  </>)
                                  })}
                                </div>
                              </div>
                              <a href="" class="edit-icon" data-bs-toggle="modal" data-bs-target="#skillssearch">
                                <i class="fa-solid fa-pen-to-square"></i></a>
                            </li>
                         


                            <li
                              class="list-group-item d-flex justify-content-between align-items-start search-list">
                              <div class="input-group mb-3">
                                <input type="text" class="form-control custom-input" value={freeText} id="myInputID2"
                                  placeholder={t('search_by_job')} aria-label="Freeword Search" onKeyDown={handleKeyDown}
                                  aria-describedby="basic-addon2" onChange={e => handleOnChangeFreeText(e)} />
                                <div class="input-group-text search-box" id="basic-addon2" onClick={searchFreeText}>
                                  <i class="fa-solid fa-magnifying-glass"></i>
                                </div>
                              </div>
                            </li>


                          </ul>
                        </div>
                      </div>

                    </div>


                  </div>

                </div>

                <div class="col-md-9 custom-col9">
                  <div class="row">
                    {allJobs?.length > 0 && <>
                      <p className="justify-content-between d-flex">
                         <b>{count} {t('item_count')} ({(limit*(currentPage-1)+1)} {t('item_to')} {(limit*currentPage)-(limit-allJobs.length)} {t('item_display')}) </b>
                         <b className="validation">{errorResponse}</b>
                         </p>
                      {isLoadedAllJobs &&
                        <div>
                          {allJobs.map((jobList) => {
                            return (
                              <div class="card job-list-card ">
                                <div class="card-body p-4">
                                  <div class="row">
                                    <div class="col-md-12 col-custom10">
                                      <h5>{jobList?.jobTitle}</h5>

                           <div  class="row pt-4 colr-catch-font "><span className='saperate-text '>{ jobList?.catchPhrase}</span></div>
                          
                                <div className="row pt-4">
                                  {/* <div className="col-md-3 job-col2">
                                    <div className="icons-btm d-flex" > <div><i className="fas fa-building yen-font pe-2"></i>  </div>
                                   <div className="table-text"><span
                                    data-toggle="tooltip" data-placement="bottom" title={jobList?.companyName} className="font-16">{jobList.companyName}</span></div></div>
                                  </div> */}
                                  {(jobList?.prefectureName)?<div className="col-md-4 job-col2">
                                    <div className="icons-btm d-flex" > <div><i className="fa fa-map-marker-alt yen-font pe-2"></i> </div>
                                   <div className="table-text"> <span
                                      className="font-16"  data-toggle="tooltip" data-placement="bottom" title={jobList?.prefectureName}>{jobList.prefectureName}</span></div></div>
                                  </div>:<></>}
                                  {(jobList?.industryName)?<div className="col-md-4 job-col2">
                                    <div className="icons-btm d-flex" > <div><i className="fa fa-suitcase yen-font pe-2"></i> </div>
                                   <div className="table-text"> <span
                                    data-toggle="tooltip" data-placement="bottom" title={jobList?.industryName} className="font-16">{jobList.industryName}</span></div></div>
                                  </div>:<></>} 
                                  {(jobList?.minSalary)?<div className="col-md-4 job-col2">
                                    <div className="icons-btm d-flex" > <div><i className="fa-solid fa-yen-sign yen-font pe-2"></i> </div>
                                   <div className="table-text"> <span
                                    data-toggle="tooltip" data-placement="bottom" title={numFormatter(jobList.minSalary) +" - "+ numFormatter(jobList.maxSalary)}
                                     className="font-16 yen-color">{numFormatter(jobList.minSalary)} - {numFormatter(jobList.maxSalary)}</span></div></div>
                                  </div>:<></>}
                                </div>

                                      <div class="row ps-2 my-3">
                                        {jobList.tags.map((tagDetails) => {
                                          return (
                                            <button className="tag-bg mb-2">{tagDetails?.tagName}</button>
                                          )
                                        })}
                                      </div>

                                    </div>

                                    <hr />

                                    <div class="col-md-12 py-3">
                                      <p className="description-text">{jobList.description}</p>
                                    </div>

                                    <div class="col-md-12  text-center mt-4">
                                      <Link to={"/job-details/" + jobList.jobId}>
                                        <button className="job-details"> {t('learn_more')} </button>
                                      </Link>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            )
                          })}

                          <div class="col-md-12 my-5">
                            <nav aria-label="Page navigation example">
                              <ul class="pagination justify-content-end">
                                <Pagination
                                  className="pagination-bar"
                                  currentPage={currentPage}
                                  totalCount={count}
                                  pageSize={limit}
                                  onPageChange={page => pageChange(page)}
                                />
                              </ul>
                            </nav>
                          </div>
                        </div> }{!isLoadedAllJobs && <Spinner></Spinner>}
                    </> }{allJobs?.length  === 0 && <div><h6 style={{ "textAlign": "center", "fontWeight": "bold", "lineHeight": "400px" }}>{t('no_results')}</h6></div>}
                  </div>

                </div>
              </div>

            </div>
          </div>
        </section>
        {/* MODAL AREA */}

        <div className="modal fade" id="placesearch" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
          aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered  modal-lg">

         <div className="modal-content">
              <div className="modal-header border-none">
                <div className="col-md-12 text-center mt-60">
                  <div className="main-heading hot-jobhead">
                    <h4>{t('search_by_place')}</h4>
                  </div>
                </div>
                <button type="button" className="Close-btn" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseAreaModal}><i
                  className="fa-solid fa-xmark"></i></button>
              </div>

              <div className="modal-body p-0">
                <div className="search-area">
                  {area.map((areaDetails) => {
                    return (
                      <div>
                        <div className="row">
                       {areaDetails.prefectures.length > 0 ?     <div className="popup-head mt-3 mb-3 d-flex">
                            <h6>{areaDetails.areaTitle}</h6>
                            <input className="form-check-input form-chck ms-2" type="checkbox" onChange={e => handleOnChangeArea(e, areaDetails.prefectures)}
                              id={"area" + areaDetails.id} value={areaDetails.id} checked={tempArea?.includes(areaDetails.id)} />
                          </div> : null}
                        </div>
                        <div>
                          {areaDetails.prefectures.map((prefectureDetails) => {
                            return (
                              <div className="form-check form-check-inline mb-2">
                                <input className="form-check-input form-chck" type="checkbox" onChange={e => handleOnChangePrefecture(e, areaDetails.prefectures, areaDetails.id)}
                                  id={"prefecture" + prefectureDetails.prefectureId} value={prefectureDetails.prefectureId} checked={tempPrefecture?.includes(prefectureDetails.prefectureId)} />
                                <label className="form-check-label ps-2" for="inlineCheckbox1"
                                 data-toggle="tooltip" data-placement="bottom" title={prefectureDetails?.prefectureTitle}>{prefectureDetails.prefectureTitle}</label>
                              </div>)
                          })}
                        </div>

                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="modal-footer border-none ">
                <div className="col-md-12 text-center text-col12">
                  <button className="cancel-btn" onClick={clearArea}>{t('clear')}</button>
                  <button className="main-buttons sub-btn" data-bs-dismiss="modal" onClick={submitArea}>{t('submit')}</button>
                </div>
              </div>
            </div>

          </div>
        </div>

        {/* MODAL CATEGORY */}

        <div class="modal fade" id="occupationsearch" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered  modal-lg">
            <div class="modal-content">
              <div class="modal-header border-none">
                <div class="col-md-12 text-center mt-60">
                  <div class="main-heading hot-jobhead"><h4>{t('search_by_job_category')}</h4>
                  </div>
                </div>
                <button type="button" class="Close-btn" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseCategoryModal}><i class="fa-solid fa-xmark"></i></button>
              </div>

              <div className="modal-body p-0">
                <div className="search-area">
                  {category.map((categoryDetails) => {
                    return (
                      <div>
                        <div className="row">
                       { categoryDetails.subCategoryList.length > 0 ?    <div className="popup-head mt-3 mb-3 d-flex">
                            <h6>{categoryDetails.categoryTitle}</h6>
                            <input className="form-check-input form-chck ms-2" type="checkbox" onChange={e => handleOnChangeJobCategory(e, categoryDetails.subCategoryList)}
                              id={"category" + categoryDetails.id} value={categoryDetails.id} checked={tempJobCategory?.includes(categoryDetails.id)} />
                          </div>:null}
                        </div>
                        <div>
                          {categoryDetails.subCategoryList.map((subCategoryDetails) => {
                            return (
                              <div className="form-check form-check-inline mb-2">
                                <input className="form-check-input form-chck" type="checkbox" onChange={e => handleOnChangeJobSubCategory(e, categoryDetails.subCategoryList, categoryDetails.id)}
                                  id={"subCategory" + subCategoryDetails.subId} value={subCategoryDetails.subId} checked={tempJobSubCategory?.includes(subCategoryDetails.subId)} />
                                <label className="form-check-label ps-2" for="inlineCheckbox1"
                                 data-toggle="tooltip" data-placement="bottom" title={subCategoryDetails?.subCategoryTitle}>{subCategoryDetails.subCategoryTitle}</label>
                              </div>)
                          })}
                        </div>
                      </div>)
                  })}

                </div>
              </div>
              <div class="modal-footer border-none ">
                <div class="col-md-12 text-center text-col12">
                  <button class="cancel-btn" onClick={clearCategory}>{t('clear')}</button>
                  <button className="main-buttons sub-btn" data-bs-dismiss="modal" onClick={submitCategory}>{t('submit')}</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* MODAL INDUSTRY */}

        <div class="modal fade" id="industrysearch" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered  modal-lg">
            <div class="modal-content">
              <div class="modal-header border-none">
                <div class="col-md-12 text-center mt-60">
                  <div class="main-heading hot-jobhead"><h4>{t('search_by_industry')}</h4>
                  </div>
                </div>
                <button type="button" class="Close-btn" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseIndustryModal}><i class="fa-solid fa-xmark"></i></button>
              </div>
              <div class="modal-body p-0">
                <div class="search-area">
                  <div>
                    <div>
                      <div class="row">
                        <div>
                          {industry.map((industryDetails) => {
                            return (
                              <div class="form-check form-check-inline mb-2">
                                <input class="form-check-input form-chck" type="checkbox" checked={tempIndustry?.includes(industryDetails.industryId)}
                                  id={"industry" + industryDetails.industryId} value={industryDetails.industryId} onChange={e => handleOnChangeIndustry(e)} />
                                <label class="form-check-label ps-2"  data-toggle="tooltip" data-placement="bottom" title={industryDetails?.industryTitle}
                                  for="inlineCheckbox1">{industryDetails.industryTitle}</label>
                              </div>)
                          })}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer border-none ">
                  <div class="col-md-12 text-center text-col12">
                    <button class="cancel-btn" onClick={clearIndustry}>{t('clear')}</button>
                    <button class="main-buttons sub-btn" data-bs-dismiss="modal" onClick={submitIndustry}>{t('submit')}</button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        {/* MODAL SALARY */}

        <div class="modal fade" id="salaryrange" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered  modal-lg">
            <div class="modal-content">
              <div class="modal-header border-none">
                <div class="col-md-12 text-center mt-60">
                  <div class="main-heading hot-jobhead"><h4>{t('search_by_salary_range')}</h4>
                  </div>
                </div>
                <button type="button" class="Close-btn" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseSalaryModal}><i class="fa-solid fa-xmark"></i></button>
              </div>
              <div class="modal-body p-0">
                <div class="search-area">
                  <div>
                    <div class="search-area2">
                      <div>
                      <div>
                      {salaryList?.map((sub, index) => (
                        <>
                          <div className="form-check form-check-inline mb-3">
                            <input
                              className="form-check-input form-chck"
                              type="radio"
                              name="radioLOc"
                              id={sub?.salaryId}
                              value={sub?.salary}
                              onChange={e => handleOnChangeMinSalary(e)}
                              checked={tempMinSalary == sub.salary}
                            />
                      
                            <label
                              className="form-check-label form-label-width ps-2"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title={sub?.salaryString}
                              for="inlineCheckbox1"
                            >
                              {sub?.salaryString}
                            </label>
                          </div>
                        </>
                      ))}
                    </div>
                        {/* <div class="row">
                          <div class="col-md-4">
                            <div class="mb-3">
                              <label for="name" class="form-label input-label">{t('lower_range')}</label>
                              <input type="number" class="form-control custom-input" onChange={e => handleOnChangeMinSalary(e)}
                              placeholder="1 - 999999999" maxLength="9"   id="myInputID2" aria-describedby="emailHelp" value={tempMinSalary} 
                              onKeyDown={(e) =>alphabets.includes(e.key) && e.preventDefault()} />
                            </div>

                          </div>
                          <div class="col-md-4">
                            <div class="mb-3">
                              <label for="name" class="form-label input-label">{t('upper_range')}</label>
                              <input type="number" class="form-control custom-input" onChange={e => handleOnChangeMaxSalary(e)}
                             placeholder="1 - 999999999" maxLength="9"   id="myInputID2" aria-describedby="emailHelp" value={tempMaxSalary} 
                             onKeyDown={(e) =>alphabets.includes(e.key) && e.preventDefault()} />
                            </div>
                          </div>
                        </div> */}
                        {/* <div className="row mb-2 mt-3 px-3">
                            <div className="mb-3">
                                <label for="name" className="form-label input-label">{t('lower_range')}</label>
                            
                            <Select aria-label="Default select example" placeholder= {t('lower_range')}  style={{"height":"60px","paddingLeft":"10px"}}
                    name="id" options={salaryList} 
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: '#CBCBCB',
                        boxShadow: 'none',
                        '&:hover': {
                            border: '1px solid #86b7fe',
                        },
                        height : "60px",
                        borderRadius:"6px",
                        }),
                        
                                            
                    }} 
                    noOptionsMessage={() => t('no_results')}  >
                  
                  </Select>
               
                            </div>
                        </div> */}
                        {/* {( salaryError && tempMaxSalary && tempMinSalary && !isDecimalmin && !isDecimalmax) &&
                                 <div class="mb-2">
                                 <label for="name" class="form-label input-label" style={{ "color": "red" }}>{t('salary_error')}</label>
                            </div>}
                            {(isDecimalmin || isDecimalmax) &&   <div class="mb-2">
                                 <label for="name" class="form-label input-label" style={{ "color": "red" }}>{t('salary_error2')}</label>
                            </div>}
                            {( sameSalaryError) &&
                                 <div className="error">{t('same_salary')}</div> } */}
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer border-none ">
                <div class="col-md-12 text-center text-col12">
                  <button class="cancel-btn" onClick={clearSalary}>{t('clear')}</button>
                  <button class="main-buttons sub-btn" data-bs-dismiss="modal" onClick={submitSalary}>{t('submit')}</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* MODAL SKILL */}

        <div class="modal fade" id="skillssearch" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered  modal-lg">
            <div class="modal-content">
              <div class="modal-header border-none">
                <div class="col-md-12 text-center mt-60">
                  <div class="main-heading hot-jobhead"><h4>{t('search_by_skill')}</h4>
                  </div>
                </div>
                <button type="button" class="Close-btn" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseSkillModal}><i class="fa-solid fa-xmark"></i></button>
              </div>
              <div class="modal-body p-0">
                <div class="search-area">
                  <div>
                      <div class="row">
                        <div>
                          {skill.map((skillDetails) => {
                            return (
                              <div class="form-check form-check-inline mb-2">
                                <input class="form-check-input form-chck" type="checkbox" checked={tempSkill?.includes(skillDetails.skillId)}
                                  id={"industry" + skillDetails.skillId} value={skillDetails.skillId} onChange={e => handleOnChangeSkill(e)} />
                                <label class="form-check-label ps-2"  data-toggle="tooltip" data-placement="bottom" title={skillDetails?.skillTitle}
                                  for="inlineCheckbox1">{skillDetails.skillTitle}</label>
                              </div>)
                          })}
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer border-none ">
                <div class="col-md-12 text-center text-col12">
                  <button class="cancel-btn" onClick={clearSkill}>{t('clear')}</button>
                  <button class="main-buttons sub-btn" data-bs-dismiss="modal" onClick={submitSkill}>{t('submit')}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <script src="js/bootstrap.bundle.js"></script>

      </body>
      <ToastContainer
        position="bottom-right"
        autoClose={800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
    </div>
  )
}

export default Joblist;
