import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import axios from "axios";
import { Link } from 'react-router-dom';
import Spinner from "../spinner/spinner";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { actions } from "../../redux/companyReducer";
import { useDispatch } from 'react-redux';
const Jobsearch = () => {
  const { t } = useTranslation();
  const limit = 4;
  const page = 1;
  const [newJobs, setNewJobs] = useState([]);
  const [area, setArea] = useState([]);
  const [category, setCategory] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [salary, setSalary] = useState([]);
  const [skill, setSkill] = useState([]);
  const [isLoadedNewJobs, setIsLoadedNewJobs] = useState(false);
  const [isDecimalmin, setIsDecimalMin] =  useState(false);
  const [isDecimalmax, setIsDecimalMax] =  useState(false);
  const [salaryError, setSalaryError] = useState(false);
  const [sameSalaryError, setSameSalaryError] = useState(false);

  const [selectedPrefecture, setSelectedPrefecture] = useState([])
  const [selectedArea, setSelectedArea] = useState([]);
  const [selectedJobCategory, setSelectedJobCategory] = useState([]);
  const [selectedJobSubCategory, setSelectedJobSubCategory] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selectedMaxSalary, setSelectedMaxSalary] = useState([]);
  const [selectedMinSalary, setSelectedMinSalary] = useState([]);
  const [salaryList, setSalaryList] = useState()
  const maxValue = 999999999;
  const minValue = 0;
  const alphabets = [
    'a','b','c','d','e','f','g','h','i','j','k','l', 'm','n','o','p','q','r','s','t','u','v','w','x','y','z',
    'A','B','C','D','E', 'F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z',
    '+','-','*','/','!','@','#','$','%','^',"&",'(',')','-','_','"','{','}','`','~','/',',','<','>','?',';',':'
    ];
    const dispatch = useDispatch();
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    dispatch(actions.setuserDetails({}));
    getNewJobs();
    getCategory();
    getArea();
    getIndustry();
    getSalary();
    getSkill();
    getAllSalary();
    // eslint-disable-next-line 
  }, []);

  function getNewJobs() {
    axios.get(process.env.REACT_APP_BASE_URL + "job/list?limit=" + limit + "&page=" + page).then((response) => {
      setNewJobs(response.data.data.jobList);
      setIsLoadedNewJobs(true);
      console.log(newJobs);
    }).catch(error => {
      setIsLoadedNewJobs(true);
      console.log('There was an error!', error);
    });
  }
  function getArea() {
    axios.get(process.env.REACT_APP_BASE_URL + "category/area").then((response) => {
      setArea(response.data.areaList);
      console.log(area);
    }).catch(error => {
      console.log('There was an error!', error);
    });
  }
  function getCategory() {
    axios.get(process.env.REACT_APP_BASE_URL + "category").then((response) => {
      setCategory(response.data.categoryList);
      console.log(category);
    }).catch(error => {
      console.log('There was an error!', error);
    });
  }
  function getIndustry() {
    axios.get(process.env.REACT_APP_BASE_URL + "industry").then((response) => {
      setIndustry(response.data.industryList);
      console.log(industry);
    }).catch(error => {
      console.log('There was an error!', error);
    });
  }
  function getSalary() {
    axios.get(process.env.REACT_APP_BASE_URL + "salary").then((response) => {
      setSalary(response.data);
      console.log(salary);
    }).catch(error => {
      console.log('There was an error!', error);
    });
  }
  function getSkill() {
    axios.get(process.env.REACT_APP_BASE_URL + "skill").then((response) => {
      setSkill(response.data.skillList);
      console.log(skill);
    }).catch(error => {
      console.log('There was an error!', error);
    });
  }
  const handleOnChangeArea = (event, prefectures) => {
    console.log(prefectures)
    if (event.target.checked) {
      console.log("checked")
      prefectures.map((prefectureDetails) => {
        setSelectedPrefecture(selectedPrefecture => [parseInt(prefectureDetails.prefectureId), ...selectedPrefecture]);
        return 0;
      })
      setSelectedArea(selectedArea => [parseInt(event.target.value), ...selectedArea]);
      console.log(selectedArea);
    } else if (!event.target.checked) {
      console.log("unchecked")
      prefectures.map((prefectureDetails) => {
        setSelectedPrefecture(current =>
          current.filter(item => {
            return item !== parseInt(prefectureDetails.prefectureId);
          }),);
        return 0;
      })
      setSelectedArea(current =>
        current.filter(item => {
          return item !== parseInt(event.target.value);
        }),);
      console.log(selectedArea);
    }
  };
  const handleOnChangePrefecture = (event, prefectures, areaId) => {
    if (event.target.checked) {
      console.log("checked")
      setSelectedPrefecture(selectedPrefecture => [parseInt(event.target.value), ...selectedPrefecture]);
      console.log(selectedPrefecture);
      let flag;
      for (let i = 0; i < prefectures.length; i++) {
        if (document.getElementById("prefecture" + prefectures[i].prefectureId).checked === true) {
          flag = 1;
          console.log(flag)
        } else {
          flag = 0;
          break;
        }
      }
      if (flag === 1) {
        setSelectedArea(selectedArea => [parseInt(areaId), ...selectedArea]);
      }
    } else if (!event.target.checked) {
      console.log("unchecked")
      setSelectedPrefecture(current =>
        current.filter(item => {
          return item !== parseInt(event.target.value);
        }),);
      setSelectedArea(current =>
        current.filter(item => {
          return item !== parseInt(areaId);
        }),);
    }
  };
  const handleOnChangePrefectureModal = (event, prefectures, areaId) => {
    if (event.target.checked) {
      console.log("checked")
      setSelectedPrefecture(selectedPrefecture => [parseInt(event.target.value), ...selectedPrefecture]);
      console.log(selectedPrefecture);
      let flag;
      for (let i = 0; i < prefectures.length; i++) {
        if (document.getElementById("modalprefecture" + prefectures[i].prefectureId).checked === true) {
          flag = 1;
          console.log(flag)
        } else {
          flag = 0;
          break;
        }
      }
      if (flag === 1) {
        setSelectedArea(selectedArea => [parseInt(areaId), ...selectedArea]);
      }
    } else if (!event.target.checked) {
      console.log("unchecked")
      setSelectedPrefecture(current =>
        current.filter(item => {
          return item !== parseInt(event.target.value);
        }),);
      setSelectedArea(current =>
        current.filter(item => {
          return item !== parseInt(areaId);
        }),);
    }
  };
  const handleOnChangeJobCategory = (event, jobSubCategory) => {
    console.log(jobSubCategory)
    if (event.target.checked) {
      console.log("checked")
      jobSubCategory.map((jobSubCategoryDetails) => {
        setSelectedJobSubCategory(selectedJobSubCategory => [parseInt(jobSubCategoryDetails.subId), ...selectedJobSubCategory]);
        return 0;
      })
      setSelectedJobCategory(selectedJobCategory => [parseInt(event.target.value), ...selectedJobCategory]);
      console.log(selectedJobCategory);
    } else if (!event.target.checked) {
      console.log("unchecked")
      jobSubCategory.map((jobSubCategoryDetails) => {
        setSelectedJobSubCategory(current =>
          current.filter(item => {
            return item !== parseInt(jobSubCategoryDetails.subId);
          }),);
        return 0;
      })
      setSelectedJobCategory(current =>
        current.filter(item => {
          return item !== parseInt(event.target.value);
        }),);
      console.log(selectedJobCategory);
    }
  };
  const handleOnChangeJobSubCategory = (event, subCategory, categoryId) => {
    if (event.target.checked) {
      console.log("checked")
      setSelectedJobSubCategory(selectedJobSubCategory => [parseInt(event.target.value), ...selectedJobSubCategory]);
      console.log(selectedJobSubCategory);
      let flag;
      for (let i = 0; i < subCategory.length; i++) {
        if (document.getElementById("subCategory" + subCategory[i].subId).checked === true) {
          flag = 1;
          console.log(flag)
        } else {
          flag = 0;
          break;
        }
      }
      if (flag === 1) {
        setSelectedJobCategory(selectedJobCategory => [parseInt(categoryId), ...selectedJobCategory]);
      }
    } else if (!event.target.checked) {
      console.log("unchecked")
      setSelectedJobSubCategory(current =>
        current.filter(item => {
          return item !== parseInt(event.target.value);
        }),);
      setSelectedJobCategory(current =>
        current.filter(item => {
          return item !== parseInt(categoryId);
        }),);
    }
  };
  const handleOnChangeJobSubCategoryModal = (event, subCategory, categoryId) => {
    if (event.target.checked) {
      console.log("checked")
      setSelectedJobSubCategory(selectedJobSubCategory => [parseInt(event.target.value), ...selectedJobSubCategory]);
      console.log(selectedJobSubCategory);
      let flag;
      for (let i = 0; i < subCategory.length; i++) {
        if (document.getElementById("modalsubCategory" + subCategory[i].subId).checked === true) {
          flag = 1;
          console.log(flag)
        } else {
          flag = 0;
          break;
        }
      }
      if (flag === 1) {
        setSelectedJobCategory(selectedJobCategory => [parseInt(categoryId), ...selectedJobCategory]);
      }
    } else if (!event.target.checked) {
      console.log("unchecked")
      setSelectedJobSubCategory(current =>
        current.filter(item => {
          return item !== parseInt(event.target.value);
        }),);
      setSelectedJobCategory(current =>
        current.filter(item => {
          return item !== parseInt(categoryId);
        }),);
    }
  };
  const handleOnChangeIndustry = (event) => {
    if (event.target.checked) {
      setSelectedIndustry(selectedIndustry => [parseInt(event.target.value), ...selectedIndustry]);
      console.log(selectedIndustry);
    } else if (!event.target.checked) {
      setSelectedIndustry(current =>
        current.filter(item => {
          return item !== parseInt(event.target.value);
        }),);
      console.log(selectedIndustry)
    }
  };
  const handleOnChangeMaxSalary = (event) => {
    if(event.target.value.length > 9){
      return
    }
    console.log(event.target.value)
    salaryCheck(selectedMinSalary,event.target.value);
    if (!event.target.value) {
      setSelectedMaxSalary("");
    } else {
      validateDecimalMax(event.target.value);
      if (event.target.value < minValue) {
        event.target.value = minValue;

      } else if (event.target.value > maxValue) {
        event.target.value = event.target.value.slice(0, 10);

      }
      setSelectedMaxSalary(event.target.value);
    }
  }
  const handleOnChangeMinSalary = (event) => {
    setSelectedMinSalary(event.target.value);
    // if(event.target.value.length > 9){
    //   return
    // }
    // salaryCheck(event.target.value,selectedMaxSalary);
    // console.log(selectedMaxSalary)
    // if (!event.target.value) {
    //   setSelectedMinSalary("");
    // } else {
    //   validateDecimalMin(event.target.value);
    //   if (event.target.value < minValue) {
    //     event.target.value = minValue;

    //   } else if (event.target.value > maxValue) {
    //     event.target.value = event.target.value.slice(0, 10);

    //   }
    //   setSelectedMinSalary(event.target.value);
    //   console.log(selectedMinSalary);
    // }
  };
  function salaryCheck(min,max){
    if(parseInt(min) > parseInt(max)){
      console.log(min," ",max)
      console.log("true")
      setSalaryError(true);
      setSameSalaryError(false);   
    }else if(parseInt(min) < parseInt(max)){
      console.log(min," ",max)
      console.log("false")
      setSalaryError(false);
      setSameSalaryError(false);        
    }else if(parseInt(min) === parseInt(max)){
      console.log(min," ",max)
      console.log("false")
      setSalaryError(false);
      setSameSalaryError(true);   
    }   
  }
  function validateDecimalMax(num) {
    let value = num;
    let regex = /^[-+]?[0-9]+\.[0-9]+$/;
    let isValidated = regex.test(value);
    if (isValidated) {
      setIsDecimalMax(true);
    } else {
      setIsDecimalMax(false);
    }
 }
 function validateDecimalMin(num) {
  let value = num;
  let regex = /^[-+]?[0-9]+\.[0-9]+$/;
  let isValidated = regex.test(value);
  if (isValidated) {
    setIsDecimalMin(true);
  } else {
    setIsDecimalMin(false);
  }
}
  const handleOnChangeSkill = (event) => {
    if (event.target.checked) {
      setSelectedSkill(selectedSkill => [parseInt(event.target.value), ...selectedSkill]);
      console.log(selectedSkill);
    } else if (!event.target.checked) {
      setSelectedSkill(current =>
        current.filter(item => {
          return item !== parseInt(event.target.value);
        }),);
      console.log(selectedSkill)
    }
  };
  function clearArea() {
    setSelectedArea([]);
    setSelectedPrefecture([]);
  }
  function clearCategory() {
    setSelectedJobCategory([]);
    setSelectedJobSubCategory([]);
  }
  function clear() {
    setSelectedArea([]);
    setSelectedJobCategory([]);
    setSelectedJobSubCategory([]);
    setSelectedIndustry([]);
    setSelectedSkill([]);
    setSelectedMinSalary([]);
    setSelectedMaxSalary([]);
  }
  function numFormatter(num) {
    if(num != undefined && num !== 0 && num !== 1){
      if(num > 999999){
        return (num / 10000).toLocaleString("en-US") + '万円';
      }else if(num < 1000000 && num > 999){
        return (num/1000).toLocaleString("en-US") + '千円';
      }else if(num < 1000 && num > 99){
        return (num/100).toLocaleString("en-US") + '百円';
      }else if(num < 100){
        return (num).toLocaleString("en-US");
      }
    }
    if(num === 0){
       num = "上限なし"
       return num
    }
    if(num === 1){
      num = "応相談"
      return num
    }
  }
  function getAllSalary(){
    axios.get(process.env.REACT_APP_BASE_URL + "salaryCondition").then((response) => {
      setSalaryList(response.data.salaryConditionList);
    }).catch(error => {
      console.log('There was an error!', error);
    });
    console.log(salaryList,"salaryList")
  }
  return (
    <div data-testid="Jobsearch">
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js"></script>
      <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
      <div class="banner-search middle-toparea">
        <div class="container-fluid padding-body">
          <div class="sub-menus"><Link className="topI" to={'/'}><a style={{ "color": "#DBDBDB" }}>{t('top')}</a></Link> / <span>{t('job_search')}</span> </div>

          <div class="col-md-12 text-center">
            <div class="job-header">
              <h3>{t('job_search')}</h3>
            </div>
            <p class="text-white ">ご希望の勤務地、職種から求人の検索が可能です。</p>

            <div class="col-md-12 mt-custom-5">
              <button class="place-btn" data-bs-toggle="modal" data-bs-target="#placesearch">{t('place')}</button>
              <button class="Occupation-btn" data-bs-toggle="modal"
                data-bs-target="#occupationsearch">{t('occupation')}</button>
            </div>
          </div>
        </div>

      </div>


<div  className="top-90">
      {newJobs.length > 0 ? <section id="Hotjobs">
        <div className="padding-body">
          <div className="row d-flex align-items-center">
            <div className="main-heading hot-jobhead">
              <h4>{t('new_jobs')}</h4>
            </div>
          </div>
          <div className="top-90">
            <div className="row mb-3 justify-content-center">
              {isLoadedNewJobs ? <div className="col-md-10">

                {newJobs.map((jobDetails) => {
                  return (
                    <div className="card job-card">
                      <div className="card-body p-4">
                        <div className="row">
                          <div className="col-md-10 col-custom10">
                            <h5>{jobDetails.jobTitle}</h5>
                            <p className="description-text">{jobDetails.description}</p>
                            <div className="row ps-2">
                              {jobDetails.tags?.map((tagDetails)=>{return(
                        <button className="tag-bg mb-2">{tagDetails?.tagName}</button>
                        )})}
                            </div>
                          
                            <div  class="row pt-3 colr-catch-font "><span className='saperate-text '>{jobDetails?.catchPhrase}</span></div>
                            <div className="row pt-4">
                                  {/* <div className="col-md-3 job-col2">
                                    <div className="icons-btm d-flex" ><div> <i className="fas fa-building yen-font pe-2"></i>  </div>
                                   <div className="table-text"><span
                                    data-toggle="tooltip" data-placement="bottom" title={jobDetails?.companyName} className="font-16">{jobDetails.companyName}</span></div></div>
                                  </div> */}
                                  {(jobDetails?.prefectureName)? <div className="col-md-4 job-col2">
                                    <div className="icons-btm d-flex" > <div><i className="fa fa-map-marker-alt yen-font pe-2"></i>  </div>
                                   <div className="table-text"><span
                                      className="font-16"  data-toggle="tooltip" data-placement="bottom" title={jobDetails?.prefectureName}>{jobDetails.prefectureName}</span></div>
                                  </div>
                                  </div>:<></>}
                                  {(jobDetails?.industryName)?<div className="col-md-4 job-col2">
                                    <div className="icons-btm d-flex" ><div> <i className="fa fa-suitcase yen-font pe-2"></i>  </div>
                                   <div className="table-text"><span
                                    data-toggle="tooltip" data-placement="bottom" title={jobDetails?.industryName} className="font-16">{jobDetails.industryName}</span></div></div>
                                  </div>:<></>}
                                  {(jobDetails?.minSalary)?  <div className="col-md-4 job-col2">
                                    <div className="icons-btm d-flex" ><div> <i className="fa-solid fa-yen-sign yen-font pe-2"></i>  </div>
                                   <div className="table-text"><span
                                    data-toggle="tooltip" data-placement="bottom" title={numFormatter(jobDetails.minSalary) +" - "+ numFormatter(jobDetails.maxSalary)}
                                     className="font-16 yen-color">{numFormatter(jobDetails.minSalary)} - {numFormatter(jobDetails.maxSalary)}</span></div></div>
                                  </div>:<></>}
                                
                                </div>
                              
                          </div>

                          <div className="col-md-2 m-auto text-center col-custom-2">
                            <Link to={"/job-details/" + jobDetails.jobId}>
                              <button className="job-details"> {t('job_details')} </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}

                <div className="text-center">
                  <Link to={"/job-list/1?area="+selectedArea+"&prefecture="+selectedPrefecture+"&category="+selectedJobCategory+"&subCategory="+selectedJobSubCategory+
                    "&industry="+selectedIndustry+"&skill="+selectedSkill+"&maxSalary="+selectedMaxSalary+"&minSalary="+selectedMinSalary}>
                    <button className="main-buttons"> {t('view_all_jobs')}</button>
                  </Link>
                </div>
              </div> : <Spinner></Spinner>}
            </div>
          </div>
        </div>
      </section> : null}

      <section id="Hotjobs">
        <div class="padding-body">
          <div class="row d-flex align-items-center">
            <div class="main-heading hot-jobhead">
              <h4>{t('engineer_job_list')}</h4>
            </div>
          </div>

          <div class="top-90">
            <div class="row mb-3 justify-content-center">
              <div class="col-md-10">
                <div class="card mt-3 Card-box-search">
                  <div class="card-body p-0 card-search-custom">

                    <div>
                      <div class="heading-searching">
                        <h5>{t('location')}</h5>
                      </div>
                      <div class="search-area2">
                        {area.map((areaDetails) => {
                          return (<div>
                            <div class="row">
                           { areaDetails.prefectures.length > 0 ?    <div class="popup-head mt-3 mb-3 d-flex">
                                <h6>{areaDetails.areaTitle}</h6>
                                <input className="form-check-input form-chck ms-2" type="checkbox" onChange={e => handleOnChangeArea(e, areaDetails.prefectures)}
                                  id={"area" + areaDetails.id} value={areaDetails.id} checked={selectedArea.includes(areaDetails.id)} />
                              </div>:null}
                            </div>
                            <div>
                              {areaDetails.prefectures.map((prefectureDetails) => {
                                return (
                                  <div className="form-check form-check-inline mb-2">
                                    <input className="form-check-input form-chck" type="checkbox" onChange={e => handleOnChangePrefecture(e, areaDetails.prefectures, areaDetails.id)}
                                      id={"prefecture" + prefectureDetails.prefectureId} value={prefectureDetails.prefectureId} checked={selectedPrefecture.includes(prefectureDetails.prefectureId)} />
                                    <label className="form-check-label2 ps-2" for="inlineCheckbox1">{prefectureDetails.prefectureTitle}</label>
                                  </div>)
                              })}
                            </div>
                          </div>)
                        })}


                      </div>
                    </div>

                    <div>
                      <div class="heading-searching">
                        <h5>{t('job_category')}</h5>
                      </div>
                      <div class="search-area2">
                        {category.map((categoryDetails) => {
                          return (
                            <div>
                              <div class="row">
                              { categoryDetails.subCategoryList.length > 0 ?   <div class="popup-head mt-3 mb-3 d-flex">
                                  <h6>{categoryDetails.categoryTitle}</h6>
                                  <input className="form-check-input form-chck ms-2" type="checkbox" onChange={e => handleOnChangeJobCategory(e, categoryDetails.subCategoryList)}
                                    id={"category" + categoryDetails.id} value={categoryDetails.id} checked={selectedJobCategory.includes(categoryDetails.id)} />
                                </div> : null}
                              </div>
                              <div>
                                {categoryDetails.subCategoryList.map((subCategoryDetails) => {
                                  return (
                                    <div class="form-check form-check-inline mb-2">
                                      <input className="form-check-input form-chck" type="checkbox" onChange={e => handleOnChangeJobSubCategory(e, categoryDetails.subCategoryList, categoryDetails.id)}
                                        id={"subCategory" + subCategoryDetails.subId} value={subCategoryDetails.subId} checked={selectedJobSubCategory.includes(subCategoryDetails.subId)} />
                                      <label className="form-check-label2 ps-2" for="inlineCheckbox1">{subCategoryDetails.subCategoryTitle}</label>
                                    </div>)
                                })}
                              </div>
                            </div>)
                        })}

                      </div>
                    </div>

                    <div>
                      <div class="heading-searching">
                        <h5>{t('industry')}</h5>
                      </div>
                      <div class="search-area2">
                        <div>
                          <div class="row">
                            <div>
                              {industry.map((industryDetails) => {
                                return (
                                  <div class="form-check form-check-inline mb-2">
                                    <input class="form-check-input form-chck" type="checkbox" checked={selectedIndustry.includes(industryDetails.industryId)}
                                      id={"industry" + industryDetails.industryId} value={industryDetails.industryId} onChange={e => handleOnChangeIndustry(e)} />
                                    <label class="form-check-label2 ps-2"
                                      for="inlineCheckbox1">{industryDetails.industryTitle}</label>
                                  </div>)
                              })}

                            </div>
                          </div>

                        </div>
                      </div>

                      <div>
                        <div class="heading-searching">
                          <h5>{t('salary_range')}</h5>
                        </div>
                        <div class="search-area2">
                          <div>
                            {/* <div class="row">
                              <div class="col-md-4">
                                <div class="mb-3">
                                  <label for="name" class="form-label input-label">{t('lower_range')}</label>
                                  <input type="number" class="form-control custom-input" onChange={e => handleOnChangeMinSalary(e)}
                                   placeholder="1 - 999999999" maxLength="9" id="myInputID2" aria-describedby="emailHelp" value={selectedMinSalary}
                                   onKeyDown={(e) =>alphabets.includes(e.key) && e.preventDefault()}  />
                                </div>

                              </div>
                              <div class="col-md-4">
                                <div class="mb-3">
                                  <label for="name" class="form-label input-label">{t('upper_range')}</label>
                                  <input type="number" class="form-control custom-input" onChange={e => handleOnChangeMaxSalary(e)}
                                  placeholder="1 - 999999999" maxLength="9"  id="myInputID2" aria-describedby="emailHelp" value={selectedMaxSalary} 
                                  onKeyDown={(e) =>alphabets.includes(e.key) && e.preventDefault()} />
                                </div>
                              </div>
                            </div>
                        
                            {( salaryError && selectedMaxSalary && selectedMinSalary && !isDecimalmin && !isDecimalmax) &&
                                 <div class="mb-2">
                                 <label for="name" class="form-label input-label" style={{ "color": "red" }}>{t('salary_error')}</label>
                            </div>}
                            {(isDecimalmin || isDecimalmax) &&   <div class="mb-2">
                                 <label for="name" class="form-label input-label" style={{ "color": "red" }}>{t('salary_error2')}</label>
                            </div>}
                            {( sameSalaryError) &&
                                 <div className="error">{t('same_salary')}</div> } */}
                                  <div>
                      {salaryList?.map((sub, index) => (
                        <>
                          <div className="form-check form-check-inline mb-3">
                            <input
                              className="form-check-input form-chck"
                              type="radio"
                              name="radioLOc"
                              id={sub?.salaryId}
                              value={sub?.salary}
                              onChange={e => handleOnChangeMinSalary(e)}
                              checked={selectedMinSalary == sub.salary}
                            />
                      
                            <label
                              className="form-check-label form-label-width ps-2"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title={sub?.salaryString}
                              for="inlineCheckbox1"
                            >
                              {sub?.salaryString}
                            </label>
                          </div>
                        </>
                      ))}
                    </div>
                          </div>

                        </div>
                      </div>

                      <div>
                        <div class="heading-searching">
                          <h5>{t('skill_based')}</h5>
                        </div>
                        <div class="search-area2">
                          <div>

                            <div>
                              {skill.map((skillDetails) => {
                                return (
                                  <div class="form-check form-check-inline mb-2">
                                    <input class="form-check-input form-chck" type="checkbox" checked={selectedSkill.includes(skillDetails.skillId)}
                                      id={"industry" + skillDetails.skillId} value={skillDetails.skillId} onChange={e => handleOnChangeSkill(e)} />
                                    <label class="form-check-label2 ps-2"
                                      for="inlineCheckbox1">{skillDetails.skillTitle}</label>
                                  </div>)
                              })}
                            </div>
                          </div>

                        </div>
                      </div>


                    </div>
                  </div>


                </div>
                <div class="col-md-12 text-center top-90">
                  <button class="cancel-btn" data-bs-dismiss="modal" onClick={clear}>{t('clear')}</button>
                  <Link to={"/job-list/1?area="+selectedArea+"&prefecture="+selectedPrefecture+"&category="+selectedJobCategory+"&subCategory="+selectedJobSubCategory+
                    "&industry="+selectedIndustry+"&skill="+selectedSkill+"&maxSalary="+selectedMaxSalary+"&minSalary="+selectedMinSalary}>
                    <button class="main-buttons sub-btn" disabled={salaryError ||
                    isDecimalmax || isDecimalmin || sameSalaryError}>{t('search')}</button>
                  </Link>

                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      </div>

      {/* MODAL AREA */}

      <div className="modal fade" id="placesearch" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered  modal-lg">

         <div className="modal-content">
            <div className="modal-header border-none">
              <div className="col-md-12 text-center mt-60">
                <div className="main-heading hot-jobhead">
                  <h4>{t('search_by_place')}</h4>
                </div>
              </div>
              <button type="button" className="Close-btn" data-bs-dismiss="modal" aria-label="Close" onClick={clearArea}><i
                className="fa-solid fa-xmark"></i></button>
            </div>

            <div className="modal-body p-0">
              <div className="search-area">
                {area.map((areaDetails) => {
                  return (
                    <div>
                      <div className="row">
                        {areaDetails.prefectures.length > 0 ?  <div className="popup-head mt-3 mb-3 d-flex">
                          <h6>{areaDetails.areaTitle}</h6>
                          <input className="form-check-input form-chck ms-2" type="checkbox" onChange={e => handleOnChangeArea(e, areaDetails.prefectures)}
                            id={"modalarea" + areaDetails.id} value={areaDetails.id} checked={selectedArea.includes(areaDetails.id)} />
                        </div> : null}
                      </div>
                      <div>
                        {areaDetails.prefectures.map((prefectureDetails) => {
                          return (
                            <div className="form-check form-check-inline mb-2">
                              <input className="form-check-input form-chck" type="checkbox" onChange={e => handleOnChangePrefectureModal(e, areaDetails.prefectures, areaDetails.id)}
                                id={"modalprefecture" + prefectureDetails.prefectureId} value={prefectureDetails.prefectureId} checked={selectedPrefecture.includes(prefectureDetails.prefectureId)} />
                              <label className="form-check-label2 ps-2" for="inlineCheckbox1"
                               data-toggle="tooltip" data-placement="bottom" title={prefectureDetails?.prefectureTitle}>{prefectureDetails.prefectureTitle}</label>
                            </div>)
                        })}
                      </div>

                    </div>
                  )
                })}
              </div>
            </div>
            <div className="modal-footer border-none ">
              <div className="col-md-12 text-center">
                <button className="cancel-btn"  onClick={clearArea}>{t('clear')}</button>
                <Link to={"/job-list/1?area="+selectedArea+"&prefecture="+selectedPrefecture+"&category="+selectedJobCategory+"&subCategory="+selectedJobSubCategory+
                    "&industry="+selectedIndustry+"&skill="+selectedSkill+"&maxSalary="+selectedMaxSalary+"&minSalary="+selectedMinSalary}>
                  <button className="main-buttons sub-btn" data-bs-dismiss="modal">{t('submit')}</button>
                </Link>
              </div>
            </div>
          </div>

        </div>
      </div>

      {/* MODAL CATEGORY */}

      <div class="modal fade" id="occupationsearch" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered  modal-lg">
          <div class="modal-content">
            <div class="modal-header border-none">
              <div class="col-md-12 text-center mt-60">
                <div class="main-heading hot-jobhead"><h4>{t('search_by_job_category')}</h4>
                </div>
              </div>
              <button type="button" class="Close-btn" data-bs-dismiss="modal" aria-label="Close" onClick={clearCategory}><i class="fa-solid fa-xmark"></i></button>
            </div>

            <div className="modal-body p-0">
              <div className="search-area">
                {category.map((categoryDetails) => {
                  return (
                    <div>
                      <div className="row">
                      {categoryDetails.subCategoryList.length > 0 ?   <div className="popup-head mt-3 mb-3 d-flex">
                          <h6>{categoryDetails.categoryTitle}</h6>
                          <input className="form-check-input form-chck ms-2" type="checkbox" onChange={e => handleOnChangeJobCategory(e, categoryDetails.subCategoryList)}
                            id={"modalcategory" + categoryDetails.id} value={categoryDetails.id} checked={selectedJobCategory.includes(categoryDetails.id)} />
                        </div>:null}
                      </div>
                      <div>
                        {categoryDetails.subCategoryList.map((subCategoryDetails) => {
                          return (
                            <div className="form-check form-check-inline mb-2">
                              <input className="form-check-input form-chck" type="checkbox" onChange={e => handleOnChangeJobSubCategoryModal(e, categoryDetails.subCategoryList, categoryDetails.id)}
                                id={"modalsubCategory" + subCategoryDetails.subId} value={subCategoryDetails.subId} checked={selectedJobSubCategory.includes(subCategoryDetails.subId)} />
                              <label className="form-check-label2 ps-2" for="inlineCheckbox1"
                               data-toggle="tooltip" data-placement="bottom" title={subCategoryDetails?.subCategoryTitle}>{subCategoryDetails.subCategoryTitle}</label>
                            </div>)
                        })}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            <div class="modal-footer border-none ">
              <div class="col-md-12 text-center">
                <button class="cancel-btn" onClick={clearCategory}>{t('clear')}</button>
                <Link to={"/job-list/1?area="+selectedArea+"&prefecture="+selectedPrefecture+"&category="+selectedJobCategory+"&subCategory="+selectedJobSubCategory+
                    "&industry="+selectedIndustry+"&skill="+selectedSkill+"&maxSalary="+selectedMaxSalary+"&minSalary="+selectedMinSalary}>
                  <button className="main-buttons sub-btn" data-bs-dismiss="modal">{t('submit')}</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <script src="js/bootstrap.bundle.js"></script>
      <ToastContainer
        position="bottom-right"
        autoClose={800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};


export default Jobsearch;
