import React from 'react';
import styles from './spinner.module.css';

const Spinner = () => (
  <div className={styles.Spinner} data-testid="Spinner">
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status" style={{"color":"#00de9b","width":"3rem","height":"3rem"}}>
    <span class="sr-only">Loading...</span>
  </div>
</div>
  </div>
)

export default Spinner;
