import React from "react";
import axios from "axios";

const NO_OP = () => {};

const AppContext = React.createContext();


const useAppContext = () => React.useContext(AppContext);

const LOGIN_API = "login";
const LOGIN_API_PUT = "login";
const SESSION_KEY = "refreshToken";
const FORGOT_PASSWORD_API = "login/forgot-password";
const FORGOT_PASSWORD_RESET_API = "login/forgotPasswordValidate";
const FORGOT_PASSWORD_VALIDATE_API = "login/forgotPasswordValidate";

const basicContentType = { "Content-Type": "application/json" };
const AXIOS = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    get: basicContentType,
    post: basicContentType,
    put: basicContentType,
    delete: basicContentType,
    patch: basicContentType,
  },
});
AXIOS.interceptors.request.use(
  function (config) {
    console.log(config,"config")
    if(config.url.includes('job/apply') || config.url.includes('careerCounselling/apply') || config.url.includes('users/details')){
      config.headers["authorization"] = "jobsite " + localStorage.getItem("accessToken");
      // config.headers["Content-Type"] = "application/json"
    }
    if(config.url.includes('file/upload')){
   
      config.headers["Content-Type"] = ""
    }


  
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
AXIOS.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        localStorage.removeItem(SESSION_KEY);
        removeAuthorization();
        return Promise.reject(error);
      }
      if (error?.response?.status === 404) {
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
 );
 function AppContextProvider({ init, children }) {
    const [auth, setAuth] = React.useState(init);
  
    React.useEffect(() => {
      if (auth !== null) {
        const time = 300000; //(expiry >= 300000 ? expiry - 30000 : expiry * 0.9);
        const timeout = setInterval(() => {
          refreshAccessToken();
        }, time);
     
        return () => {
          clearTimeout(timeout);
        };
      }
  
      return NO_OP;
    }, [auth]);
  
    function signIn(email, password) {
      removeAuthorization();
      let data = JSON.stringify({
        password: password,
        email: email,
      });
      return AXIOS.post(LOGIN_API, data).then(
        (response) => {
          console.log(response)
          // AXIOS.defaults.headers.common["AuthToken"] =
          //   response.data.accessToken;
          //setAuthorization(response.data.accessToken);
          localStorage.setItem(SESSION_KEY, response.data.refreshToken);
          localStorage.setItem("accessToken", response.data.accessToken);
          localStorage.setItem("userId", response.data.applicantId);
          localStorage.setItem("userName", response.data.fullName);
          setAuth(response.data.accessToken);
          return { status: true, info: response };
        },
        (error) => {
          removeAuthorization();
          return { status: false, info: error };
        }
      );
    }
  
    function forgotPassword(email) {
      let data = JSON.stringify({
        email: email,
      });
  
      return AXIOS.post(FORGOT_PASSWORD_API, data).then(
        (response) => {
          return { status: true, info: response };
        },
        (error) => {
          return { status: false, info: error };
        }
      );
    }
  
    function forgotPasswordReset(newPassword, token) {
      let data = JSON.stringify({
        password: newPassword,
        token: token,
      });
  
      return AXIOS.put(`${FORGOT_PASSWORD_RESET_API}`, data).then(
        (response) => {
          // onClose();
          return { status: true, info: response };
        },
        (error) => {
          return { status: false, info: error };
        }
      );
    }
  
    function forgotPasswordforgotPasswordValidate(token) {
      return AXIOS.get(`${FORGOT_PASSWORD_VALIDATE_API}/${token}`).then(
        (response) => {
          return { status: true, info: response };
        },
        (error) => {
          return { status: false, info: error };
        }
      );
    }
  
    function logout() {
      removeAuthorization();
      setAuth(null);
    }
  
    const isLoggedin = () => auth !== null;
  
    const getAccessToken = () => auth;
  
    const getAxios = () => AXIOS;
  
    const context = {
      signIn,
      forgotPassword,
      forgotPasswordReset,
      forgotPasswordforgotPasswordValidate,
      logout,
      isLoggedin,
      getAccessToken,
      getAxios,
    };
  
    return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
  }
  function refreshAccessToken() {
    let refreshToken = localStorage.getItem(SESSION_KEY);
    let refreshTokendata = JSON.stringify({
      token: refreshToken,
    });
    if (refreshToken === null) {
      return Promise.resolve(null);
    }
    return AXIOS.put(LOGIN_API_PUT, refreshTokendata, {
      headers: {
        authorization: null,
      },
    }).then(
      (response) => {
        if (localStorage.getItem("accessToken")) {
        // AXIOS.defaults.headers.common["AuthToken"] =
        //   response.data.accessToken;
       // setAuthorization(response.data.accessToken);
        localStorage.setItem(SESSION_KEY, response.data.refreshToken);
        localStorage.setItem("accessToken",response.data.accessToken);
        return response.data.accessToken;
        }
      },
      () => {
        if(localStorage.getItem("accessToken")){
          return localStorage.getItem("accessToken");
        } else {
          removeAuthorization();
          localStorage.removeItem(SESSION_KEY);
          return null;
        }
      }
    );
  }
  function setAuthorization(accessTokenValue) {
    AXIOS.defaults.headers.common["authorization"] = AXIOS.defaults.headers.get[
      "authorization"
    ] = AXIOS.defaults.headers.post["authorization"] = AXIOS.defaults.headers.put[
      "authorization"
    ] = AXIOS.defaults.headers.delete[
      "authorization"
    ] = AXIOS.defaults.headers.patch["authorization"] =
      "jobsite " + accessTokenValue;
  }
  
  function removeAuthorization() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("id");
    localStorage.removeItem(SESSION_KEY);
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    delete AXIOS.defaults.headers.common["authorization"];
    delete AXIOS.defaults.headers.get["authorization"];
    delete AXIOS.defaults.headers.post["authorization"];
    delete AXIOS.defaults.headers.put["authorization"];
    delete AXIOS.defaults.headers.delete["authorization"];
    delete AXIOS.defaults.headers.patch["authorization"];
  }
  
  export default useAppContext;
  export { refreshAccessToken, AppContextProvider };
  