import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
import './pagination.css';
const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <div className="col-md-12 my-5">
    <nav aria-label="Page navigation example">
    <ul
      className={classnames('pagination justify-content-end', { [className]: className })}
    >
      <li
        className={classnames('page-item page-list', {
          disabled: currentPage === 1
        })}
        onClick={onPrevious}
      >
    <div className="page-link page-btn" tabindex="-1" aria-disabled="true" style={{"cursor":"pointer"}}>  <i className="fa-solid fa-chevron-left"></i></div>
      </li>
      {paginationRange.map(pageNumber => {
        if (pageNumber === DOTS) {
          return <li className="page-link page-dots dots">&#8230;</li>;
        }

        return (
          <div className="page-item page-list" style={{"color":"#fff","cursor":"pointer"}}>
                         <li
            className={classnames('page-link page-num', {
              selected: pageNumber === currentPage
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li></div>
        );
      })}
      <li
        className={classnames('page-item page-list', {
          disabled: currentPage === lastPage
        })}
        onClick={onNext}
      >
    <div className="page-link page-btn" tabindex="-1" aria-disabled="true" style={{"cursor":"pointer"}}>   <i className="fa-solid fa-chevron-right"></i></div>
      </li>
    </ul>
    </nav>
    </div>
  );
};

export default Pagination;
