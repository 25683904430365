import React, { useEffect } from "react";
import styles from './faq.module.css';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { Link
 } from "react-router-dom";
const Faq = () => {
  
  const { t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("LANGUAGE"));
    // eslint-disable-next-line 
 },[]);
  return(
    <div className={styles.Faq} data-testid="Faq">
    <body className="d-flex flex-column h-100">
 

  <div className="banner-login middle-toparea">
    <div className="container-fluid padding-body">
      <div className="sub-menus"><Link className="topI" to={'/'}><a style={{"color":"#DBDBDB"}}>{t('top')}</a></Link> / <span> {t('faq')}</span> </div>

      <div className="login-header">
        <h3>{t('frequently_asked_questions')}</h3>
      </div>
    </div>

  </div>

  <div className="container my-5">
    <div className="card mt-3 Card-box">
      <div className="card-body">
        <div className="accordion" id="accordionExample">
          <div className="accordion-item mb-4 accor-body">
            <h2 className="accordion-header" id="headingOne">
              <button className="accordion-button collapsed  accor-bg" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, s ?
              </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne"
              data-bs-parent="#accordionExample">
              <div className="accordion-body mt-3">
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                  sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                  sed diam voluptua. At vero eos et accusam et</p>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                  sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                  sed diam voluptua. At vero eos et accusam et</p>
              </div>
            </div>
          </div>
          <div className="accordion-item accordion-custom mb-4 accor-body">
            <h2 className="accordion-header" id="headingtwo">
              <button className="accordion-button collapsed  accor-bg" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, s ?
              </button>
            </h2>
            <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="headingtwo"
              data-bs-parent="#accordionExample">
              <div className="accordion-body mt-3">
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                  sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                  sed diam voluptua. At vero eos et accusam et</p>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                  sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                  sed diam voluptua. At vero eos et accusam et</p>
              </div>
            </div>
          </div>
          <div className="accordion-item accordion-custom mb-4 accor-body">
            <h2 className="accordion-header" id="headingthree">
              <button className="accordion-button collapsed  accor-bg" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, s ?
              </button>
            </h2>
            <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="headingthree"
              data-bs-parent="#accordionExample">
              <div className="accordion-body mt-3">
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                  sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                  sed diam voluptua. At vero eos et accusam et</p>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                  sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                  sed diam voluptua. At vero eos et accusam et</p>
              </div>
            </div>
          </div>

          <div className="accordion-item accordion-custom mb-4 accor-body">
            <h2 className="accordion-header" id="headingfour">
              <button className="accordion-button collapsed  accor-bg" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, s ?
              </button>
            </h2>
            <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="headingfour"
              data-bs-parent="#accordionExample">
              <div className="accordion-body mt-3">
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                  sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                  sed diam voluptua. At vero eos et accusam et</p>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                  sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                  sed diam voluptua. At vero eos et accusam et</p>
              </div>
            </div>
          </div>

          <div className="accordion-item accordion-custom mb-4 accor-body">
            <h2 className="accordion-header" id="headingfive">
              <button className="accordion-button collapsed  accor-bg" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, s ?
              </button>
            </h2>
            <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="headingfive"
              data-bs-parent="#accordionExample">
              <div class="accordion-body mt-3">
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                  sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                  sed diam voluptua. At vero eos et accusam et</p>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                  sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                  sed diam voluptua. At vero eos et accusam et</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <script src="js/bootstrap.bundle.js"></script>

</body>
  </div>
)};


export default Faq;
